import React, { CSSProperties } from 'react';
import { classNames } from '../lib/classNames';
import { FCWithChildren } from '../lib/types';
import { AnalyticsEvent, ButtonClickedEvents, track } from '../lib/analytics';

export type ButtonType = 'submit' | 'button';
type Tag = 'button' | 'label';

export interface ButtonProps {
  Tag?: Tag;
  analyticsEventName?: ButtonClickedEvents;
  htmlFor?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  theme?: 'primary' | 'outline' | 'text';
  type?: ButtonType;
  testID?: string;
  form?: string;
  arialLabel?: string;
  role?: string;
}

export const Button: FCWithChildren<ButtonProps> = ({
  Tag = 'button',
  htmlFor,
  onClick,
  disabled,
  children,
  form,
  className = '',
  analyticsEventName,
  theme = 'primary',
  type = 'button',
  testID,
  arialLabel,
  role,
}) => {
  const commonClasses = classNames(
    theme !== 'text' &&
      'text-base w-full font-bold mb-6 rounded-full disabled:opacity-50 disabled:drop-shadow-none'
  );

  const activeClasses = classNames(
    theme !== 'text' &&
      'active:bg-black active:bg-opacity-40 py-3 active:rounded-full'
  );

  const themeClasses = classNames(
    theme === 'primary' &&
      'button-text-theme-primary bg-theme-primary border button-border-theme-primary drop-shadow-[0_5px_0px_rgba(78,92,111,0.25)]',
    theme === 'outline' &&
      'border-2 bg-white border-theme-primary text-theme-primary',
    theme === 'text' &&
      'underline text-left text-theme-primary font-bold hover:text-huckleberry'
  );

  const textClasses = classNames(theme === 'text' && 'disabled:opacity-50');

  const innerStyle: CSSProperties | undefined =
    theme === 'primary'
      ? { padding: 'calc(0.75rem - 1px) 0' }
      : theme === 'outline'
      ? { padding: 'calc(0.75rem - 2px) 0' }
      : undefined;

  return (
    <Tag
      htmlFor={htmlFor}
      type={type}
      className={classNames(
        commonClasses,
        themeClasses,
        textClasses,
        !disabled && theme !== 'text' && 'hover:shadow-md',
        className
      )}
      disabled={disabled}
      onClick={() => {
        if (analyticsEventName) {
          track(AnalyticsEvent.ButtonClicked, { name: analyticsEventName });
        }
        onClick && onClick();
      }}
      aria-label={arialLabel}
      data-testid={testID}
      id={testID}
      role={role}
      form={form}
    >
      <div className={activeClasses} style={innerStyle}>
        {children}
      </div>
    </Tag>
  );
};
