import { ParsedUrlQuery } from 'querystring';
/**
 * Replaces [uuid] in the step parameter with the correct in store shortcode from the router.
 * @param step The string path that may contain [uuid] in it
 * @param query The query object from the router.
 * @returns The step parameter with [uuid] replaced with the uuid value from the router.
 */
export function getNamespacedPath(step: string, query: ParsedUrlQuery): string {
  if (query.uuid && typeof query.uuid === 'string') {
    return step.replace('[uuid]', query.uuid);
  } else if (query.cartID && typeof query.cartID === 'string') {
    return step.replace('[cartID]', query.cartID);
  }
  return step;
}
