import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { Locale } from '@ads-bread/shared/bread/codecs';
import { useAppConfig } from './AppConfigContext';
import { useLocale } from './IntlContext';
import { LanguageConfirmation } from './LanguageConfirmation';

export const LanguageToggle: FunctionComponent = () => {
  const intl = useIntl();
  const { locale, setLocale } = useLocale();
  const { locales } = useAppConfig();
  const { formatMessage } = useIntl();
  const { pathname } = useRouter();
  const [showConfirmation, setShow] = useState(false);
  const [selectedLocale, selectLocale] = useState(locale);

  const confirm = () => {
    setLocale(selectedLocale);
  };
  const cancel = () => {
    setLocale(locale);
  };

  if (locales.length < 2) {
    return null;
  }

  const keyMap = {
    fr: formatMessage({
      defaultMessage: 'FR',
      description: 'Abbreviation for French',
    }),
    en: formatMessage({
      defaultMessage: 'EN',
      description: 'Abbreviation for English',
    }),
  };

  //we only want to display the language toggle up until the loan-agreement page
  if (pathname != '/rbc/loan-agreement') {
    return (
      <>
        <select
          data-testid="language-toggle"
          aria-label={intl.formatMessage({
            defaultMessage: 'Select Language',
            description: 'language-toggle dropdown',
          })}
          className="languageToggle text-theme-primary font-bold"
          onChange={(event) => {
            selectLocale(event.target.value as Locale);
            setShow(true);
          }}
          value={locale}
        >
          {locales.map((loc) => (
            <option key={loc} value={loc}>
              {keyMap[loc]}
            </option>
          ))}
        </select>
        {showConfirmation ? (
          <LanguageConfirmation
            setShow={setShow}
            confirm={confirm}
            cancel={cancel}
          />
        ) : null}
      </>
    );
  } else {
    return null;
  }
};
