import { Cart } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const getCartURI = (cartID: string): string => `/api/cart/${cartID}`;

export async function getCart(
  apiFetch: APIFetch,
  cartID: string
): Promise<HandlerResponse<Cart>> {
  const uri = getCartURI(cartID);
  const res: APIResponse<Cart> = await apiFetch<Cart>(
    uri,
    { method: 'GET' },
    Cart
  );
  return handleResponse(res);
}
