import { CheckoutXProps } from './defaultXProps';

declare global {
  interface Window {
    xprops?: CheckoutXProps;
  }
}

export const readXProps = (): CheckoutXProps | null => {
  return window.xprops || null;
};
