import {
  MerchantPaymentProduct,
  PaymentType,
} from '@ads-bread/shared/bread/codecs';

/**
 * Groups the merchantPaymentProducts by payment type.
 * @param paymentProducts The {@link MerchantPaymentProduct}s to group
 * @returns an object with the {@link PaymentType} as the keys, and the
 * MerchantPaymentProduct with that type as the value.
 */
export function groupPaymentProductByType(
  paymentProducts?: MerchantPaymentProduct[]
): Record<PaymentType | string, MerchantPaymentProduct> {
  return !paymentProducts
    ? {}
    : paymentProducts.reduce((paymentProductsByType, paymentProduct) => {
        return {
          ...paymentProductsByType,
          [paymentProduct.paymentProduct.type]: paymentProduct,
        };
      }, {});
}

/**
 * Returns an object describing if the MerchantPaymentProducts
 * contain INSTALLMENT and/or SPLITPAY options
 * @param paymentProductsByType The return value of the {@link groupPaymentProductByType} function
 */
export function hasMerchantPaymentProducts(
  paymentProductsByType?: Record<PaymentType, MerchantPaymentProduct>
): { hasInstallmentProduct: boolean; hasSplitPayProduct: boolean } {
  return {
    hasInstallmentProduct: Boolean(paymentProductsByType?.INSTALLMENTS),
    hasSplitPayProduct: Boolean(paymentProductsByType?.SPLITPAY),
  };
}
