import { Application, Order } from '@ads-bread/shared/bread/codecs';

import { moneyEqual } from './order';

/**
 * Used in Session Routing to detect when we can reuse an application for a returning buyer.
 * This returns true if the order matches the offer amount in the existing application.
 */
export function totalPriceIsCovered(
  validApplication: Application,
  order: Order
): boolean {
  return Boolean(
    validApplication.offers?.some((offer) =>
      moneyEqual(
        offer.paymentAgreement.terms.originalPrincipal,
        order.totalPrice
      )
    )
  );
}
