import { createContext, useContext, useReducer } from 'react';
import {
  PaymentMethod,
  VirtualCardIssuanceResponse,
} from '@ads-bread/shared/bread/codecs';
import { useMounted } from '../lib/hooks/useMounted';
import { FCWithChildren } from '../lib/types';

type EstimatedSpendKeys = 'INSTALLMENTS' | 'SPLITPAY';

export type EstimatedSpend = {
  [key in EstimatedSpendKeys]: number | undefined;
};

export type Action =
  | { type: 'setPaymentMethods'; paymentMethods: PaymentMethod[] }
  | { type: 'setPaymentMethodID'; paymentMethodID: string }
  | { type: 'setEstimatedSpend'; estimatedSpend: EstimatedSpend }
  | { type: 'reset'; state: AppData }
  | { type: 'setVirtualCardIssuance'; issuance: VirtualCardIssuanceResponse };

export type AppData = {
  paymentMethods: PaymentMethod[];
  paymentMethodID: string | null;
  estimatedSpend: EstimatedSpend;
  issuance?: VirtualCardIssuanceResponse;
};

export type ProviderValueType = {
  data: AppData;
  dispatch: React.Dispatch<Action>;
  reset: () => void;
};

export const getInitialState = (): AppData => {
  return {
    paymentMethods: [],
    paymentMethodID: null,
    estimatedSpend: {
      SPLITPAY: undefined,
      INSTALLMENTS: undefined,
    },
  };
};

// For test mocks
export const initialState = getInitialState();

const AppDataContext = createContext<ProviderValueType>({
  data: getInitialState(),
  dispatch: () => {
    // no op
  },
  reset: () => {
    // no op
  },
});

function reducer(state: AppData, action: Action): AppData {
  switch (action.type) {
    case 'setPaymentMethods':
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    case 'setPaymentMethodID':
      return {
        ...state,
        paymentMethodID: action.paymentMethodID,
      };
    case 'setEstimatedSpend':
      return {
        ...state,
        estimatedSpend: action.estimatedSpend,
      };
    case 'reset':
      return action.state;
    case 'setVirtualCardIssuance':
      return {
        ...state,
        issuance: action.issuance,
      };
  }
}

interface ProviderProps {
  state?: AppData;
}

export const AppDataContextProvider: FCWithChildren<ProviderProps> = ({
  children,
  state = getInitialState(),
}) => {
  const [data, dispatch] = useReducer(reducer, state);

  const reset = () => {
    dispatch({ type: 'reset', state });
  };

  const isMounted = useMounted();
  const wrappedDispatch = (action: Action) => {
    if (isMounted.current) {
      dispatch(action);
    }
  };

  return (
    <AppDataContext.Provider value={{ data, dispatch: wrappedDispatch, reset }}>
      {children}
    </AppDataContext.Provider>
  );
};
export type AppDataAPI = {
  data: AppData;
  reset: () => void;
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
  setPaymentMethodID: (paymentMethodID: string) => void;
  setEstimatedSpend: (estimatedSpend: EstimatedSpend) => void;
  setVirtualCardIssuance: (issuance: VirtualCardIssuanceResponse) => void;
};

export const useAppData = (): AppDataAPI => {
  const { data, dispatch, reset } = useContext(AppDataContext);

  return {
    data,
    setPaymentMethods: (paymentMethods: PaymentMethod[]) =>
      dispatch({ type: 'setPaymentMethods', paymentMethods }),
    setPaymentMethodID: (paymentMethodID: string) =>
      dispatch({ type: 'setPaymentMethodID', paymentMethodID }),
    setEstimatedSpend: (estimatedSpend: EstimatedSpend) =>
      dispatch({ type: 'setEstimatedSpend', estimatedSpend }),
    reset,
    setVirtualCardIssuance: (issuance: VirtualCardIssuanceResponse) =>
      dispatch({ type: 'setVirtualCardIssuance', issuance }),
  };
};
