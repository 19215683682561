import { ParsedUrlQuery } from 'querystring';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import {
  MarkAllImplementationsAsProvided,
  State,
  StateSchema,
  StateValue,
  TransitionConfigOrTarget,
  Typestate,
} from 'xstate';
import { useIntl } from 'react-intl';
import { NamespacePath } from '@ads-bread/shared/bread/util';
import { useNamespace } from '../lib/hooks/useNamespace';
import { getNamespacedPath } from '../lib/get-namespaced-path';
import { AnalyticsEvent, PageNames, track } from '../lib/analytics';
import { logger } from '../lib/logger';
import {
  EcommRoutingMachineProvider,
  EcommRoutingMachineContext,
} from './EcommRoutingMachineContext';
import {
  InStoreRoutingMachineProvider,
  InStoreRoutingMachineContext,
} from './InStoreRoutingMachineContext';
import {
  CartRoutingMachineProvider,
  CartRoutingMachineContext,
} from './CartRoutingMachineContext';
import {
  RBCRoutingMachineProvider,
  RBCRoutingMachineContext,
} from './RBCRoutingMachineContext';
import { BuyerService, useBuyerMachine } from './BuyerMachineContext';
import {
  ApplicationService,
  useApplicationMachine,
} from './ApplicationMachineContext';
import { Loader, useLoadingManager } from './LoadingManager';
import { useToast } from './Toast';
import { useAuthentication } from './AuthenticationContext';
import { useAppData } from './AppDataContext';

export interface RouteMachineServiceScreenProps {
  isTransitioning: boolean;
  /**
   * Sends forward event
   * @returns void
   */
  forward: () => void;
  /**
   * Sends back event
   * @returns void
   */
  back: () => void;
  /**
   * Raises an event that can be handled to route conditionally
   * within the state of a router
   * @param cond RoutePathCondition
   * @returns void
   */
  routeWithCondition: (cond: RoutePathCondition) => void;
}

// Used to implement routing machine render props
export interface RouteMachineServiceProps {
  children: (options: RouteMachineServiceScreenProps) => JSX.Element;
}

// Used to implement routing machine context
export interface RouteMachineContextProps<ServiceT> {
  service: ServiceT;
  isTransitioning: boolean;
}

/**
 * Determines the correct routing machine provider to make available for the app based on namespace
 * @param React.Children
 * @returns FunctionComponent<RouteMachineServiceProps>
 */
export const RouteMachineService: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const namespace = useNamespace();

    /**
     * Returns the appropriate router per experience by namespace
     */
    const RoutingMachine =
      useMemo((): FunctionComponent<RouteMachineServiceProps> => {
        switch (namespace) {
          case 'alliance':
            return EcommRoutingMachineProvider;
          case 'in-store/[uuid]':
            return InStoreRoutingMachineProvider;
          case 'cart/[cartID]':
            return CartRoutingMachineProvider;
          case 'rbc':
            return RBCRoutingMachineProvider;
          default:
            return EcommRoutingMachineProvider;
        }
      }, [namespace]);

    return <RoutingMachine>{(props) => children(props)}</RoutingMachine>;
  };

export interface UseRouteMachineServiceValues
  extends RouteMachineServiceScreenProps {
  stateValue: StateValue | undefined;
}

/**
 * Hook to expose context values of the current route machine
 * @returns UseRouteMachineServiceValues
 */
export const useRouteMachineService = (): UseRouteMachineServiceValues => {
  const namespace = useNamespace();

  const ecommRoutingMachineService = useContext(EcommRoutingMachineContext);
  const inStoreRoutingMachineService = useContext(InStoreRoutingMachineContext);
  const cartRoutingMachineService = useContext(CartRoutingMachineContext);
  const rbcRoutingMachineService = useContext(RBCRoutingMachineContext);

  /**
   * Gets the active routing machine service namespace
   */
  const getActiveMachineService = () => {
    switch (namespace) {
      case 'alliance':
        return ecommRoutingMachineService?.service;
      case 'in-store/[uuid]':
        return inStoreRoutingMachineService?.service;
      case 'cart/[cartID]':
        return cartRoutingMachineService?.service;
      case 'rbc':
        return rbcRoutingMachineService?.service;
      default:
        return ecommRoutingMachineService?.service;
    }
  };

  /**
   * Gets the active routing machine isTransitioning state
   */
  const getActiveMachineServiceTransitioningState = () => {
    switch (namespace) {
      case 'alliance':
        return !!ecommRoutingMachineService?.isTransitioning;
      case 'in-store/[uuid]':
        return !!inStoreRoutingMachineService?.isTransitioning;
      case 'cart/[cartID]':
        return !!cartRoutingMachineService?.isTransitioning;
      case 'rbc':
        return !!rbcRoutingMachineService?.isTransitioning;
      default:
        return !!ecommRoutingMachineService?.isTransitioning;
    }
  };

  /**
   * Gets the active routing machine state value
   */
  const getActiveMachineServiceState = () => {
    const service = getActiveMachineService();
    return service?.getSnapshot().value;
  };

  const forward = (): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_FORWARD',
    });
  };

  const back = (): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_BACK',
    });
  };

  const routeWithCondition = (cond: RoutePathCondition): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_ROUTE_WITH_CONDITION',
      cond,
    });
  };

  return {
    forward,
    back,
    routeWithCondition,
    isTransitioning: getActiveMachineServiceTransitioningState(),
    stateValue: getActiveMachineServiceState(),
  };
};

/**
 * Allow formatted view of the RouteMachineService state
 * @returns JSX.Element
 */
export const RouteMachineServiceSubscriber: FunctionComponent = () => {
  const { stateValue } = useRouteMachineService();
  return (
    <pre className="text-sm">
      {JSON.stringify({ value: stateValue }, null, 2)}
    </pre>
  );
};

/**
 * Interface to support iteration over state targets in routing machines
 * for the SendSyncRouterStateEvent.
 */
export interface SyncStateTargets {
  target: string;
  cond: (c: unknown, e: SendSyncRouterState) => boolean;
}

/**
 * Events available for RouteMachineService state machines
 */
type SendContinue = {
  type: 'SEND_FORWARD';
};
type SendBack = {
  type: 'SEND_BACK';
};
type SendRouteWithCondition = {
  type: 'SEND_ROUTE_WITH_CONDITION';
  cond: RoutePathCondition;
};
type SendSyncRouterState = {
  type: 'SEND_SYNC_ROUTER_STATE';
  path: string;
};
type SendRouteApplicationError = {
  type: 'SEND_ROUTE_APPLICATION_ERROR';
  state: ApplicationService['state'];
};
type SendRouteBuyerError = {
  type: 'SEND_ROUTE_BUYER_ERROR';
  state: BuyerService['state'];
};
type SendResetRouterState = {
  type: 'SEND_RESET_ROUTER_STATE';
};
type SendResetRouterStateUnAuthorized = {
  type: 'SEND_RESET_ROUTER_STATE_UNAUTHORIZED';
};
type SendRouteNewBuyer = {
  type: 'SEND_ROUTE_NEW_BUYER';
};
type SendRouteCompleteIdentityBuyer = {
  type: 'SEND_ROUTE_COMPLETE_IDENTITY_BUYER';
};
type SendRouteCompleteBuyer = {
  type: 'SEND_ROUTE_COMPLETE_BUYER';
};
type SendRouteIncompleteBuyer = {
  type: 'SEND_ROUTE_INCOMPLETE_BUYER';
};
type SendRouteExchangedBuyer = {
  type: 'SEND_ROUTE_EXCHANGED_BUYER';
};
type SendRouteAuthRetryWithPII = {
  type: 'SEND_ROUTE_AUTH_RETRY_WITH_PII';
};
type SendRouteApplicationNeedsIDVerification = {
  type: 'SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION';
};
type SendRouteApplicationNeedsFullIIN = {
  type: 'SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN';
};
type SendRouteApplicationCheckoutCompleted = {
  type: 'SEND_ROUTE_APPLICATION_CHECKOUT_COMPLETED';
};
type SendRouteApplicationCheckoutPrepared = {
  type: 'SEND_ROUTE_APPLICATION_CHECKOUT_PREPARED';
};

export type RouteMachineServiceEvents =
  | SendContinue
  | SendBack
  | SendRouteWithCondition
  | SendSyncRouterState
  | SendRouteApplicationError
  | SendRouteBuyerError
  | SendResetRouterState
  | SendResetRouterStateUnAuthorized
  | SendRouteNewBuyer
  | SendRouteCompleteIdentityBuyer
  | SendRouteCompleteBuyer
  | SendRouteIncompleteBuyer
  | SendRouteExchangedBuyer
  | SendRouteAuthRetryWithPII
  | SendRouteApplicationNeedsIDVerification
  | SendRouteApplicationNeedsFullIIN
  | SendRouteApplicationCheckoutCompleted
  | SendRouteApplicationCheckoutPrepared;

/**
 * Helpers functions
 */

/**
 * Gets the route for a given state value and query
 * @param stateValue
 * @param query
 * @returns string
 */
export const getRouteFromStateValue = (
  stateValue: StateValue,
  query: ParsedUrlQuery
): string => {
  if (typeof stateValue === 'string') {
    return getNamespacedPath(stateValue, query);
  }
  return getNamespacedPath(`${Object.values(stateValue)?.[0]}`, query);
};

/**
 * Gets the state value string from a given location path
 * @param pathname
 * @param namespace
 * @param query
 * @returns string
 */
export const getStateValueFromRoute = (
  pathname: string,
  namespace: NamespacePath,
  query: ParsedUrlQuery
): string => {
  const path = removeSearchParamQueryStrings(pathname);
  const namespacePath = getNamespacedPath(namespace, query);

  return path.replace(namespacePath, namespace);
};

/**
 * Removes search param query strings from a URL
 * @param url - URL to remove search param query strings
 * @returns string
 */
export const removeSearchParamQueryStrings = (url: string): string => {
  const queryIndex = url.indexOf('?');
  if (queryIndex < 0) {
    return url;
  }
  return url.substring(0, queryIndex);
};

/**
 * Builds a query string from NextJS search params and allowable keys
 * @param searchParams ReadonlyURLSearchParams
 * @param keys string[]
 * @returns string
 */
export const getSearchParamQueryStringByKeys = (
  searchParams: ReadonlyURLSearchParams | null,
  keys: string[]
): string => {
  if (!keys.length) {
    return '';
  }

  let queryString = '';
  let matchIndex = 0;
  keys.forEach((key) => {
    const searchValue = searchParams?.get(key);
    if (searchValue) {
      queryString +=
        matchIndex === 0 ? `?${key}=${searchValue}` : `&${key}=${searchValue}`;
      matchIndex++;
    }
  });

  return queryString;
};

/**
 * Determine if the event was a logout action
 * @param event RouteMachineServiceEvents
 * @returns boolean
 */
export const isLogoutEvent = (
  historyEvent?: RouteMachineServiceEvents
): boolean => {
  if (!historyEvent) {
    return false;
  }
  return historyEvent.type === 'SEND_RESET_ROUTER_STATE';
};

/**
 * Determine if the event was an unauthorized logout action
 * @param event RouteMachineServiceEvents
 * @returns boolean
 */
export const isLogoutUnauthorizedEvent = (
  historyEvent?: RouteMachineServiceEvents
): boolean => {
  if (!historyEvent) {
    return false;
  }
  return historyEvent.type === 'SEND_RESET_ROUTER_STATE_UNAUTHORIZED';
};

/**
 * Interface for state meta data in routing machines
 */
export interface RouteStateMeta {
  analyticsPageName?: PageNames;
  prefetchRoutes: string[];
}

/**
 * Type guard for RouteStateMeta
 * @param meta
 * @returns boolean
 */
const isRouteStateMeta = (
  meta: Record<string, any>
): meta is RouteStateMeta => {
  return !!(
    meta &&
    typeof meta.prefetchRoutes === 'object' &&
    typeof meta.analyticsPageName === 'string'
  );
};

/**
 * Gets meta data from a router state node for processing
 * @param routeMachineId string
 * @param state Record<string, any>
 * @returns RouteStateMeta
 */
export const getRouteStateMeta = (
  routeMachineId: string,
  state: Record<string, any>
): RouteStateMeta => {
  const routeStateMeta = state.meta[`${routeMachineId}.${state.value}`];

  if (isRouteStateMeta(routeStateMeta)) {
    return routeStateMeta;
  }

  return {
    prefetchRoutes: [],
  };
};

type UseLogUnhandledRouteEvent = (
  identified: string,
  event: RouteMachineServiceEvents
) => void;

/**
 * Logs state information via action when an event is fired in state.
 * The wildcard of * is used in the machine router to catch and log
 * @returns UseLogUnhandledRouteEvent
 */
export const useLogUnhandledRouteEvent = (): UseLogUnhandledRouteEvent => {
  const { state: buyerState } = useBuyerMachine();
  const { state: applicationState } = useApplicationMachine();

  const logUnhandledRouteEvent = (
    stateValue: StateValue,
    event: RouteMachineServiceEvents
  ) => {
    const buyerID = buyerState.context.buyer?.id || buyerState.context?.buyerID;
    const applicationID = applicationState?.context.application?.id;

    logger.debug(
      { buyerID, applicationID, event, stateValue },
      `Unhandled Route Event`
    );
  };

  return logUnhandledRouteEvent;
};

/**
 * Allows tracking of analytics pages from RouteMachineService.
 * Waits for loading manager to be ready before running tracking queue
 * @returns (analyticsPageName?: PageNames) => void
 */
export const useTrackPageView = (): ((
  analyticsPageName?: PageNames
) => void) => {
  const pagesQueue = useRef<PageNames[]>([]);
  const isReady = useRef<boolean>(false);
  const { loading } = useLoadingManager();

  const trackPageView = (analyticsPageName?: PageNames): void => {
    if (analyticsPageName) {
      pagesQueue.current.push(analyticsPageName);
    }

    // Always read queue when ready
    if (isReady.current) {
      while (pagesQueue.current.length > 0) {
        const pageName = pagesQueue.current.shift();
        track(AnalyticsEvent.PageLoaded, {
          page: { title: pageName },
        });
      }
    }
  };

  useEffect(() => {
    // Track loading manager state
    if (!loading) {
      isReady.current = true;
      trackPageView();
    } else {
      isReady.current = false;
    }
  }, [loading]);

  return trackPageView;
};

/**
 * Prefetches an array of state value routes
 * @returns (prefetchRoutes: string[]) => void
 */
export const usePrefetch = (): ((prefetchRoutes: string[]) => void) => {
  const { prefetch, query } = useRouter();

  return (prefetchRoutes: string[]) => {
    for (const route of prefetchRoutes) {
      prefetch(getRouteFromStateValue(route, query));
    }
  };
};

/**
 * Logs the state values and event raised to reach that state to
 * help debug router issues
 * @param stateValue StateValue
 * @param event RouteMachineServiceEvents
 */
export const handleRouteServiceDebugLogging = (
  stateValue: StateValue,
  event: RouteMachineServiceEvents
): void => {
  if (process.env.NEXT_PUBLIC_DEBUG_ROUTE_MACHINE_SERVICE === 'true') {
    logger.info({ stateValue, event });
  }
};

// All RouteMachines implement the same RouteMachineServiceEvents via send
export interface RouteMachineServiceEventEmitter {
  send: (e: RouteMachineServiceEvents) => void;
}

/**
 * Subscribes to router beforePopState to emit a sync state event
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useSyncRouterStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { query, beforePopState } = useRouter();
  const namespace = useNamespace();

  useEffect(() => {
    beforePopState(({ as: pathname }) => {
      routingMachineService.send({
        type: 'SEND_SYNC_ROUTER_STATE',
        path: getStateValueFromRoute(pathname, namespace, query),
      });
      return true;
    });
  }, []);
};

/**
 * Subscribes to the buyer and application machine state and emits
 * and event with the buyer is complete, and the application is approved
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useBuyerCompleteApplicationApprovedEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: buyerState } = useBuyerMachine();
  const { state: applicationState } = useApplicationMachine();
  const lastApplicationStateRef = useRef<StateValue>();
  const isReturningBuyerState = useRef<boolean>(false);

  // Compound state subscriber
  useEffect(() => {
    // Prevents a race condition where application finishes before buyer on initialization
    if (
      buyerState.matches('fetchingBuyer') &&
      applicationState.matches('fetchingLatestApplication')
    ) {
      isReturningBuyerState.current = true;
    }

    // Prevents over firing of complete event when updating buyer contact information
    if (
      lastApplicationStateRef.current &&
      applicationState.matches(
        lastApplicationStateRef.current as Record<string, string>
      ) &&
      !isReturningBuyerState.current
    ) {
      return;
    }

    if (
      buyerState.matches('ready.complete') &&
      applicationState.matches('ready.approved')
    ) {
      // Returning buyer
      routingMachineService.send({ type: 'SEND_ROUTE_COMPLETE_BUYER' });
      isReturningBuyerState.current = false;
    }

    lastApplicationStateRef.current = applicationState.value;
  }, [buyerState, applicationState]);
};

/**
 * Subscribes to the application machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useApplicationMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: applicationState } = useApplicationMachine();

  useEffect(() => {
    if (applicationState.matches('errors')) {
      // Application in an error state
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_ERROR',
        state: applicationState,
      });
      return;
    }

    if (
      applicationState.matches('ready.needsIDVerification.initial') ||
      applicationState.matches('ready.needsIDVerificationAlloy.initial')
    ) {
      // Self cure
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION',
      });
    } else if (applicationState.matches('ready.needsFullIIN')) {
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN',
      });
    }
  }, [applicationState]);
};

/**
 * Subscribes to the buyer machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useBuyerMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: buyerState } = useBuyerMachine();

  // Buyer machine subscriber
  useEffect(() => {
    if (buyerState.matches('errors')) {
      // Buyer in an error state
      routingMachineService.send({
        type: 'SEND_ROUTE_BUYER_ERROR',
        state: buyerState,
      });
      return;
    }

    if (buyerState.matches('ready.completeIdentity')) {
      // Complete identity buyer
      routingMachineService.send({
        type: 'SEND_ROUTE_COMPLETE_IDENTITY_BUYER',
      });
    } else if (
      buyerState.matches('ready.initial') ||
      buyerState.matches('ready.cartRetrieved')
    ) {
      // New buyer
      routingMachineService.send({ type: 'SEND_ROUTE_NEW_BUYER' });
    } else if (buyerState.matches('ready.incomplete')) {
      // Incomplete buyer
      routingMachineService.send({ type: 'SEND_ROUTE_INCOMPLETE_BUYER' });
    } else if (buyerState.matches('ready.exchanging')) {
      routingMachineService.send({ type: 'SEND_ROUTE_EXCHANGED_BUYER' });
    }
  }, [buyerState]);
};

/**
 * Subscribes to the authentication machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useAuthenticationMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: authState } = useAuthentication();

  // Authentication state subscriber
  useEffect(() => {
    if (authState.matches('unAuthenticated.complete')) {
      routingMachineService.send({ type: 'SEND_RESET_ROUTER_STATE' });
    } else if (authState.matches('unAuthenticated.unauthorized')) {
      routingMachineService.send({
        type: 'SEND_RESET_ROUTER_STATE_UNAUTHORIZED',
      });
    } else if (authState.matches('unAuthenticated.retryWithPII')) {
      // Auth retry with PII event
      routingMachineService.send({
        type: 'SEND_ROUTE_AUTH_RETRY_WITH_PII',
      });
    }
  }, [authState]);
};

// Intermediate routing states that should not route
export const transitionalStates: string[] = ['initializing'];

type TransitionState = State<
  unknown,
  RouteMachineServiceEvents,
  StateSchema<unknown>,
  Typestate<unknown>,
  MarkAllImplementationsAsProvided<any>
>;

interface RouteMachineTransitionHandler {
  onTransition: (callback: (state: TransitionState) => Promise<void>) => void;
}

interface UseHandleOnTransition {
  isTransitioning: boolean;
}

/**
 * Handles state transition routing and meta data handling
 * @param routingMachineService
 * @param removeLoader
 * @returns UseHandleOnTransition
 */
export const useHandleStateRouteTransition = (
  routingMachineService: RouteMachineTransitionHandler,
  persistedSearchParams: string[] | undefined = []
): UseHandleOnTransition => {
  const { push, query } = useRouter();
  const searchParams = useSearchParams();
  const prefetch = usePrefetch();
  const { notify } = useToast();
  const intl = useIntl();
  const { removeLoader } = useLoadingManager();
  const trackPageView = useTrackPageView();
  const { resetBuyerState } = useBuyerMachine();
  const { reset: resetAppState } = useAppData();
  const { resetApplicationState } = useApplicationMachine();
  const [isTransitioning, setIsTransitioning] = useState<boolean>(true);

  // Reset application state on logout
  const handleLogoutEvent = useCallback(() => {
    resetBuyerState();
    resetApplicationState();
    resetAppState();
    removeLoader?.('ROUTE_SERVICE');
  }, [removeLoader, resetAppState, resetApplicationState, resetBuyerState]);

  // Show unauthorized notification
  const notifyUnauthorized = useCallback(() => {
    notify({
      title: intl.formatMessage({
        defaultMessage: 'Session expired',
        description: 'Logout banner session expired title',
      }),
      message: intl.formatMessage({
        defaultMessage:
          'Your session has expired. Please enter your email to log back in or to restart your application.',
        description: 'Logout banner session expired copy',
      }),
    });
  }, [intl, notify]);

  const searchParamQuery = useMemo((): string => {
    // No need to rebuild query string if nothing changes
    return getSearchParamQueryStringByKeys(searchParams, persistedSearchParams);
  }, [searchParams, persistedSearchParams]);

  useEffect(() => {
    routingMachineService.onTransition(async (state) => {
      setIsTransitioning(true);

      const stateRoute = getRouteFromStateValue(state.value, query);

      // Transitional routes are not routeable
      if (!transitionalStates.includes(stateRoute)) {
        await push(`${stateRoute}${searchParamQuery}`);

        const meta = getRouteStateMeta('router', state);
        trackPageView(meta.analyticsPageName);
        prefetch(meta.prefetchRoutes);
      }

      // Ensure state is reset when logging out
      if (isLogoutEvent(state.event)) {
        handleLogoutEvent();
      }

      // Handle unauthorized logout event
      if (isLogoutUnauthorizedEvent(state.event)) {
        handleLogoutEvent();
        notifyUnauthorized();
      }

      handleRouteServiceDebugLogging(state.value, state.event);

      setIsTransitioning(false);
    });
  }, [routingMachineService]);

  return { isTransitioning };
};

type UseRouteTransitionActions = Omit<
  RouteMachineServiceScreenProps,
  'isTransitioning'
>;

/**
 * Returns actions to raise events related to route navigation
 * @param routingMachineService
 * @returns UseRouteTransitionActions
 */
export const useRouteTransitionActions = (
  routingMachineService: RouteMachineServiceEventEmitter
): UseRouteTransitionActions => {
  const forward = useCallback(() => {
    routingMachineService.send({
      type: 'SEND_FORWARD',
    });
  }, [routingMachineService]);

  const back = useCallback(() => {
    routingMachineService.send({
      type: 'SEND_BACK',
    });
  }, [routingMachineService]);

  const routeWithCondition = useCallback(
    (cond: RoutePathCondition) => {
      routingMachineService.send({
        type: 'SEND_ROUTE_WITH_CONDITION',
        cond,
      });
    },
    [routingMachineService]
  );

  return {
    forward,
    back,
    routeWithCondition,
  };
};

/**
 * Shared guards for routing contexts.
 */
interface DefaultApplicationErrorRouteGuards {
  isAgeAlabamaMilitaryError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isAgeIneligibleError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isBuyerHashedFailedError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isBuyerSkippedInstallmentsError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isBuyerStatusIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isCreditDenialError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isCreditFreezeError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isKYCDenialError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isLocationIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isMaxCardIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isNeedsActionWarningError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isOFACDenialError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isOutstandingLoansIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  poBoxAddressIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isPreviousDenialIneligibleError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isSanctionsDenialError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isFraudAlertDenialError: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isCapacityRecheckError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isFraudDenialError: (c: unknown, e: SendRouteApplicationError) => boolean;
  isIDVerificationFailure: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isIDVerificationMaxAttemptsExceeded: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
  isIDVerificationNeedsActionWarning: (
    c: unknown,
    e: SendRouteApplicationError
  ) => boolean;
}

/**
 * Shared default guard conditions to route for Application state errors
 * responding to RouteMachineService event SendRouteApplicationError
 * Note: These can be overridden in the routing machine context if needed
 * @returns DefaultApplicationErrorRouteGuards
 */
export const getDefaultApplicationErrorRouteGuards =
  (): DefaultApplicationErrorRouteGuards => {
    return {
      isAgeAlabamaMilitaryError: (_, e) => {
        return e.state.matches('errors.ageAlabamaMilitary');
      },
      isAgeIneligibleError: (_, e) => {
        return e.state.matches('errors.ageIneligible');
      },
      isBuyerHashedFailedError: (_, e) => {
        return e.state.matches('errors.buyerHashFailed');
      },
      isBuyerSkippedInstallmentsError: (_, e) => {
        return e.state.matches('errors.buyerSkippedInstallments');
      },
      isBuyerStatusIneligibleError: (_, e) => {
        return e.state.matches('errors.buyerStatusIneligible');
      },
      isCreditDenialError: (_, e) => {
        return e.state.matches('errors.creditDenial');
      },
      isCreditFreezeError: (_, e) => {
        return e.state.matches('errors.creditFreeze');
      },
      isKYCDenialError: (_, e) => {
        return e.state.matches('errors.kycDenial');
      },
      isLocationIneligibleError: (_, e) => {
        return e.state.matches('errors.locationIneligible');
      },
      isMaxCardIneligibleError: (_, e) => {
        return e.state.matches('errors.maxCardIneligible');
      },
      isNeedsActionWarningError: (_, e) => {
        return e.state.matches('errors.needsActionWarning');
      },
      isOFACDenialError: (_, e) => {
        return e.state.matches('errors.sanctionsOFACDenial');
      },
      isOutstandingLoansIneligibleError: (_, e) => {
        return e.state.matches('errors.outstandingLoansIneligible');
      },
      poBoxAddressIneligibleError: (_, e) => {
        return e.state.matches('errors.poBoxAddressIneligible');
      },
      isPreviousDenialIneligibleError: (_, e) => {
        return e.state.matches('errors.previousDenialIneligible');
      },
      isSanctionsDenialError: (_, e) => {
        return e.state.matches('errors.sanctionsDenial');
      },
      isFraudAlertDenialError: (_, e) => {
        return e.state.matches('errors.fraudAlertDenial');
      },
      isCapacityRecheckError: (_, e) => {
        return e.state.matches('errors.capacityRecheck');
      },
      isFraudDenialError: (_, e) => {
        return e.state.matches('errors.fraudDenial');
      },
      isIDVerificationFailure: (_, e) => {
        return e.state.matches('errors.idVerificationFailure');
      },
      isIDVerificationMaxAttemptsExceeded: (_, e) => {
        return e.state.matches('errors.idVerificationMaxAttemptsExceeded');
      },
      isIDVerificationNeedsActionWarning: (_, e) => {
        return e.state.matches('errors.idVerificationNeedsActionWarning');
      },
    };
  };

interface DefaultBuyerErrorRouteGuards {
  isBuyerVerificationError: (_: unknown, e: SendRouteBuyerError) => boolean;
}

/**
 * Shared default guard conditions to route for Buyer state errors
 * responding to RouteMachineService event SendRouteBuyerError
 * Note: These can be overridden in the routing machine context if needed
 * @returns DefaultBuyerErrorRouteGuards
 */
export const getDefaultBuyerErrorRouteGuards =
  (): DefaultBuyerErrorRouteGuards => {
    return {
      isBuyerVerificationError: (_, e) => {
        return e.state.matches('errors.verifiedEmail');
      },
    };
  };

/**
 * There are some instances where we need to route conditionally.
 * This should be used in rare circumstances, but is sometimes necessary
 */
export enum RoutePathCondition {
  BillingAddress = '/billing-address',
  IDVerificationUploadFront = '/id-verification/upload-front',
  IDVerificationUploadBack = '/id-verification/upload-back',
  VirtualCardError = '/card-error',
  VirtualCardErrorFailed = '/card-error-failed',
  Payment = '/payment',
  UnknownError = '/unknown-error',
}

export interface RoutePathConditionGuards {
  isBillingAddressRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
  isIDVerificationUploadFrontRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
  isIDVerificationUploadBackRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
  isVirtualCardErrorRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
  isVirtualCardFailedRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
  isPaymentRouteCondition: (_: unknown, e: SendRouteWithCondition) => boolean;
  isUnknownErrorRouteCondition: (
    _: unknown,
    e: SendRouteWithCondition
  ) => boolean;
}

/**
 * Shared Guards for route path condition event handling
 * @returns RoutePathConditionGuards
 */
export const getRoutePathConditionGuards = (): RoutePathConditionGuards => {
  return {
    isBillingAddressRouteCondition: (_, event) => {
      return RoutePathCondition.BillingAddress === event.cond;
    },
    isIDVerificationUploadFrontRouteCondition: (_, event) => {
      return RoutePathCondition.IDVerificationUploadFront === event.cond;
    },
    isIDVerificationUploadBackRouteCondition: (_, event) => {
      return RoutePathCondition.IDVerificationUploadBack === event.cond;
    },
    isVirtualCardErrorRouteCondition: (_, event) => {
      return RoutePathCondition.VirtualCardError === event.cond;
    },
    isVirtualCardFailedRouteCondition: (_, event) => {
      return RoutePathCondition.VirtualCardErrorFailed === event.cond;
    },
    isPaymentRouteCondition: (_, event) => {
      return RoutePathCondition.Payment === event.cond;
    },
    isUnknownErrorRouteCondition: (_, event) => {
      return RoutePathCondition.UnknownError === event.cond;
    },
  };
};

/**
 * Shared Actions
 */
interface GetLoadingIndicatorActions {
  addLoadingIndicator: (e: unknown, c: unknown) => void;
  removeLoadingIndicator: (e: unknown, c: unknown) => void;
}
interface GetLoadingIndicatorActionsProps {
  addLoader: (loader: Loader) => void;
  removeLoader: (loader: Loader) => void;
}
/**
 * Shared actions to support adding and removing loaders the LoadingManager
 * @param GetLoadingIndicatorActionsProps
 * @returns GetLoadingIndicatorActions
 */
export const getLoadingIndicatorActions = ({
  addLoader,
  removeLoader,
}: GetLoadingIndicatorActionsProps): GetLoadingIndicatorActions => {
  return {
    addLoadingIndicator: () => {
      return addLoader('ROUTE_SERVICE');
    },
    removeLoadingIndicator: () => {
      return removeLoader('ROUTE_SERVICE');
    },
  };
};

interface RoutingMachineLevelEvents {
  SEND_SYNC_ROUTER_STATE?: TransitionConfigOrTarget<
    unknown,
    SendSyncRouterState
  >;
  SEND_RESET_ROUTER_STATE?: TransitionConfigOrTarget<
    unknown,
    SendResetRouterState
  >;
  SEND_RESET_ROUTER_STATE_UNAUTHORIZED?: TransitionConfigOrTarget<
    unknown,
    SendResetRouterStateUnAuthorized
  >;
  SEND_ROUTE_APPLICATION_ERROR?: TransitionConfigOrTarget<
    unknown,
    SendRouteApplicationError
  >;
  SEND_ROUTE_BUYER_ERROR?: TransitionConfigOrTarget<
    unknown,
    SendRouteBuyerError
  >;
}

/**
 * Shared Events
 */

/**
 * Get shared top level events for routing machine. These handle top level machine
 * state error events and sync state events for browser back actions.
 * @param Routes Routes enum from machine
 * @returns RoutingMachineLevelEvents
 */
export function getRoutingMachineLevelEvents(
  Routes: Record<string, string>
): RoutingMachineLevelEvents {
  return {
    SEND_SYNC_ROUTER_STATE: Object.values(Routes).map(
      (route): SyncStateTargets => ({
        target: route,
        cond: (_, e) => e.path === route,
      })
    ),
    SEND_RESET_ROUTER_STATE: {
      actions: ['addLoadingIndicator'],
      target: Routes.Email,
    },
    SEND_RESET_ROUTER_STATE_UNAUTHORIZED: {
      actions: ['addLoadingIndicator'],
      target: Routes.Email,
    },
    SEND_ROUTE_APPLICATION_ERROR: [
      {
        cond: 'isAgeAlabamaMilitaryError',
        target: Routes.AgeAlabamaMilitary,
      },
      {
        cond: 'isAgeIneligibleError',
        target: Routes.AgeIneligible,
      },
      {
        cond: 'isBuyerHashedFailedError',
        target: Routes.BuyerHashFailed,
      },
      {
        cond: 'isBuyerSkippedInstallmentsError',
        target: Routes.BuyerSkippedInstallments,
      },
      {
        cond: 'isBuyerStatusIneligibleError',
        target: Routes.BuyerStatusIneligible,
      },
      {
        cond: 'isCreditDenialError',
        target: Routes.CreditDenial,
      },
      {
        cond: 'isCreditFreezeError',
        target: Routes.CreditFreeze,
      },
      {
        cond: 'isKYCDenialError',
        target: Routes.KycDenial,
      },
      {
        cond: 'isLocationIneligibleError',
        target: Routes.LocationIneligible,
      },
      {
        cond: 'isMaxCardIneligibleError',
        target: Routes.MaxCardIneligible,
      },
      {
        cond: 'isNeedsActionWarningError',
        target: Routes.NeedsActionWarning,
      },
      {
        cond: 'isOFACDenialError',
        target: Routes.OFACDenial,
      },
      {
        cond: 'isOutstandingLoansIneligibleError',
        target: Routes.OutstandingLoansIneligible,
      },
      {
        cond: 'isPreviousDenialIneligibleError',
        target: Routes.PreviousDenialIneligible,
      },
      {
        cond: 'poBoxAddressIneligibleError',
        target: Routes.POBoxAddressIneligible,
      },
      {
        cond: 'isSanctionsDenialError',
        target: Routes.SanctionDenial,
      },
      {
        cond: 'isFraudAlertDenialError',
        target: Routes.FraudAlertDenial,
      },
      {
        cond: 'isFraudDenialError',
        target: Routes.FraudDenial,
      },
      {
        cond: 'isCapacityRecheckError',
        target: Routes.CapacityRecheck,
      },
      {
        cond: 'isIDVerificationFailure',
        target: Routes.IDVerificationFailure,
      },
      {
        cond: 'isIDVerificationMaxAttemptsExceeded',
        target: Routes.IDVerificationMaxAttemptsExceeded,
      },
      {
        cond: 'isIDVerificationNeedsActionWarning',
        target: Routes.IDVerificationNeedsActionWarning,
      },
      {
        target: Routes.Unknown,
      },
    ],
    SEND_ROUTE_BUYER_ERROR: [
      {
        cond: 'isBuyerVerificationError',
        target: Routes.VerifiedEmail,
      },
      {
        target: Routes.Unknown,
      },
    ],
  };
}
