import { Address } from '@ads-bread/shared/bread/codecs';

export const transformRBCPostal = (value: string): string => {
  const formattedPostal = value.toUpperCase();
  return formattedPostal.length > 3 && formattedPostal[3] !== ' '
    ? formattedPostal.substring(0, 3) + ' ' + formattedPostal.substring(3)
    : formattedPostal;
};

const REQUIRED_ADDRESS_FIELDS: Partial<keyof Address>[] = [
  'address1',
  'country',
  'locality',
  'postalCode',
  'region',
];

/**
 * Validates all required fields are present in the address
 * @param address - the address to validate
 * @returns true if the address has non-blank values for all required fields.
 */
export const isAddressComplete = (address?: Address | null): boolean => {
  if (!address) {
    return false;
  }
  // Ensure all required fields are present. `address2` is not required.
  return REQUIRED_ADDRESS_FIELDS.every((key) => address[key]);
};

/**
 * Determines if any address value has changed
 * @param initialAddress Address | undefined
 * @param newAddress Address
 * @returns boolean
 */
export const hasAddressChanged = (
  newAddress: Address,
  initialAddress: Address | undefined
): boolean => {
  const newAddressValueFields: Record<string, string> = {
    address1: newAddress.address1,
    address2: newAddress.address2,
    country: newAddress.country,
    locality: newAddress.locality,
    postalCode: newAddress.postalCode,
    region: newAddress.region,
  };

  const initialAddressValueFields: Record<string, string> = {
    address1: initialAddress?.address1 || '',
    address2: initialAddress?.address2 || '',
    country: initialAddress?.country || '',
    locality: initialAddress?.locality || '',
    postalCode: initialAddress?.postalCode || '',
    region: initialAddress?.region || '',
  };
  const initialAddressFields = Object.keys(initialAddressValueFields);

  for (const field of initialAddressFields) {
    if (initialAddressValueFields[field] !== newAddressValueFields[field]) {
      return true;
    }
  }

  return false;
};

/**
 * Checks for blacklisted PO Box addresses
 * @param address
 * @returns boolean
 */
export const isBlacklistedPOBox = (address: string): boolean => {
  return POBoxBlackList.some((v) => {
    return address.toLowerCase().includes(v.toLowerCase());
  });
};

export const POBoxBlackList = [
  'POB BOX',
  'PO #',
  'PO#',
  'POBX',
  '(P0 BO',
  '# P0BO',
  'P O',
  'P. . B',
  'P. BOX',
  'P. I.',
  'P. O',
  'P. 0 B',
  'P. 0.',
  'P.. O.',
  'P..O B',
  'P..O.',
  'P.).BO',
  'P.B.BO',
  'P.O .',
  'P.O, B',
  'P.O,.B',
  'P.O,BO',
  'P.OB B',
  'P.OFFI',
  'P.OX B',
  'P.P.BO',
  'P.S.BO',
  'P.0 .',
  'P.0 BO',
  'P.0.',
  'P/ O/',
  'P/.O.B',
  'P/O BO',
  'P/O/ B',
  'P/O/BO',
  'P,O BO',
  'P,O. B',
  'P,O, B',
  'PA BOX',
  'P0 O B',
  'P0. BO',
  'PI BOX',
  'P0O BO',
  'PO OX',
  'P. O. BOX',
  'P.O BOX',
  'PO. BOX',
  'P O BOX',
  'P O. BOX',
  'POBOX',
  'P.O.BOX',
  'P  O BOX',
  'PO BOX',
  'P.O. BOX',
  'POST OFFICE BOX',
  'P..BOX',
  'P.BOX',
  'P.OBOX',
  'P.0BOX',
  'PBOX',
  'P0 BOX',
  'P0 B0X',
  'P0.BOX',
  'P0BOX',
  'General Delivery',
];
