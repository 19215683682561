import { ClientAppConfig } from '@ads-bread/shared/bread/codecs';
import React, { createContext, useContext, useRef } from 'react';
import { FCWithChildren } from '../lib/types';

const AppConfigContext = createContext<null | ClientAppConfig>(null);

export const AppConfigProvider: FCWithChildren<{
  config?: ClientAppConfig;
}> = ({ children, config }) => {
  // Only use initial value of config from first render as it is undefined
  // subsequent route transitions
  const cachedConfig = useRef(config || null);

  if (!cachedConfig.current) {
    return null;
  }
  return (
    <AppConfigContext.Provider value={cachedConfig.current}>
      {children}
    </AppConfigContext.Provider>
  );
};

export function useAppConfig(): ClientAppConfig {
  const config = useContext(AppConfigContext);
  if (!config) {
    throw new Error('Cannot use AppConfig context while null');
  }
  return config;
}
