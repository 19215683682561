import * as io from 'io-ts';
import { MerchantConfig } from './MerchantConfig';
import { Capabilities, MerchantProgramValues } from './MerchantProgramValues';
import { MerchantDetails } from './MerchantDetails';
import { MerchantPaymentProduct } from './PaymentProduct';
var Currency = io.union([io.literal('USD'), io.literal('CAD')]);
export var Program = io.type({
  id: io.string,
  tenantID: io.string,
  name: io.string,
  currency: Currency,
  defaultLanguage: io.string,
  displayInterestRateBPS: io.number,
  policies: io.type({
    application: io.type({
      tof: io.type({
        disclosures: io.union([io.array(io.type({
          type: io.string,
          requiresChecksum: io["boolean"]
        })), io.undefined])
      })
    })
  }),
  capabilities: Capabilities
});
export var AggregatedMerchantProgramConfiguration = io.intersection([io.type({
  program: Program,
  merchantPaymentProducts: io.array(MerchantPaymentProduct),
  merchantProgramValues: MerchantProgramValues,
  merchantDetails: MerchantDetails
}), io.partial({
  merchantConfig: MerchantConfig
})]);