import * as io from 'io-ts';
import { Money } from './Money';
var PaymentProductType = io.union([io.literal('INSTALLMENTS'), io.literal('SPLITPAY')]);
var PaymentProduct = io.type({
  id: io.string,
  programID: io.string,
  name: io.string,
  description: io.string,
  numPayments: io.number,
  paymentFrequency: io.string,
  minInterestRateBPS: io.number,
  maxInterestRateBPS: io.number,
  paymentMethodTypes: io.array(io.string),
  autopayRequired: io["boolean"],
  type: PaymentProductType,
  requiredDownPaymentBPS: io.number,
  legalAgreements: io.record(io.string, io.unknown),
  policies: io.record(io.string, io.unknown),
  workflowIDs: io.record(io.string, io.unknown),
  createdAt: io.string,
  // Probably not relevant for us?
  deletedAt: io.union([io.string, io["null"]])
});
var MerchantValues = io.type({
  minCartSize: Money,
  maxCartSize: Money,
  preauthAmount: io.union([io.number, io["null"]])
});
export var MerchantPaymentProduct = io.type({
  paymentProduct: PaymentProduct,
  merchantValues: MerchantValues
});
export var MerchantPaymentProductResponse = io.type({
  merchantPaymentProducts: io.array(MerchantPaymentProduct)
});
export var PaymentProductConfiguration = io.type({
  paymentProduct: PaymentProduct,
  enrollments: io.array(MerchantValues)
});
export var PaymentProductConfigurationResponse = io.type({
  items: io.array(PaymentProductConfiguration)
});