import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UseReviewedApplicationState {
  hasReviewedApplication: boolean;
  setHasReviewedApplication: (hasReviewedApplication: boolean) => void;
}

export const useHasReviewedApplication = (): UseReviewedApplicationState => {
  const getStore = create<UseReviewedApplicationState>()(
    persist(
      (set) => ({
        hasReviewedApplication: false,
        setHasReviewedApplication: (hasReviewedApplication: boolean) =>
          set({ hasReviewedApplication }),
      }),
      { name: 'reviewed-application' }
    )
  );

  return getStore();
};
