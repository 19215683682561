import * as io from 'io-ts';
import { Address, Name } from './Buyer';
import { DisclosuresAttribute } from './Disclosures';
import { Money } from './Money';
import { ScheduledPayment } from './PaymentAgreement';
import { LocalizedString } from './Locale';
export var PaymentTypes;

(function (PaymentTypes) {
  PaymentTypes["INSTALLMENTS"] = "INSTALLMENTS";
  PaymentTypes["SPLITPAY"] = "SPLITPAY";
})(PaymentTypes || (PaymentTypes = {}));

export var TermsIntervals = io.union([io.literal('MONTHLY'), io.literal('BIWEEKLY')]);
var Terms = io.type({
  originalPrincipal: Money,
  interval: TermsIntervals,
  length: io.number,
  rate: io.number
});
var ShortCode = io.type({
  value: io.string,
  expiresAt: io.string
});
var PaymentAgreement = io.type({
  createdAt: io.string,
  expiresAt: io.string,
  id: io.string,
  paymentAmount: Money,
  status: io.string,
  terms: Terms,
  updatedAt: io.string
});
export var Offer = io.type({
  capacity: Money,
  createdAt: io.string,
  id: io.string,
  overflowCapacity: io.union([Money, io.undefined]),
  paymentAgreement: PaymentAgreement,
  paymentProduct: io.type({
    id: io.string,
    type: io.keyof(PaymentTypes)
  }),
  shortCode: io.union([ShortCode, io.undefined]),
  status: io.string,
  statusCodes: io.union([io.array(io.string), io["null"]]),
  updatedAt: io.string,
  schedule: io.type({
    installments: io.array(ScheduledPayment)
  }),
  interest: Money
});
export var OrderFulfillmentType = io.union([io.literal('DELIVERY'), io.literal('PICKUP'), io.literal('MULTIPLE'), io.literal('')]);
export var OrderItemFulfillmentType = io.union([io.literal('DELIVERY'), io.literal('PICKUP'), io.literal('')]);
export var OrderItem = io.intersection([io.type({
  name: io.string,
  quantity: io.number,
  shippingCost: Money,
  shippingDescription: io.union([io.string, LocalizedString]),
  unitPrice: Money,
  unitTax: Money
}), io.partial({
  category: io.string,
  itemUrl: io.string,
  sku: io.string,
  shippingProvider: io.string,
  shippingTrackingNumber: io.string,
  shippingTrackingURL: io.string,
  fulfillmentType: OrderItemFulfillmentType
})]);
export var PickupInformation = io.intersection([io.type({
  address: Address
}), io.partial({
  name: Name,
  email: io.string,
  phone: io.string
})]);
export var Order = io.intersection([io.type({
  items: io.union([io.array(OrderItem), io.undefined]),
  subTotal: Money,
  totalDiscounts: Money,
  totalPrice: Money,
  totalShipping: Money,
  totalTax: Money
}), io.partial({
  fulfillmentType: OrderFulfillmentType,
  pickupInformation: PickupInformation
})]);
var ShortContact = io.type({
  address: Address,
  email: io.string,
  name: Name,
  phone: io.string
});
export var APPLICATION_STATUS;

(function (APPLICATION_STATUS) {
  APPLICATION_STATUS["APPROVED"] = "APPROVED";
  APPLICATION_STATUS["ATTEMPTED_CHECKOUT"] = "ATTEMPTED_CHECKOUT";
  APPLICATION_STATUS["ATTEMPTED_PREPARE_CHECKOUT"] = "ATTEMPTED_PREPARE_CHECKOUT";
  APPLICATION_STATUS["CANCELLED"] = "CANCELLED";
  APPLICATION_STATUS["CHECKOUT_COMPLETED"] = "CHECKOUT_COMPLETED";
  APPLICATION_STATUS["CHECKOUT_PREPARED"] = "CHECKOUT_PREPARED";
  APPLICATION_STATUS["DENIED"] = "DENIED";
  APPLICATION_STATUS["EXPIRED"] = "EXPIRED";
  APPLICATION_STATUS["INELIGIBLE"] = "INELIGIBLE";
  APPLICATION_STATUS["NEEDS_ACTION"] = "NEEDS_ACTION";
  APPLICATION_STATUS["STARTED"] = "STARTED";
  APPLICATION_STATUS["UPDATED"] = "UPDATED";
})(APPLICATION_STATUS || (APPLICATION_STATUS = {}));

var Extension = io.type({
  key: io.string,
  value: io.string
});
var ClientInfo = io.type({
  type: io.string,
  value: io.string
});
var ApplicationOptional = io.type({
  buyerShippingContactID: io.string,
  ipAddress: io.string,
  paymentAgreementID: io.union([io.string, io.undefined]),
  status: io.keyof(APPLICATION_STATUS),
  statusCodes: io.union([io.array(io.string), io["null"]]),
  transactionID: io.string,
  shippingContact: ShortContact,
  paymentMethodID: io.string,
  autoPaymentMethodID: io.string
});
var ApplicationOptionalOutgoing = io.type({
  billingContact: ShortContact,
  buyerID: io.string,
  capacity: Money,
  createdAt: io.string,
  expiresAt: io.string,
  id: io.string,
  merchantID: io.string,
  programID: io.string,
  offers: io.union([io.array(Offer), io.undefined]),
  clientInfo: io.union([io.array(ClientInfo), io.undefined]),
  extensions: io.union([io.array(Extension), io.undefined]),
  retargetingURL: io.union([io.string, io.undefined]),
  site_id: io.union([io.string, io.undefined]),
  neuro_user_id: io.union([io.string, io.undefined])
});
var ApplicationRequiredOutgoing = io.type({
  order: Order,
  disclosures: DisclosuresAttribute
});
export var Application = io.intersection([ApplicationOptionalOutgoing, ApplicationRequiredOutgoing, io.partial(ApplicationOptional.props)]);
export var OutgoingApplication = io.intersection([ApplicationRequiredOutgoing, io.partial(ApplicationOptionalOutgoing.props)]);
export var ApplicationsResponse = io.array(Application);