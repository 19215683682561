/**
 * Ensures an image path is a valid path.
 *
 * @param imagePath - Path to an image represented as a URL or relative path.
 *
 * @returns - Returns true if an image path is a valid path.
 *
 */
export var isValidPreviewImage = function isValidPreviewImage(imagePath) {
  if (!imagePath) {
    return false;
  }

  if (!isValidRelativePath(imagePath) && !isValidURL(imagePath)) {
    return false;
  }

  var fileName = imagePath.split('/').pop();

  if (!fileName) {
    return false;
  }

  return isValidImageExtension(fileName);
};
/**
 * Determines if URL is valid.
 *
 * @param url - URL as string.
 *
 * @returns - Returns true if a URL is valid.
 *
 */

export var isValidURL = function isValidURL(url) {
  if (!url) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
/**
 * Determines if a filepath is a valid relative path.
 *
 * @param filePath - A file path.
 *
 * @returns - Returns if a filepath is a valid relative path.
 *
 */

export var isValidRelativePath = function isValidRelativePath(filePath) {
  if (!filePath) {
    return false;
  }

  return filePath.startsWith('/');
};
var validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
/**
 * Determines if a file name has a valid image extension.
 *
 * @param fileName - A file name.
 *
 * @returns - Returns if a file name has a valid image extension.
 *
 */

export var isValidImageExtension = function isValidImageExtension(fileName) {
  var extension = fileName.split('.').pop();

  if (!extension) {
    return false;
  }

  return validImageExtensions.includes(extension);
};
var hexColorCodeRegex = new RegExp(/^#(?:[0-9A-Fa-f]{3}){1,2}$/i);
/**
 * Determines if a color is a valid hex code.
 *
 * @param color - Color as a hex code.
 *
 * @returns - Returns if a color is a valid hex code.
 *
 */

export var isValidHexCode = function isValidHexCode(color) {
  if (!color) {
    return false;
  }

  return hexColorCodeRegex.test(color);
};
var uuidRegex = new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/);
/**
 * Determine if a string is a valid UUID
 * @param uuid - UUID string
 * @returns boolean
 */

export var isValidUUID = function isValidUUID(uuid) {
  if (!uuid) {
    return false;
  }

  return uuidRegex.test(uuid);
};