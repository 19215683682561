import Head from 'next/head';
import { useAppConfig } from '../AppConfigContext';

export function Datadog(): JSX.Element | null {
  const {
    datadogAppID,
    datadogClientToken,
    datadogEnabled,
    datadogEnv,
    datadogSite,
  } = useAppConfig();
  if (!datadogEnabled) {
    return null;
  }

  const ddRumOpts = {
    clientToken: datadogClientToken,
    service: 'checkout',
    site: datadogSite,
    env: datadogEnv,
    sampleRate: 100,
    useCrossSiteSessionCookie: true,
    silentMultipleInit: true,
    applicationId: datadogAppID,
    trackInteractions: true,
  };
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,u,n,d) {
              h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
              d=o.createElement(u);d.async=1;d.src=n
              n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
            })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum.js','DD_RUM')
            DD_RUM.onReady(function() {
              DD_RUM.init(${JSON.stringify(ddRumOpts)})
              window.dispatchEvent(new CustomEvent('dd_rum.ready'));
            })`,
        }}
      />
    </Head>
  );
}
