import React from 'react';
import { SVGIcon } from './svgHelpers';

export const SpeechBubble: SVGIcon = (props) => {
  const {
    className,
    'data-testid': dataTestID = 'speech-bubble-icon',
    ...otherProps
  } = props;
  return (
    <svg
      data-testid={dataTestID}
      className={`fill-current ${className}`}
      width="81"
      height="75"
      viewBox="0 0 81 75"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M40.1776 0C62.3671 0 80.3553 15.5352 80.3553 34.6989C80.3553 53.8625 62.3671 69.3977 40.1776 69.3977C33.8898 69.3977 27.9393 68.1503 22.6398 65.9263L2.79824 75L7.71845 55.152C2.86492 49.4167 0 42.3467 0 34.6989C0 15.5352 17.9881 0 40.1776 0ZM40.1776 3.65251C20.0054 3.65251 3.65251 17.5524 3.65251 34.6989C3.65251 42.0387 6.64907 48.7837 11.6591 54.0981L7.55337 69.5088L22.1919 61.7265C27.5013 64.2847 33.6388 65.7452 40.1776 65.7452C60.3499 65.7452 76.7028 51.8453 76.7028 34.6989C76.7028 17.5524 60.3499 3.65251 40.1776 3.65251ZM36.0445 47.2116H41.3681V52.7759H36.0445V47.2116ZM30.2928 16.4871C32.4687 14.1419 35.4559 12.9693 39.2547 12.9693C42.7681 12.9693 45.5815 13.9725 47.6949 15.9788C49.8083 17.9852 50.865 20.5489 50.865 23.67C50.865 25.5605 50.4771 27.0942 49.7013 28.2713C48.9255 29.4484 47.3605 31.1783 45.0063 33.4611C43.2942 35.1198 42.184 36.5242 41.6757 37.6746C41.1674 38.8249 40.9133 40.5236 40.9133 42.7708H36.1515C36.1515 40.2204 36.4547 38.165 37.061 36.6045C37.6674 35.044 38.9961 33.2561 41.0471 31.2407L43.1872 29.1274C43.8293 28.521 44.3465 27.8879 44.7388 27.228C45.4522 26.0687 45.8089 24.8649 45.8089 23.6165C45.8089 21.8687 45.2872 20.3528 44.2439 19.0687C43.2006 17.7846 41.4751 17.1426 39.0674 17.1426C36.0891 17.1426 34.0292 18.2483 32.8878 20.4598C32.2457 21.6904 31.8801 23.4649 31.7909 25.7834H27.0291C27.0291 21.9311 28.117 18.8324 30.2928 16.4871C30.2928 16.4871 28.117 18.8324 30.2928 16.4871Z" />
    </svg>
  );
};
