export type BreadJWT = {
  iat: number;
  exp: number;
  buyerID: string;
  merchantID: string;
  programID: string;
  scp: string[];
};

export function isBreadJWT(
  decoded: Record<string, any> | string | null
): decoded is BreadJWT {
  return !!(
    decoded &&
    typeof decoded === 'object' &&
    typeof decoded.exp === 'number' &&
    typeof decoded.buyerID === 'string' &&
    typeof decoded.merchantID === 'string'
  );
}
