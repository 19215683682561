import {
  GetFraudAlloyRequest,
  GetFraudAlloyResponse,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export async function getFraudAlloy(
  applicationID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<GetFraudAlloyResponse>> {
  const res: APIResponse<GetFraudAlloyResponse> = await apiFetch<
    GetFraudAlloyResponse,
    GetFraudAlloyRequest
  >(
    `/api/fraud/journey-meta/${applicationID}`,
    {
      method: 'GET',
    },
    GetFraudAlloyResponse
  );

  return handleResponse(res);
}
