import { flattenDetailProperties, flattenOrder } from './util';
import { Analytics, CheckoutDetails, EventProperties, Values } from '.';

export const decibelClient: Omit<
  Analytics,
  'track' | 'trackLogout' | 'setApplication' | 'setSelectedOffer' | 'setOrder'
> = {
  initialize() {
    decibel.endSession();
    decibel.startSession();
  },
  identify(buyerID: string) {
    decibel.identify(buyerID);
    decibel.sendCustomDimension({ buyer_id: buyerID });
  },
  setCheckoutDetails(details: CheckoutDetails): void {
    const detailProperties = flattenDetailProperties(details);
    const orderProperties = flattenOrder(details.order, details.isDefaultOrder);
    decibel.sendCustomDimension({ ...detailProperties, ...orderProperties });
  },
  setAdditionalContext(properties: EventProperties) {
    decibel.sendCustomDimension(properties);
  },
};

export type WindowWithDecibel = Window & {
  decibelInsight?: (
    event: string,
    p1?: Values,
    p2?: Values,
    p3?: Values
  ) => void;
};
declare const window: WindowWithDecibel;

interface Decibel {
  startSession: () => void;
  endSession: () => void;
  identify: (buyerID: string) => void;
  sendCustomDimension: (properties: EventProperties) => void;
  sendTrackedEvent: (
    name: string,
    value?: number,
    currency?: string //A three-letter ISO 4217 currency code
  ) => void;
}

// sendCustomDimension and sendTrackedEvent requires configuration
// via the decibel dashboard under settings
const decibel: Decibel = {
  startSession() {
    window.decibelInsight?.('startSession', true);
  },
  endSession() {
    window.decibelInsight?.('endSession');
  },
  identify(buyerID: string) {
    window.decibelInsight?.('updateUserId', buyerID);
    window.decibelInsight?.('sendCustomDimension', 'buyer_id', buyerID);
  },
  sendCustomDimension(properties: EventProperties) {
    Object.entries(properties).forEach(([dimension, value]) => {
      window.decibelInsight?.('sendCustomDimension', dimension, value);
    });
  },
  sendTrackedEvent(name: string, value?: number, currency?: string) {
    window.decibelInsight?.('sendTrackedEvent', name, value, currency);
  },
};
