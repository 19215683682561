type ActionArgs = {
  name: string;
  context?: Record<string, unknown> | undefined;
};

let queue: ActionArgs[] = [];

if (typeof window !== 'undefined') {
  window.addEventListener('dd_rum.ready', () => {
    queue.forEach(({ name, context }) => addAction(name, context));
    queue = [];
  });
}

export function addAction(
  name: string,
  context?: Record<string, unknown> | undefined
): void {
  if (window.DD_RUM && window.DD_RUM.addAction) {
    window.DD_RUM.addAction(name, context);
  } else {
    queue.push({ name, context });
  }
}
