import { Decoder } from 'io-ts';
import { parseISO, formatISO } from 'date-fns';
import {
  AuthPayload,
  AuthResult,
  isAPIError,
} from '@ads-bread/shared/bread/codecs';
import { logger } from '../logger';
import {
  APIFetch,
  APIResponse,
  UnauthorizedFromAuthError,
} from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const AUTHORIZE_URI = '/api/auth/buyer/authorize';
export const VERIFY_CODE_URI = '/api/auth/buyer/verify-code';
export const EXCHANGE_CODE_URI = '/api/auth/buyer/token/exchange';
export const LOGIN_URI = '/api/auth/login';

export function authorize(
  authPayload: AuthPayload,
  apiFetch: APIFetch
): Promise<HandlerResponse<AuthResult>> {
  return makeAuthRequest<AuthResult>(
    AUTHORIZE_URI,
    authPayload,
    AuthResult,
    apiFetch
  );
}

async function makeAuthRequest<T>(
  uri: string,
  authPayload: AuthPayload,
  decoder: Decoder<unknown, T>,
  apiFetch: APIFetch
): Promise<HandlerResponse<T>> {
  const {
    buyerID,
    merchantID,
    programID,
    referenceID = '',
    credentials,
  } = authPayload;

  const body: AuthPayload = {
    buyerID,
    merchantID,
    programID,
    referenceID,
    credentials: {
      code: credentials.code,
    },
  };

  if (credentials.dob) {
    body.credentials.dob = formatISO(parseISO(credentials.dob), {
      representation: 'date',
    });
  }

  if (credentials.iin) {
    body.credentials.iin = credentials.iin;
  }

  if (credentials.firstName) {
    body.credentials.firstName = credentials.firstName;
  }

  if (credentials.lastName) {
    body.credentials.lastName = credentials.lastName;
  }

  try {
    const res: APIResponse<T> = await apiFetch<T, AuthPayload>(
      uri,
      {
        body,
      },
      decoder
    );
    return handleResponse(res);
  } catch (err) {
    if (err instanceof UnauthorizedFromAuthError) {
      const responseBody = err.data.body;
      if (isAPIError(responseBody)) {
        return handleResponse({
          response: null,
          error: responseBody,
        });
      }
    }
    logger.error({ err }, 'Unexpected error in auth');
    throw err;
  }
}
