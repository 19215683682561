import * as io from 'io-ts';
import { Money } from './Money';
var VirtualCardSettings = io.type({
  enabled: io["boolean"],
  provider: io.string
});
export var Capabilities = io.type({
  downPayment: io.type({
    installments: io["boolean"]
  })
});
export var MerchantProgramValues = io.type({
  id: io.string,
  merchantID: io.string,
  programID: io.string,
  defaultOTPMethod: io.string,
  fundingPaymentMethodID: io.union([io.string, io["null"]]),
  defaultCartAmount: Money,
  policies: io.union([io.undefined, io.type({
    virtualCardSettings: io.union([io.undefined, VirtualCardSettings])
  })]),
  createdAt: io.string,
  capabilities: Capabilities,
  derivedCapabilities: Capabilities,
  // Probably not relevant for us?
  deletedAt: io.union([io.string, io["null"]])
});