import * as io from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
export var LocalizedString = io.type({
  en: io.string,
  fr: io.string
});
export function isLocale(locale) {
  return locale ? Object.keys(LocalizedString.props).includes(locale) : false;
}
export function isLocalizedString(localeString) {
  if (!localeString) {
    return false;
  }

  var decoded = LocalizedString.decode(localeString);
  return isRight(decoded);
}