import { ID_VERIFICATION_STATUS_CODES } from '@ads-bread/shared/bread/util';

const NEEDS_MANUAL_REVIEW_STATUS_CODES = [
  ID_VERIFICATION_STATUS_CODES.DocumentEvaluationHighRisk,
  ID_VERIFICATION_STATUS_CODES.DocumentEvaluationExtendedFraudAlert,
];

const ID_VERIFICATION_FAILED_STATUS_CODES = [
  ID_VERIFICATION_STATUS_CODES.KYCFailIDDenial,
];

export const needsManualReview = (statusCode: string): boolean => {
  return Boolean(
    statusCode && NEEDS_MANUAL_REVIEW_STATUS_CODES.includes(statusCode)
  );
};

export const hasFailed = (statusCode: string): boolean => {
  return Boolean(
    statusCode && ID_VERIFICATION_FAILED_STATUS_CODES.includes(statusCode)
  );
};
