import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SVGIcon } from './svgHelpers';

export const Spinner: SVGIcon = (props) => {
  const {
    className,
    'data-testid': dataTestID = 'spinner-icon',
    ...otherProps
  } = props;
  return (
    <svg
      data-testid={dataTestID}
      className={`animate-spin fill-current motion-reduce:hidden ${className}`}
      aria-label="loading"
      viewBox="0 0 24 24"
      fill="none"
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <title id="svg-icon-loading">
        <FormattedMessage
          defaultMessage="Loading..."
          description="Loading spinner title"
        />
      </title>
      <path
        opacity="0.2"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.33614 12C2.33614 17.3372 6.6628 21.6639 12 21.6639C17.3372 21.6639 21.6639 17.3372 21.6639 12C21.6639 6.6628 17.3372 2.33614 12 2.33614C6.6628 2.33614 2.33614 6.6628 2.33614 12Z"
      />
      <path d="M21.7502 7.28152C22.3309 7.0005 23.0353 7.24199 23.2585 7.84723C23.9406 9.69623 24.1588 11.6909 23.8854 13.6547C23.5581 16.0054 22.5411 18.2063 20.963 19.979C19.3849 21.7517 17.3166 23.0167 15.0196 23.6139C13.1006 24.1128 11.0941 24.127 9.17856 23.6636C8.55154 23.5119 8.23013 22.8402 8.44203 22.2309C8.65393 21.6216 9.31905 21.3069 9.94947 21.4438C11.4247 21.7641 12.9602 21.7355 14.4317 21.3529C16.2816 20.872 17.9472 19.8533 19.2181 18.4257C20.489 16.9981 21.308 15.2257 21.5715 13.3326C21.7812 11.8267 21.6319 10.2982 21.1429 8.86994C20.934 8.25961 21.1695 7.56253 21.7502 7.28152Z" />
    </svg>
  );
};
