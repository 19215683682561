import React from 'react';
import { SVGIcon } from './svgHelpers';

export const X: SVGIcon = (props) => (
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`fill-current ${props.className}`}
  >
    <path d="M13.6894 0.321183C13.491 0.122356 13.2217 0.0106181 12.9408 0.0106181C12.66 0.0106181 12.3906 0.122356 12.1923 0.321183L7 5.50283L1.80774 0.310565C1.60936 0.111738 1.34003 0 1.05916 0C0.77829 0 0.50896 0.111738 0.31058 0.310565C-0.103527 0.724672 -0.103527 1.39361 0.31058 1.80772L5.50284 6.99998L0.31058 12.1922C-0.103527 12.6064 -0.103527 13.2753 0.31058 13.6894C0.724687 14.1035 1.39363 14.1035 1.80774 13.6894L7 8.49714L12.1923 13.6894C12.6064 14.1035 13.2753 14.1035 13.6894 13.6894C14.1035 13.2753 14.1035 12.6064 13.6894 12.1922L8.49716 6.99998L13.6894 1.80772C14.0929 1.40423 14.0929 0.724672 13.6894 0.321183Z" />
  </svg>
);
