import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useAppConfig } from '../../components/AppConfigContext';
import { isBlacklistedPOBox, transformRBCPostal } from '../address';
import {
  ADDRESS_REGEX,
  CITY_REGEX,
  CONTAINS_CARE_OF_REGEX,
  POSTAL_CODE_REGEX,
  ZIP_REGEX,
} from '../regex';

export function useAddressSchema(): yup.ObjectSchema {
  const { formatMessage } = useIntl();
  const { addressValidationSchemaName } = useAppConfig();
  const specialCharactersMessage = formatMessage({
    defaultMessage: 'Special characters are not allowed',
    description: 'Inline validation Billing Address field',
  });
  const postalCodeValidationMsg = formatMessage({
    defaultMessage: 'Please enter a valid postal code',
    description: 'Inline validation Address Postal Code validation message',
  });

  const noPoBoxValidationMessage = formatMessage({
    defaultMessage:
      'We can’t offer financing with a PO Box. Please enter a valid street address',
    description: 'Inline validation Billing Address PO Box check',
  });

  const noAddressCareOfValidationMessage = formatMessage({
    defaultMessage: 'Please enter a valid address',
    description: "Inline validation Billing Address 'care of' check",
  });
  const schemas = {
    USAddressSchema: yup.object().shape({
      address1: yup
        .string()
        .required()
        .min(4)
        .max(255)
        .matches(ADDRESS_REGEX, specialCharactersMessage)
        .test(
          'no-po-box',
          formatMessage({
            defaultMessage:
              'We can’t offer financing with a PO Box. Please enter a valid street address',
            description: 'Inline validation Billing Address PO Box check',
          }),
          (value) => {
            if (!value) {
              return false;
            }
            return !isBlacklistedPOBox(value);
          }
        ),
      address2: yup
        .string()
        .notRequired()
        .max(255)
        .matches(ADDRESS_REGEX, specialCharactersMessage),
      locality: yup.string().required().min(2).max(255).matches(CITY_REGEX),
      region: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.string().required().min(5).matches(ZIP_REGEX),
    }),
    CanadaAddressSchema: yup.object().shape({
      address1: yup
        .string()
        .required()
        .min(4)
        .max(255)
        .matches(
          ADDRESS_REGEX,
          formatMessage({
            defaultMessage: 'Special characters are not allowed',
            description: 'Inline validation Billing Address field',
          })
        )
        .test('no-po-box', noPoBoxValidationMessage, (value) => {
          if (!value) {
            return false;
          }
          return !isBlacklistedPOBox(value);
        })
        .test('no-care-of', noAddressCareOfValidationMessage, (value) => {
          if (!value) {
            return false;
          }
          return !CONTAINS_CARE_OF_REGEX.test(value);
        }),
      address2: yup
        .string()
        .notRequired()
        .max(255)
        .matches(
          ADDRESS_REGEX,
          formatMessage({
            defaultMessage: 'Special characters are not allowed',
            description:
              'Inline validation Billing Address optional fields check',
          })
        ),
      locality: yup.string().required().min(2).max(255).matches(CITY_REGEX),
      region: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup
        .string()
        .transform(transformRBCPostal)
        .required(postalCodeValidationMsg)
        .min(6, postalCodeValidationMsg)
        .matches(POSTAL_CODE_REGEX, postalCodeValidationMsg),
    }),
  };
  return schemas[addressValidationSchemaName];
}
