import * as io from 'io-ts';
import { Order } from './Application';
import { Address, Name } from './Buyer';
var ContactOptional = io.type({
  shippingAddress: io.union([Address, io.undefined]),
  billingAddress: io.union([Address, io.undefined]),
  name: io.union([Name, io.undefined])
});
var ContactRequired = io.type({
  email: io.string,
  phone: io.string
});
var Contact = io.intersection([ContactRequired, io.partial(ContactOptional.props)]);
var Disclosure = io.type({
  name: io.string,
  acceptedAt: io.string
});
var Disclosures = io.union([io.array(Disclosure), io["null"]]);
export var Cart = io.type({
  id: io.string,
  checkoutCompleteURL: io.string,
  checkoutErrorURL: io.string,
  createdAt: io.string,
  updatedAt: io.string,
  expiresAt: io.string,
  merchantID: io.string,
  programID: io.string,
  status: io.union([io.literal('ACTIVE'), io.literal('EXPIRED'), io.literal('DELETED'), io.literal('COMPLETED'), io.literal('VISITED'), io.literal('NOTIFIED')]),
  orderReference: io.string,
  contact: Contact,
  order: Order,
  disclosures: Disclosures,
  isHipaaRestricted: io["boolean"]
});