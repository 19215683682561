import { Application } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const GET_APPLICATION_PATH = '/api/application';

export async function getApplication(
  applicationID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<Application>> {
  const res: APIResponse<Application> = await apiFetch<Application>(
    `${GET_APPLICATION_PATH}/${applicationID}`,
    { method: 'GET' },
    Application
  );
  const response = handleResponse(res);

  return response;
}
