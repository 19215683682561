// N.B. This file is a duplicate of that at apps/apply/lib/namespace.ts
// So far there is no way to share files in libs that are used by our custom
// server.
import { Tenant, isTenantKey } from './tenant';

export const ADS = 'alliance';
export const RBC = 'rbc';
export const INSTORE = 'in-store/[uuid]';

export const NAMESPACE_MAP = {
  ADS,
  RBC,
  INSTORE,
} as const;

export const NAMESPACE_TO_TENANT_MAP = {
  [ADS]: 'ADS',
  [INSTORE]: 'ADS',
  [RBC]: 'RBC',
};

export type Namespace = keyof typeof NAMESPACE_MAP;
export type NamespacePath = typeof NAMESPACE_MAP[keyof typeof NAMESPACE_MAP];

export function isNamespaceKey(
  namespace: string | undefined | null
): namespace is Namespace {
  if (!namespace) {
    return false;
  }
  return Object.keys(NAMESPACE_MAP).includes(namespace);
}

export function isNamespacePath(
  path: string | NamespacePath | undefined
): path is NamespacePath {
  if (!path) {
    return false;
  }

  return Object.values<string>(NAMESPACE_MAP).includes(path);
}

export function tenantFromNamespacePath(namespacePath: NamespacePath): Tenant {
  if (!isNamespacePath(namespacePath)) {
    throw new Error('not a path');
  }

  const tenant = NAMESPACE_TO_TENANT_MAP[namespacePath];

  if (!isTenantKey(tenant)) {
    throw new Error('not a tenant');
  }

  return tenant;
}
