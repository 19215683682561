import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from './Button';
import { X } from './svgs/X';
import { Popover } from './Popover';

type Props = {
  [key: string]: any;
};

export const LanguageConfirmation: FunctionComponent<Props> = function ({
  setShow,
  confirm,
  cancel,
}: Props) {
  return (
    <Popover>
      <Popover.Title>
        <X
          className="fill-current w-4 h-4 filter hover:drop-shadow-lg text-dark-blue-grey float-right cursor-pointer"
          aria-hidden="true"
          onClick={() => {
            setShow(false);
            cancel();
          }}
          data-testid="language-confirmation-close"
        />
        <FormattedMessage
          defaultMessage="Change language preference?"
          description="Language preference header"
        />
      </Popover.Title>
      <FormattedMessage
        defaultMessage="By changing your language preference, you'll also start receiving all your communications in your new language preference"
        description="Language preference reminder"
      />
      <Button
        className="mt-5"
        onClick={() => {
          setShow(false);
          confirm();
        }}
      >
        <div data-testid="language-toggle-confirm">
          <FormattedMessage
            defaultMessage="Yes, Change My Language"
            description="Confirm language change"
          />
        </div>
      </Button>
      <div
        className="underline text-theme-primary font-bold hover:text-huckleberry cursor-pointer"
        data-testid="language-toggle-cancel"
        onClick={() => {
          setShow(false);
          cancel();
        }}
      >
        <FormattedMessage
          data-testid="language-toggle-cancel"
          defaultMessage="No, keep my current language"
          description="Language confirmation cancel"
        />
      </div>
    </Popover>
  );
};
