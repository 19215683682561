import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { ObjectSchema, Lazy } from 'yup';
import { FCWithChildren } from '../lib/types';

type YupValidationSchemaContextProps = {
  isRequiredField: (name: string) => boolean;
};

const YupValidationSchemaContext =
  createContext<YupValidationSchemaContextProps>({
    isRequiredField: () => {
      return false;
    },
  });

interface ExtendedYupObjectSchema extends ObjectSchema {
  resolve?: (options: any) => any;
}

interface ExtendedYupLazy extends Lazy {
  resolve?: (options: any) => any;
}

export type YupValidationSchemaProviderProps = {
  validationSchema: ExtendedYupObjectSchema | ExtendedYupLazy | undefined;
};

// Does not work with yup.Lazy. Because the form values are needed to call resolve,
// it causes slowness when validating because we need to resolve on every
// keystroke to get the schema information.
export const YupValidationSchemaProvider: FCWithChildren<YupValidationSchemaProviderProps> =
  ({ validationSchema, children }) => {
    const isRequiredCache = useRef<Record<string, boolean>>({});

    const memoSchema = useMemo(() => {
      isRequiredCache.current = {};
      return validationSchema?.type ? validationSchema : undefined;
    }, [validationSchema]);

    const isRequiredField = useCallback(
      (name: string) => {
        if (!memoSchema) {
          return false;
        }

        if (isRequiredCache?.current[name]) {
          return !!isRequiredCache.current[name];
        }

        try {
          const helper = (schema: any, fieldNames: string[]): boolean => {
            const n = fieldNames.shift();
            if (fieldNames.length === 0) {
              if (!schema?.describe) {
                return false;
              }
              const fieldValidationSchema = schema.describe().fields[n || ''];
              const tests = fieldValidationSchema
                ? fieldValidationSchema.tests
                : false;
              const result = tests
                ? !!tests.find(
                    (test: { name: string }) => test.name === 'required'
                  )
                : false;
              isRequiredCache.current[name] = result;
              return result;
            }
            return helper(schema.fields[n || ''], fieldNames);
          };
          return helper(memoSchema, name.split('.'));
        } catch (e) {
          return false;
        }
      },
      [memoSchema]
    );

    return (
      <YupValidationSchemaContext.Provider value={{ isRequiredField }}>
        {children}
      </YupValidationSchemaContext.Provider>
    );
  };

export const useYupValidationSchema = (): YupValidationSchemaContextProps => {
  const context = useContext(YupValidationSchemaContext);
  if (!context) {
    throw new Error('Cannot use context while null');
  }

  return context;
};
