import * as io from 'io-ts';
import { DisclosuresAttribute } from './Disclosures';
export var DeliveryMethod = io.union([io.literal('SMS'), io.literal('EMAIL')]);
var UatPayloadPartial = io.type({
  auth: io.type({
    token: io.string
  })
});
export var SendCodePayload = io.type({
  email: io.union([io.string, io.undefined, io["null"]]),
  phone: io.union([io.string, io.undefined, io["null"]]),
  deliveryMethod: DeliveryMethod,
  disclosures: DisclosuresAttribute,
  uat: io.union([UatPayloadPartial, io.undefined])
});
export var CodeResult = io.type({
  referenceID: io.string
});