import {
  IDVerificationUploadResponse,
  IDVerificationEvaluationResponse,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const ID_VERIFICATION_URI = '/api/id-verification/';

export async function uploadIDVerificationImage(
  apiFetch: APIFetch,
  buyerID: string,
  image: Blob
): Promise<HandlerResponse<IDVerificationUploadResponse>> {
  const formData = new FormData();

  formData.append('buyerID', buyerID);
  formData.append('image', image);

  const res: APIResponse<IDVerificationUploadResponse> = await apiFetch(
    ID_VERIFICATION_URI + 'document-upload',
    {
      method: 'POST',
      body: formData,
      headers: {},
    },
    IDVerificationUploadResponse
  );

  return handleResponse(res);
}

export async function evaluateIDVerificationImages(
  apiFetch: APIFetch,
  applicationID: string,
  origin: '04' | '05',
  frontImageID: string,
  backImageID: string
): Promise<HandlerResponse<IDVerificationEvaluationResponse>> {
  const res: APIResponse<IDVerificationEvaluationResponse> = await apiFetch(
    ID_VERIFICATION_URI + 'document-evaluation',
    {
      method: 'POST',
      body: {
        scopeType: 'APPLICATION',
        scopeID: applicationID,
        origin: origin,
        frontImageID: frontImageID,
        backImageID: backImageID,
      },
      headers: {},
    },
    IDVerificationEvaluationResponse
  );

  return handleResponse(res);
}
