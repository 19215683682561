import { AggregatedMerchantProgramConfiguration } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const programDataURI = (programID: string, merchantID: string): string =>
  `/api/programs/${programID}/merchants/${merchantID}`;

export async function getProgramData(
  apiFetch: APIFetch,
  programID: string,
  merchantID: string
): Promise<HandlerResponse<AggregatedMerchantProgramConfiguration>> {
  const uri = programDataURI(programID, merchantID);
  const res: APIResponse<AggregatedMerchantProgramConfiguration> =
    await apiFetch<AggregatedMerchantProgramConfiguration>(
      uri,
      { method: 'GET' },
      AggregatedMerchantProgramConfiguration
    );
  return handleResponse(res);
}
