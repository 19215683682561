import * as io from 'io-ts';
export var GetFraudAlloyRequest = io.type({});
export var GetFraudAlloyResponse = io.type({
  journey_token: io.string,
  journey_application_token: io.string,
  journey_sdk_key: io.string,
  origin: io.string
});
export var NeuroIDResponse = io.type({
  neuroIdSiteId: io.string,
  neuroUserId: io.string
});