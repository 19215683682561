import React from 'react';
import { SVGIcon } from './svgHelpers';

export const ExclamationMarkTriangle: SVGIcon = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8665 3.08624C13.053 1.63792 10.947 1.63792 10.1335 3.08624L1.27177 18.8626C0.481111 20.2702 1.51022 22 3.1383 22H20.8617C22.4898 22 23.5189 20.2702 22.7282 18.8626L13.8665 3.08624ZM12 4.11184L20.8617 19.8882H3.1383L12 4.11184ZM11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9ZM13 16V18H11V16H13Z"
    />
  </svg>
);
