import { FunctionComponent } from 'react';
import { TextLink } from './TextLink';

export const LinkToFAQs: FunctionComponent<{
  text?: string;
  url: string;
  testID?: string;
}> = ({ text, url, testID }) => (
  <TextLink
    href={url}
    className="underline text-theme-primary font-bold hover:text-huckleberry"
    testID={testID || 'bread-faq'}
    external
  >
    {text || 'FAQs'}
  </TextLink>
);
