import { APIError } from '@ads-bread/shared/bread/codecs';
import { APIResponse } from '../hooks/apiFetch';
import { track, AnalyticsEvent } from '../analytics';

export type ResultResponse<T> = { error: null; result: T };
export type ErrorResponse = { error: APIError; result: null };
export type HandlerResponse<T> = ResultResponse<T> | ErrorResponse;

export type HandlerWrapper<T, R, O = void> = (
  outgoing: T,
  optional?: O
) => Promise<HandlerResponse<R>>;

export type FetchHandlerWrapper<R, A = unknown> = (
  args: A
) => Promise<HandlerResponse<R>>;

export function handleResponse<T>(res: APIResponse<T>): HandlerResponse<T> {
  if (res.error) {
    return toErrorResponse(res.error);
  }
  return toResultResponse(res.response.body);
}

export function toErrorResponse(error: APIError): ErrorResponse {
  track(AnalyticsEvent.FormSubmitError, { reason: error.reason });

  return { error, result: null };
}

export function toResultResponse<T>(result: T): ResultResponse<T> {
  return { error: null, result };
}
