import * as io from 'io-ts';
import { Money } from './Money';
export var ScheduledPayment = io.type({
  id: io.string,
  dueDate: io.string,
  amount: Money,
  interest: Money,
  principal: Money
});
// payment agreement from servicing API
export var PaymentAgreementSchedule = io.type({
  id: io.string,
  schedule: io.type({
    installments: io.array(ScheduledPayment)
  }),
  balanceSummary: io.type({
    totalInterestEstimate: Money
  })
});
export var PaymentAgreementResponse = io.type({
  paymentAgreement: PaymentAgreementSchedule
});