import * as io from 'io-ts';
import { LocalizedString } from './Locale';
export var ButtonUIConfig = io.intersection([io.type({
  backgroundColor: io.string,
  borderColor: io.string,
  borderWidth: io.number,
  includeLogo: io["boolean"],
  logoColor: io.union([io.string, io.undefined]),
  textColor: io.string,
  width: io.union([io.number, io.undefined, io["null"]])
}), io.partial({
  logoPosition: io.string,
  buttonLogo: io.union([io.literal('BREAD_MONOCHROME_EN'), io.literal('PAY_PLAN_RGB_EN'), io.literal('PAY_PLAN_RGB_FR'), io.literal('PAY_PLAN_MONOCHROME_EN'), io.literal('PAY_PLAN_MONOCHROME_FR'), LocalizedString]),
  additionalText: io.union([io.string, LocalizedString]),
  additionalTextFontStyle: io.string,
  additionalTextDecoration: io.string
})]);
export var MerchantConfig = io.partial({
  logoUrl: io.union([io.string, LocalizedString]),
  headerImgUrl: io.string,
  checkoutPrimaryColor: io.string,
  checkoutSecondaryColor: io.string,
  disclosureSymbolInstallments: io.string,
  disclosureSymbolSplitpay: io.string,
  disclosureSymbolSplitpayInterest: io.string,
  marketingCardPlacementID: io.string,
  marketingDescription: io.string,
  marketingImageUrl: io.string,
  marketingUrl: io.string,
  merchantTier: io.union([io.literal(1), io.literal(2)]),
  paymentMethodDisplay: io.union([io.string, LocalizedString]),
  noOrderPromoTextInstallments: io.string,
  buttonConfig: io.union([ButtonUIConfig, io["null"], io.undefined])
});
export var MerchantConfigResponse = io.partial({
  shop: MerchantConfig
});