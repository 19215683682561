import * as io from 'io-ts';
var IDVerificationScopeType = io.literal('APPLICATION'); // ID Verification Upload types

export var IDVerificationUploadOutgoing = io.type({
  buyerID: io.string,
  image: io.string
});
export var IDVerificationUploadResponse = io.type({
  buyerID: io.string,
  imageID: io.string
}); // ID Verification Evaluation types

var OriginValues = io.union([io.literal('04'), io.literal('05')]);
export var IDVerificationEvaluationOutgoing = io.type({
  frontImageID: io.string,
  backImageID: io.string,
  origin: OriginValues,

  /** Only supports APPLICATION */
  scopeType: IDVerificationScopeType,

  /** Only supports applicationID */
  scopeID: io.string
});
export var IDVerificationEvaluationResponse = io.type({
  buyerID: io.string,
  metadata: io.type({
    attempts: io.number,
    maxAttempts: io.number,
    failReasons: io.union([io.array(io.string), io.undefined])
  }),
  statusCode: io.string
});