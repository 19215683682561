import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { useTierCustomizations } from '../MerchantContext';
import { BreadLogo } from '../svgs/BreadLogo';
import { Encrypted } from './Encrypted';

export const ADSFooter: FunctionComponent = () => {
  const { route } = useRouter();
  const { showFooterBreadBranding, showIntroPageFooter } =
    useTierCustomizations();

  if (route === '/alliance' && !showIntroPageFooter) {
    return null;
  }

  return (
    <div
      data-testid="footer"
      className="text-sm text-dark-blue-grey flex items-center space-x-1 mt-16 mb-1"
    >
      <Encrypted />
      {showFooterBreadBranding && <BreadFooterBranding />}
    </div>
  );
};

const BreadFooterBranding = (): JSX.Element => {
  return (
    <div
      data-testid="bread-branding-footer"
      className="flex flex-1 justify-end items-center"
    >
      <FormattedMessage
        defaultMessage="Powered by"
        description="Footer Bread branding pre logo"
      />
      <BreadLogo className="w-16 pl-1" fill="#4E5C6F" fillLogo="#4E5C6F" />
    </div>
  );
};
