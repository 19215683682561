import {
  Application,
  DisclosuresAttribute,
  Order,
  OutgoingApplication,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { logger } from '../logger';
import { HandlerResponse, toErrorResponse, toResultResponse } from './base';

export const APPLICATION_URI = '/api/application';

export async function createApplication(
  order: Order,
  extensions: Application['extensions'],
  disclosures: DisclosuresAttribute,
  retargetingURL: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<Application>> {
  const res: APIResponse<Application> = await apiFetch<
    Application,
    OutgoingApplication
  >(
    APPLICATION_URI,
    {
      body: {
        order,
        disclosures,
        extensions,
        retargetingURL,
      },
    },
    Application
  );

  if (res.error) {
    logger.error({ err: res.error }, 'Error creating application');
    return toErrorResponse(res.error);
  }

  return toResultResponse(res.response.body);
}
