import * as io from 'io-ts';
export var CreatePaymentMethodTokenRequest = io.type({
  scopeID: io.string,
  scopeType: io.literal('BUYER'),
  provider: io.literal('CYBERSOURCE'),
  targetOrigins: io.array(io.string)
});
var CreatePaymentMethodAccessToken = io.type({
  expiresAt: io.string,
  issuedAt: io.string,
  provider: io.literal('CYBERSOURCE'),
  token: io.string
});
export var CreatePaymentMethodTokenResponse = io.type({
  accessToken: CreatePaymentMethodAccessToken
});
export var PaymentMethodTypes;

(function (PaymentMethodTypes) {
  PaymentMethodTypes["CardAccount"] = "CARD_ACCOUNT";
  PaymentMethodTypes["BankAccount"] = "BANK_ACCOUNT";
})(PaymentMethodTypes || (PaymentMethodTypes = {}));

export var AccountTypes;

(function (AccountTypes) {
  AccountTypes["Checking"] = "CHECKING";
  AccountTypes["Savings"] = "SAVINGS";
})(AccountTypes || (AccountTypes = {}));

export var CardAccount = io.partial({
  cardType: io.string,
  cardName: io.string,
  last4: io.string,
  expirationMonth: io.string,
  expirationYear: io.string,
  nonce: io.string,
  provider: io.string,
  disabled: io["boolean"],
  postalCode: io.string
});
export var BankAccountSetupTypes;

(function (BankAccountSetupTypes) {
  BankAccountSetupTypes["ACH"] = "ACH";
  BankAccountSetupTypes["EFT"] = "EFT";
})(BankAccountSetupTypes || (BankAccountSetupTypes = {}));

export var BankAccountCountry;

(function (BankAccountCountry) {
  BankAccountCountry["US"] = "US";
  BankAccountCountry["CA"] = "CA";
})(BankAccountCountry || (BankAccountCountry = {}));

export var BankAccountCurrency;

(function (BankAccountCurrency) {
  BankAccountCurrency["USD"] = "USD";
  BankAccountCurrency["CAD"] = "CAD";
})(BankAccountCurrency || (BankAccountCurrency = {}));

export var BankAccount = io.partial({
  bankName: io.string,
  accountType: io.string,
  setupType: io.union([io.literal(BankAccountSetupTypes.ACH), io.literal(BankAccountSetupTypes.EFT)]),
  accountNumber: io.string,
  routingNumber: io.string,
  country: io.union([io.literal(BankAccountCountry.US), io.literal(BankAccountCountry.CA)]),
  currency: io.union([io.literal(BankAccountCurrency.USD), io.literal(BankAccountCurrency.CAD)])
});
export var StoredPaymentMethod = io.intersection([io.type({
  id: io.string,
  paymentMethodType: io.string,
  scopeID: io.string,
  scopeType: io.unknown //only need this attribute to be optional

}), io.partial({
  cardAccount: CardAccount,
  bankAccount: BankAccount
})]);
export var PaymentMethodType = io.string;
export var CreatePaymentMethodResponse = io.type({
  paymentMethod: io.intersection([StoredPaymentMethod, io.type({
    name: io.string
  })])
});
export var PaymentMethodResponse = io.type({
  paymentMethod: StoredPaymentMethod
});
export var PaymentMethodsResponse = io.type({
  paymentMethods: io.array(StoredPaymentMethod)
});