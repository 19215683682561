export var ADSProgram = {
  currency: 'USD',
  defaultLanguage: 'en-us',
  displayInterestRateBPS: 999,
  id: 'b7beac2e-2714-4d1d-b110-72e405b41c34',
  name: 'Nitro',
  tenantID: '72dbd9ee-f5af-4983-955f-0ffcac631e3d',
  policies: {
    application: {
      tof: {
        disclosures: [{
          type: 'terms-of-use',
          requiresChecksum: false
        }, {
          type: 'privacy-policy-choices',
          requiresChecksum: false
        }]
      }
    }
  },
  capabilities: {
    downPayment: {
      installments: false
    }
  }
};
export var RBCProgram = {
  currency: 'CAD',
  defaultLanguage: 'en-ca',
  displayInterestRateBPS: 999,
  id: '8a25d7ef-6733-444a-8f93-b1a4ef8b43b6',
  name: 'RBC - Core',
  tenantID: 'e9b6dddf-3fdc-4f0e-9dd3-8c82b529eb70',
  policies: {
    application: {
      tof: {
        disclosures: [{
          type: 'terms-of-use',
          requiresChecksum: false
        }, {
          type: 'privacy-policy-choices',
          requiresChecksum: false
        }]
      }
    }
  },
  capabilities: {
    downPayment: {
      installments: false
    }
  }
};