import * as io from 'io-ts';
export var ExperienceKeys = io.intersection([io.type({
  id: io.string,
  programID: io.string,
  merchantID: io.string,
  appID: io.string
}), io.partial({
  externalID: io.string,
  locationID: io.string,
  locationShortCode: io.union([io.string, io["null"]])
})]);