import pino, { Logger, LoggerOptions as PinoLoggerOptions } from 'pino';
import { AppConfig, loadConfig } from '../config/config';
import { createSplunkLogger } from './splunk';
import { createConsoleLogger } from './console';
import { LoggerClient } from './client';

export type { Logger };

// Enforce standardized messageKey (default: 'msg')
const messageKey = 'msg';
interface LogRecord extends Record<string, unknown> {
  [messageKey]?: string;
}

type BaseName = 'checkout';
type LoggerName = BaseName | `${BaseName}.${string}`;
export interface LoggerOptions extends PinoLoggerOptions {
  name?: LoggerName;
  messageKey?: typeof messageKey;
}

const cache: Map<string, Logger> = new Map();
let appConfigCache: AppConfig | null = null;

export function clearCache(): void {
  cache.clear();
}

export function clearConfigCache(): void {
  appConfigCache = null;
}

interface ClientConfig {
  isConsoleActive: boolean;
  useSplunkEventCollector: boolean;
  splunkEventCollectorToken: string;
  splunkEventCollectorUrl: string;
}

export interface ApplicationConfigSlice {
  environment: string;
  useConsoleLogging: boolean;
}

export const defaultAppConfig: ApplicationConfigSlice = {
  environment: 'development',
  useConsoleLogging: true,
};

/**
 * Since this isn't a react hook the best way to grab the config in browser mode
 * is to grab the __NEXT_DATA__ script tag and parse it's contents.
 *
 * @throws {Error} If the __NEXT_DATA__ script tag is not found
 * @throws {Error} If the __NEXT_DATA__ script tag does not contain the expected contents
 */
const getBrowserAppConfig = (): AppConfig => {
  const isScriptElement = (el: HTMLElement): el is HTMLScriptElement =>
    el.tagName === 'SCRIPT';

  const maybeNextDataScriptTag = document.getElementById('__NEXT_DATA__');

  if (!maybeNextDataScriptTag || !isScriptElement(maybeNextDataScriptTag)) {
    throw new Error('Could not find __NEXT_DATA__ script tag');
  }

  const malformedContentsMessage = 'Unexpected __NEXT_DATA__ contents';
  let maybeNextData;

  try {
    maybeNextData = JSON.parse(maybeNextDataScriptTag.text);
  } catch (e) {
    throw new Error(malformedContentsMessage);
  }

  if (!maybeNextData || !maybeNextData.props || !maybeNextData.props.config) {
    throw new Error(malformedContentsMessage);
  }

  return maybeNextData.props.config;
};

const getAppConfig = (): AppConfig | Error => {
  if (!appConfigCache) {
    if (typeof window === 'undefined') {
      appConfigCache = loadConfig();
    } else {
      try {
        appConfigCache = getBrowserAppConfig();
      } catch (e) {
        if (e instanceof Error) {
          return e;
        } else {
          return new Error('Unknown error parsing __NEXT_DATA__');
        }
      }
    }
  }

  return appConfigCache;
};

export function createLogger(opts: LoggerOptions = {}): Logger {
  const name = opts.name || 'checkout';
  let logger = cache.get(name);

  if (!logger) {
    const defaultOpts: PinoLoggerOptions = {
      name,
    };

    const clientConfig: ClientConfig = {
      isConsoleActive: false,
      useSplunkEventCollector: true,
      splunkEventCollectorToken: '',
      splunkEventCollectorUrl: '',
    };

    const maybeAppConfig = getAppConfig();

    let deferredError: Error | null = null;

    if (maybeAppConfig instanceof Error) {
      deferredError = maybeAppConfig;
    } else {
      clientConfig.isConsoleActive = maybeAppConfig.useConsoleLogging;
      clientConfig.splunkEventCollectorToken =
        maybeAppConfig.splunkEventCollectorToken;
      clientConfig.splunkEventCollectorUrl =
        maybeAppConfig.splunkEventCollectorUrl;
    }

    if (process.env.NODE_ENV !== 'production') {
      clientConfig.useSplunkEventCollector = false;
    }

    defaultOpts.browser = getBrowserOptions(clientConfig);

    if (process.env.LOG_LEVEL) {
      defaultOpts.level = process.env.LOG_LEVEL;
    }

    logger = pino({ ...defaultOpts, ...opts });
    cache.set(name, logger);

    if (deferredError) {
      logger.error(deferredError);
    }
  }

  return logger;
}

function getBrowserOptions(
  useLoggerClient: ClientConfig
): LoggerOptions['browser'] {
  const clients: Array<LoggerClient> = [
    createConsoleLogger(useLoggerClient.isConsoleActive),
  ];

  if (useLoggerClient.useSplunkEventCollector) {
    const client = createSplunkLogger({
      ...useLoggerClient,
    });

    if (client) {
      clients.push(client);
    }
  }

  const browser: LoggerOptions['browser'] = {
    write: {
      fatal: proxy(clients, 'error'),
      error: proxy(clients, 'error'),
      warn: proxy(clients, 'warn'),
      info: proxy(clients, 'info'),
      debug: proxy(clients, 'debug'),
      trace: proxy(clients, 'debug'), // Q: send traces to DD?
    },
  };
  return browser;
}

function proxy(extLoggers: LoggerClient[], levelStr: keyof LoggerClient) {
  return (o: object) => {
    const rec = o as LogRecord;
    const { [messageKey]: msg, ...attrs } = rec;
    extLoggers.forEach((extLogger) => {
      extLogger[levelStr](msg || '', attrs);
    });
  };
}
