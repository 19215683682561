import * as yup from 'yup';

export function configureValidationLocale(): void {
  yup.setLocale({
    string: {
      min: ({ min, path, ...other }) => ({
        key: 'forms.validation.minLength',
        values: { min, path, other },
      }),
      max: ({ max, path, ...other }) => ({
        key: 'forms.validation.maxLength',
        values: { max, path, other },
      }),
      matches: (options) => ({
        key: `matches`,
        values: options,
      }),
      email: (options) => ({
        key: `email`,
        values: options,
      }),
    },
    date: {
      min: ({ min, path, ...other }) => ({
        key: 'forms.validation.date.min',
        values: { min, path, other },
      }),
      max: ({ max, path, ...other }) => ({
        key: 'forms.validation.date.max',
        values: { max, path, other },
      }),
    },
    mixed: {
      required: ({ path, ...other }) => ({
        key: 'required',
        values: { path, other },
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notType: ({ path, ...other }) => ({
        key: 'typeError',
        values: { path, other },
      }),
      default: ({ path, ...other }) => ({
        key: 'invalid',
        values: { path, other },
      }),
    },
  });
}

export default yup;
