import { PaymentType } from '@ads-bread/shared/bread/codecs';
import { PromoOffer } from '@ads-bread/shared/bread/util';

export interface PromoOffersByType {
  [type: string]: PromoOffer | never;
}

/**
 * Groups the {@link PromoOffer}s by {@link PaymentType}.
 * @param promoOffers - The {@link PromoOffer}s to group
 * @returns an object with the {@link PaymentType} as the keys, and the
 * {@link PromoOffer} with that type as the value.
 */
export function groupPromoOffersByType(
  promoOffers?: PromoOffer[]
): Record<PaymentType | string, PromoOffer> {
  return !promoOffers
    ? {}
    : promoOffers.reduce((promoOffersByType, promoOffer) => {
        return {
          ...promoOffersByType,
          [promoOffer.type]: promoOffer,
        };
      }, {});
}

/**
 * Returns true if there is an installments promo offer.
 * @param promoOffersByType The return value of {@link groupPromoOffersByType}
 */
export function hasInstallmentsPromoOffer(
  promoOffersByType?: Record<PaymentType, PromoOffer>
): boolean {
  return Boolean(promoOffersByType?.INSTALLMENTS);
}

/**
 * Returns true if there is an split pay promo offer.
 * @param promoOffersByType The return value of {@link groupPromoOffersByType}
 */
export function hasSplitPayPromoOffer(
  promoOffersByType?: Record<PaymentType, PromoOffer>
): boolean {
  return Boolean(promoOffersByType?.SPLITPAY);
}
