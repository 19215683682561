import React, { FunctionComponent } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import CallOut from '../CallOut';

type YupErrorValues = {
  path: string;
  value?: string;
  originalValue?: string;
  max?: number;
};

interface YupErrorHash {
  key: string;
  values: YupErrorValues;
}

export type ErrorValue = YupErrorHash | Record<string, never> | string;

export function isYupErrorHash(
  err: YupErrorHash | Record<string, never> | string | undefined
): err is YupErrorHash {
  if (!err || typeof err === 'string') {
    return false;
  }
  return typeof err?.key === 'string' && typeof err?.values === 'object';
}

interface InlineErrorProps {
  label: string;
  name: string;
  error?: YupErrorHash | string;
  touched: boolean;
  callout?: boolean;
  inputId?: string;
}
function getErrorMessage(err: YupErrorHash, label: string, intl: IntlShape) {
  switch (err.key) {
    case 'required':
      if (err.values.path === 'email') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label}. Ex. you@example.com',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      }
      if (err.values.path === 'birthDate') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label} in MM/DD/YYYY format',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      }
      if (err.values.path === 'last4IIN') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter the last 4 digits of your SSN',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      }
      if (err.values.path === 'file') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please upload a valid {label}',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label} ',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      }
    case 'email':
      return intl.formatMessage({
        defaultMessage:
          'Please enter a valid email address. Ex. you@example.com',
        description: 'Inline validation error message for email validation',
      });
    case 'forms.validation.date.min':
      return intl.formatMessage(
        {
          defaultMessage: 'Please enter a valid {label} in MM/DD/YYYY format',
          description: 'Inline validation error message for minimum input year',
        },
        { label: label.toLowerCase() }
      );
    case 'forms.validation.date.max':
      return intl.formatMessage(
        {
          defaultMessage: 'Please enter a valid {label} in MM/DD/YYYY format',
          description: 'Inline validation error message for maximum input year',
        },
        { label: label.toLowerCase() }
      );
    case 'matches':
      if (err.values.path === 'last4IIN') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter the last 4 digits of your SSN',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label}',
            description: 'Inline validation error message for regex matching',
          },
          // TODO: consider edge case of non-roman character sets
          { label: label.toLocaleLowerCase() }
        );
      }
    case 'forms.validation.minLength':
      return intl.formatMessage(
        {
          defaultMessage: 'Please enter a valid {label}',
          description:
            'Inline validation error message for minimum input length',
        },
        // TODO: consider edge case of non-roman character sets
        { label: label.toLocaleLowerCase() }
      );
    case 'forms.validation.maxLength':
      return intl.formatMessage(
        {
          defaultMessage: '{label} length cannot exceed {max} characters',
          description:
            'Inline validation error message for maximum input length',
        },
        // TODO: consider edge case of non-roman character sets
        {
          label: label.charAt(0) + label.slice(1).toLowerCase(),
          max: err.values.max,
        }
      );
    case 'invalid':
      return intl.formatMessage(
        {
          defaultMessage: 'Please enter a valid {label}',
          description: 'Inline validation error message for invalid input',
        },
        { label: label.toLocaleLowerCase() }
      );
    case 'typeError':
      if (err.values.path === 'birthDate') {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label} in MM/DD/YYYY format',
            description: 'Inline validation error message for required field',
          },
          { label: label.toLowerCase() }
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Please enter a valid {label}',
            description:
              'Inline validation error message for incorrect input type',
          },
          { label: label.toLocaleLowerCase() }
        );
      }
    default:
      return intl.formatMessage(
        {
          defaultMessage: 'Something is wrong with {label}',
          description: 'Inline validation error message for required field',
        },
        { label }
      );
  }
}

export const InlineError: FunctionComponent<InlineErrorProps> = ({
  error,
  label,
  touched,
  name,
  callout = false,
}) => {
  const intl = useIntl();

  const errorMessage = isYupErrorHash(error)
    ? getErrorMessage(error, label, intl)
    : error;
  const isError = touched && error;

  if (isError) {
    return (
      <>
        {callout ? (
          <CallOut type="Error" dataTestId={`validation-error-${name}`}>
            {errorMessage}
          </CallOut>
        ) : (
          <p
            id={`${name}-error`}
            data-testid={`${name}-error`}
            className="mt-2 text-sm leading-none text-theme-danger"
          >
            {errorMessage}
          </p>
        )}
      </>
    );
  }
  return null;
};
