import { FCWithChildren } from '../lib/types';
import { Custom404 } from '../pages/_error';
import { useAppConfig } from './AppConfigContext';
import { useIsDefaultSDKContextProps } from './XPropsContext';

export const ClientSideRouteGuard: FCWithChildren = ({ children }) => {
  const { allowDirectSDKPageAccess } = useAppConfig();
  const isDefaultXProps = useIsDefaultSDKContextProps();
  const isInvalidRoute = isDefaultXProps && !allowDirectSDKPageAccess;
  if (isInvalidRoute) {
    return <Custom404 />;
  }
  return <>{children}</>;
};
