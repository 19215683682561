import { Buyer, OutgoingBuyerWithID } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const BUYER_URI = '/api/buyer';

export async function updateBuyer(
  buyer: OutgoingBuyerWithID,
  apiFetch: APIFetch
): Promise<HandlerResponse<Buyer>> {
  const res: APIResponse<Buyer> = await apiFetch<Buyer, OutgoingBuyerWithID>(
    `${BUYER_URI}/${buyer.id}`,
    {
      method: 'PUT',
      body: buyer,
    },
    Buyer
  );
  return handleResponse(res);
}
