import { FunctionComponent, useMemo } from 'react';
import { ApplicationSubscriber } from './ApplicationMachineContext';
import { BuyerSubscriber } from './BuyerMachineContext';
import { AuthenticationSubscriber } from './AuthenticationContext';
import { RouteMachineServiceSubscriber } from './RouteMachineService';

/**
 * Shows machine states and context when the proper environment variables
 * are set.
 * @returns FunctionComponent | null
 */
export const MachineSubscribers: FunctionComponent = () => {
  const {
    useRouteMachineServiceSubscriber,
    useAuthenticationMachineSubscriber,
    useApplicationMachineSubscriber,
    useBuyerMachineSubscriber,
  } = useMemo(() => {
    return {
      useRouteMachineServiceSubscriber: !!(
        process.env.NEXT_PUBLIC_DEBUG_ROUTE_MACHINE_SERVICE === 'true'
      ),
      useAuthenticationMachineSubscriber: !!(
        process.env.NEXT_PUBLIC_DEBUG_AUTHENTICATION_MACHINE === 'true'
      ),
      useApplicationMachineSubscriber: !!(
        process.env.NEXT_PUBLIC_DEBUG_APPLICATION_MACHINE === 'true'
      ),
      useBuyerMachineSubscriber: !!(
        process.env.NEXT_PUBLIC_DEBUG_BUYER_MACHINE === 'true'
      ),
    };
  }, []);

  const useSubscriber =
    useRouteMachineServiceSubscriber ||
    useAuthenticationMachineSubscriber ||
    useApplicationMachineSubscriber ||
    useBuyerMachineSubscriber;

  if (!useSubscriber) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-full justify-between m-4">
      <>
        <div className="flex flex-row flex-wrap">
          {useRouteMachineServiceSubscriber && (
            <div>
              <h2>{'Router'}</h2>
              <RouteMachineServiceSubscriber />
            </div>
          )}
          {useAuthenticationMachineSubscriber && (
            <div>
              <h2>{'Auth'}</h2>
              <AuthenticationSubscriber />
            </div>
          )}
          {useBuyerMachineSubscriber && (
            <div>
              <h2>{'Buyer'}</h2>
              <BuyerSubscriber />
            </div>
          )}
          {useApplicationMachineSubscriber && (
            <div>
              <h2>{'Application'}</h2>
              <ApplicationSubscriber />
            </div>
          )}
        </div>
      </>
    </div>
  );
};
