import { LocationResponse } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const getMerchantLocation = async (
  apiFetch: APIFetch,
  merchantID: string,
  locationID: string
): Promise<HandlerResponse<LocationResponse>> => {
  const uri = `/api/organization/merchant/${merchantID}/location/${locationID}`;
  const res: APIResponse<LocationResponse> = await apiFetch<LocationResponse>(
    uri,
    { method: 'GET' },
    LocationResponse
  );

  return handleResponse(res);
};
