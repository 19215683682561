/**
 * Helper method to make concating class names more readable
 * @param classes - truthy or falsy values
 * @returns a string with all truthy classes joined together.
 * @example
 * ```
 * classNames('classA', false && 'classB', true && 'classC')
 * ```
 * returns 'classA classC'
 */
export function classNames(
  ...classes: (false | null | undefined | string)[]
): string {
  return classes.filter(Boolean).join(' ');
}
