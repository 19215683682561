import {
  Application,
  OutgoingApplication,
  PaymentTypes,
  PaymentAgreementDocument,
  Disclosure,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { NeuroIdTokens } from '../../components/FraudAlloyContext';
import { HandlerResponse, handleResponse } from './base';

export const APPLICATION_URI = '/api/application';

type DisclosureConfig = {
  name: string;
  requiresChecksum: boolean;
};

const REQUIRED_DISCLOSURES: Record<PaymentTypes, DisclosureConfig[]> = {
  INSTALLMENTS: [
    { name: 'credit-score-notice', requiresChecksum: false },
    { name: 'loan-agreement', requiresChecksum: true },
    { name: 'tila', requiresChecksum: false },
  ],
  SPLITPAY: [
    { name: 'credit-score-notice', requiresChecksum: false },
    { name: 'loan-agreement-sp', requiresChecksum: true },
    { name: 'checkout-auto-enroll-sp', requiresChecksum: false },
    { name: 'payment-full-terms', requiresChecksum: false },
  ],
};

export async function applicationCheckout(
  {
    application,
    shippingContactID,
    paymentAgreementDocument,
    paymentAgreementID,
    iovationBlackbox,
    isSplitPay,
    paymentMethodID,
    autoPaymentMethodID,
    neuroIdTokens,
  }: {
    application: Application;
    shippingContactID: string;
    paymentAgreementDocument: PaymentAgreementDocument;
    paymentAgreementID: string;
    iovationBlackbox?: string | null;
    isSplitPay?: boolean;
    paymentMethodID?: string;
    autoPaymentMethodID?: string;
    neuroIdTokens?: NeuroIdTokens;
  },
  apiFetch: APIFetch
): Promise<HandlerResponse<Application>> {
  const body = {
    ...application,
    buyerShippingContactID: shippingContactID,
    disclosures: application.disclosures || [],
    paymentMethodID,
    autoPaymentMethodID,
  };

  const clientInfo = [];

  if (iovationBlackbox) {
    clientInfo.push({
      type: 'iovationBlackbox',
      value: iovationBlackbox,
    });
  }

  if (neuroIdTokens) {
    clientInfo.push(
      {
        type: 'neuroUserID',
        value: neuroIdTokens?.neuroUserId,
      },
      {
        type: 'siteID',
        value: neuroIdTokens?.neuroIdSiteId,
      }
    );
  }

  body.clientInfo = clientInfo;

  const paymentProductType = isSplitPay ? 'SPLITPAY' : 'INSTALLMENTS';

  for (const { name, requiresChecksum } of REQUIRED_DISCLOSURES[
    paymentProductType
  ]) {
    const newDisclosure: Disclosure = {
      type: name,
    };

    if (requiresChecksum) {
      newDisclosure.checksum = paymentAgreementDocument.checksum;
    }

    body.disclosures.push(newDisclosure);
  }

  if (!body.disclosures.find(({ type }) => type === 'E_SIGN')) {
    body.disclosures.push({
      type: 'E_SIGN',
      checksum: paymentAgreementDocument.checksum,
    });
  }
  const res: APIResponse<Application> = await apiFetch<
    Application,
    OutgoingApplication
  >(
    `${APPLICATION_URI}/${application?.id}/agreement/${paymentAgreementID}/checkout`,
    { body },
    Application
  );
  return handleResponse(res);
}
