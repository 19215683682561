import { useRouter } from 'next/router';
import { namespaceFromUrl, NamespacePath } from '@ads-bread/shared/bread/util';

/**
 * Returns the namespace of the current URL (RBC, alliance, in-store)
 *
 * @throws Error when namespace cannot be determined from the URL
 *
 */
export function useNamespace(): NamespacePath {
  const router = useRouter();
  const namespace = namespaceFromUrl(router.pathname);

  if (!namespace) {
    if (router.pathname.includes('error')) {
      throw new Error('Unable to resolve namespace');
    } else {
      throw new Error('Current path does not correspond to a known namespace');
    }
  }
  return namespace;
}
