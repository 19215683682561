import React, { AnchorHTMLAttributes } from 'react';

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string; // required for a11y
  testID: string;
}

type ExternalBool = {
  external?: boolean;
  target?: undefined;
};

type ExternalTarget = {
  external?: undefined;
  target?: string;
};

type TextLinkProps = AnchorProps & (ExternalBool | ExternalTarget);

export const TextLink = ({
  children,
  className = 'underline text-theme-primary font-bold hover:text-huckleberry',
  external,
  testID,
  ...props
}: TextLinkProps): JSX.Element => {
  const modifiedProps = { ...props };
  if (external) {
    modifiedProps.target = '_blank';
    modifiedProps.rel = ensureSecureExternalRel(props.rel);
  }
  return (
    <a
      className={className}
      data-testid={`text-link-${testID}`}
      {...modifiedProps}
    >
      {children}
    </a>
  );
};

function ensureSecureExternalRel(rel?: string): string {
  const SECURE_RELS = ['noopener', 'noreferrer'];
  if (!rel) {
    return SECURE_RELS.join(' ');
  }
  let relVal = rel;
  SECURE_RELS.forEach((val) => {
    if (!relVal.includes(val)) {
      relVal += ` ${val}`;
    }
  });
  return relVal;
}
