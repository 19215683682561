import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  AnalyticsEvent,
  initialize,
  setCheckoutDetails,
  track,
} from '../../lib/analytics';
import { useAppConfig } from '../AppConfigContext';
import {
  useOrder,
  useSDKAnalytics,
  useExperienceKeys,
  useSDKOptions,
  useSDKPromoOffers,
  useVirtualCard,
  useMerchantDetails,
  useProgramValues,
  useMerchantProgramValues,
  useMerchantPaymentProducts,
} from '../XPropsContext';
import { FCWithChildren } from '../../lib/types';

export const Analytics: FCWithChildren = () => {
  const config = useAppConfig();
  const { tenantID } = config;
  const { order, isDefaultOrder } = useOrder();
  const analytics = useSDKAnalytics();
  const { programID, merchantID, locationID } = useExperienceKeys();
  const { name: merchantName } = useMerchantDetails();
  const { name: programName } = useProgramValues();
  const { isDownPaymentEnabled } = useMerchantProgramValues();
  const promoOffers = useSDKPromoOffers();
  const { merchantPaymentProducts } = useMerchantPaymentProducts();
  const { route } = useRouter();
  const { isEmbedded, allowCheckout } = useSDKOptions();
  const isCarts = route.startsWith('/cart');
  const isInStore = route.startsWith('/in-store');
  const {
    isVirtualCard,
    isMerchantKeyEntryCapable,
    isMerchantTapToPayCapable,
  } = useVirtualCard();

  useEffect(() => {
    // Initialize analytics and track "modal_opened"
    initialize(config);
    track(AnalyticsEvent.ModalOpened);

    setCheckoutDetails({
      analytics,
      order,
      tenantID,
      programID,
      programName,
      merchantID,
      locationID,
      merchantName,
      promoOffers,
      merchantPaymentProducts,
      allowCheckout,
      isDefaultOrder,
      isEmbedded,
      isCarts,
      isInStore,
      isVirtualCard,
      isMerchantKeyEntryCapable,
      isMerchantTapToPayCapable,
      isDownPaymentEnabled,
    });
  }, []);

  return null;
};
