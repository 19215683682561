import { Money, Order, OrderItem } from '@ads-bread/shared/bread/codecs';

/**
 * Returns true if the 2 strings are both equal. Undefined parameters
 * are treated like as blank strings.
 */
export function apiOptionalStringEqual(s1?: string, s2?: string): boolean {
  return (s1 ?? '') === (s2 ?? '');
}

/**
 * Returns true if the 2 money parameters both have the same currency and amount.
 */
export function moneyEqual(money1?: Money, money2?: Money): boolean {
  return (
    money1?.currency === money2?.currency && money1?.value === money2?.value
  );
}

/**
 * Returns true if the 2 order items are the same item with the same costs
 */
export function itemEqual(item1: OrderItem, item2: OrderItem): boolean {
  return (
    item1.name === item2.name &&
    item1.quantity === item2.quantity &&
    moneyEqual(item1.shippingCost, item2.shippingCost) &&
    moneyEqual(item1.unitPrice, item2.unitPrice) &&
    moneyEqual(item1.unitTax, item2.unitTax) &&
    item1.shippingDescription === item2.shippingDescription &&
    apiOptionalStringEqual(item1.category, item2.category) &&
    apiOptionalStringEqual(item1.itemUrl, item2.itemUrl)
  );
}

/**
 * Returns true if the 2 orders have the same order totals and the same
 * number of equivalent order items.
 */
export function orderEqual(order1: Order, order2: Order): boolean {
  const priceMatch =
    moneyEqual(order1.totalPrice, order2.totalPrice) &&
    moneyEqual(order1.totalDiscounts, order2.totalDiscounts) &&
    moneyEqual(order1.totalShipping, order2.totalShipping) &&
    moneyEqual(order1.totalTax, order2.totalTax) &&
    moneyEqual(order1.subTotal, order2.subTotal);

  const orderMatch =
    order1.items?.length === order2.items?.length &&
    order1.items?.every((prevItem) =>
      order2.items?.some((item) => itemEqual(prevItem, item))
    );

  return !!(priceMatch && orderMatch);
}
