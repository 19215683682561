import {
  FeatureFlagClient,
  FeatureFlagClientConfig,
  FlagResponse,
} from '@getbread/feature-flag-sdk';

const FEATURE_FLAG_TAG = 'CheckoutExperience';

export async function getFeatureFlags(
  apiUrl: string,
  merchantID: string,
  tenantID: string,
  deviceID: string,
  programID?: string
): Promise<Record<string, FlagResponse>> {
  const config: FeatureFlagClientConfig = {
    apiOrigin: apiUrl,
    clientSideId: 'Checkout',
    context: {
      merchantId: merchantID,
      deviceId: deviceID,
      programId: programID,
      tenantId: tenantID,
    },
  };

  // instantiate a client
  const client = new FeatureFlagClient(config);

  // Bulk evaluate with a filter on a tag
  const bulkResultWithTagFilter = await client.bulkEvaluate({
    tag: FEATURE_FLAG_TAG,
  });

  return bulkResultWithTagFilter;
}
