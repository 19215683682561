import React from 'react';
import { Dialog } from '@headlessui/react';
import { noOp } from '../lib/noOp';
import { FCWithChildren } from '../lib/types';

interface PopoverProps {
  classNames?: string;
  width?: number;
}

export const Popover: FCWithChildren<PopoverProps> & {
  Title: FCWithChildren;
} = ({ children, classNames, width = 72 }) => (
  <Dialog open={true} onClose={noOp} static={true} className="z-50 relative">
    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

    <div className="fixed inset-0 flex items-center justify-center p-4">
      <Dialog.Panel
        className={`flex flex-col mx-auto max-w-sm bg-white p-8 w-${width} rounded justify-center items-center text-center ${classNames}`}
      >
        {children}
      </Dialog.Panel>
    </div>
  </Dialog>
);

const Title: FCWithChildren = ({ children }) => (
  <Dialog.Title className="font-bold text-xl mb-4 leading-9" tabIndex={0}>
    {children}
  </Dialog.Title>
);

Popover.Title = Title;
