/**
 * Copied from Member Portal, should be factored out into a shared place eventually.
 * https://github.com/getbread/member_portal_frontend/blob/master/src/utils/useMounted.js
 */
import { useEffect, useRef, MutableRefObject } from 'react';

/**
 * Tracks whether a component is mounted, so you can avoid
 * "setState on unmounted component" warnings
 */
export function useMounted(): MutableRefObject<boolean> {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
