import * as io from 'io-ts';
var Employment = io.type({
  annualIncome: io.union([io.number, io["null"]]),
  companyAddress: io.string,
  companyName: io.string,
  currency: io.string,
  occupation: io.string,
  occupationDescription: io.string,
  type: io.string
});
export var Name = io.intersection([io.type({
  familyName: io.string,
  givenName: io.string
}), io.partial({
  additionalName: io.string
})]);
var Identity = io.intersection([io.type({
  email: io.string,
  name: Name,
  phone: io.string
}), io.partial({
  birthDate: io.string,
  emailDuplicate: io["boolean"],
  emailVerified: io["boolean"],
  phoneDuplicate: io["boolean"],
  phoneVerified: io["boolean"],
  iinShort: io.string,
  iin: io.string,
  hasIIN: io["boolean"]
})]);
export var Address = io.type({
  address1: io.string,
  address2: io.string,
  country: io.string,
  locality: io.string,
  postalCode: io.string,
  region: io.string
});
export var ContactOutgoing = io.type({
  address: Address,
  email: io.string,
  name: Name,
  phone: io.string
});
export var Contact = io.intersection([ContactOutgoing, io.type({
  id: io.string,
  buyerID: io.string,
  createdAt: io.string,
  updatedAt: io.string
})]);
var BuyerOptionalOutgoing = io.type({
  contacts: io.record(io.string, Contact),
  createdAt: io.string,
  employment: Employment,
  id: io.string,
  primaryContactID: io.string,
  doNotSellOrShare: io["boolean"],
  updatedAt: io.string
});
var BuyerRequiredOutgoing = io.type({
  identity: Identity,
  languagePreference: io.string
});
export var Buyer = io.intersection([BuyerOptionalOutgoing, BuyerRequiredOutgoing]);
export var OutgoingBuyer = io.intersection([BuyerRequiredOutgoing, io.partial(BuyerOptionalOutgoing.props)]);
export var MaskedBuyerPhoneAndEmail = io.type({
  email: io.string,
  phone: io.string
});