export var StorageKeys;

(function (StorageKeys) {
  StorageKeys["AUTH_TOKEN"] = "auth-token";
  StorageKeys["LOCALE_KEY"] = "lang";
  StorageKeys["REVIEWED_APPLICATION"] = "reviewed-application";
  StorageKeys["DEVICE_ID"] = "device-id";
})(StorageKeys || (StorageKeys = {}));

export function getItemFromStorage(key) {
  try {
    var jsonString = localStorage.getItem(key);

    if (!jsonString) {
      return null;
    }

    return JSON.parse(jsonString);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Error calling getItem on localStorage.', e instanceof ReferenceError ? e.message : e);
    return null;
  }
}
export var removeItemFromStorage = function removeItemFromStorage(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Error calling removeItem on localStorage.', e instanceof ReferenceError ? e.message : e);
  }
};
export var setItemInStorage = function setItemInStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Error calling setItem on localStorage.', e instanceof ReferenceError ? e.message : e);
  }
};