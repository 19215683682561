export type MobileOperatingSystems = 'iOS' | 'Android' | 'Unknown';

declare const window: Window & {
  opera: string;
};

/**
 * Detect mobile operating system
 * @returns MobileOperatingSystems
 */
export const getMobileOS = (): MobileOperatingSystems => {
  if (typeof window === 'undefined') {
    return 'Unknown';
  }
  const userAgent = window.navigator.userAgent || window.opera;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (
    /(iPhone|iPad)/i.test(userAgent) ||
    (userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'iOS';
  }

  return 'Unknown';
};

const TAP_TO_PAY_COMPATIBLE_SYSTEMS = ['Android', 'iOS'];

export const mobileOS = getMobileOS();
export const isTapToPayCompatibleDevice =
  TAP_TO_PAY_COMPATIBLE_SYSTEMS.includes(mobileOS);
