import {
  MerchantPaymentProduct,
  Money,
  Offer,
  PaymentType,
} from '@ads-bread/shared/bread/codecs';
import { toMoneyValue } from '@ads-bread/shared/bread/util';

/**
 * Get the max capacity available by taking the max of all capacities
 * @param offersByType
 * @returns GetMaxCapacities
 */
export const getMaxCapacities = (
  offersByType: Record<PaymentType, Offer[]>,
  merchantPaymentProducts?: MerchantPaymentProduct[]
): Record<PaymentType, Money> => {
  const installmentCapacities = (offersByType?.INSTALLMENTS || []).map(
    (offer) => {
      return offer.capacity;
    }
  );

  const splitPayCapacities = (offersByType?.SPLITPAY || []).map((offer) => {
    return offer.capacity;
  });

  const installmentsMerchantPaymentProductMaxCartSizes = (
    merchantPaymentProducts || []
  )
    .filter((mp) => mp.paymentProduct.type === 'INSTALLMENTS')
    .map((i) => i.merchantValues.maxCartSize.value);

  const splitPayMerchantPaymentProductMaxCartSizes = (
    merchantPaymentProducts || []
  )
    .filter((mp) => mp.paymentProduct.type === 'SPLITPAY')
    .map((i) => i.merchantValues.maxCartSize.value);

  const installmentsMerchantPaymentProductMaxCartSize = Math.max(
    ...installmentsMerchantPaymentProductMaxCartSizes
  );

  const splitPayMerchantPaymentProductMaxCartSize = Math.max(
    ...splitPayMerchantPaymentProductMaxCartSizes
  );

  const installmentsMaxCapacity: Money = {
    currency: installmentCapacities?.[0]?.currency || 'usd',
    value: toMoneyValue(
      min(
        Math.max(...installmentCapacities.map((c) => c.value)),
        installmentsMerchantPaymentProductMaxCartSize
      )
    ),
  };

  const splitPayMaxCapacity: Money = {
    currency: splitPayCapacities?.[0]?.currency || 'usd',
    value: toMoneyValue(
      min(
        Math.max(...splitPayCapacities.map((c) => c.value)),
        splitPayMerchantPaymentProductMaxCartSize
      )
    ),
  };

  return {
    INSTALLMENTS: installmentsMaxCapacity,
    SPLITPAY: splitPayMaxCapacity,
  };
};

const onlyNumbers = (value?: number): value is number =>
  typeof value === 'number';

const nonNegativeNumbers = (value: number) => value >= 0;

function min(a?: number, b?: number) {
  return Math.min(...[a, b].filter(onlyNumbers).filter(nonNegativeNumbers));
}
