/* eslint-disable no-console */
/* justification: intentional browser console output for logging module */

import type { LoggerClient } from './client';

type LogLevel = 'error' | 'warn' | 'info' | 'debug';

const createLogDispatcher =
  (level: LogLevel, isConsoleActive: boolean) =>
  (msg: string, ctx: unknown): void => {
    const localSetting = window?.localStorage.getItem('useConsoleLogging');
    const useConsoleLogging = localSetting === 'true';

    if (isConsoleActive || useConsoleLogging) {
      console[level](msg, ctx);
    }
  };

export const createConsoleLogger = (
  isConsoleActive: boolean
): LoggerClient => ({
  error: createLogDispatcher('error', isConsoleActive),
  warn: createLogDispatcher('warn', isConsoleActive),
  info: createLogDispatcher('info', isConsoleActive),
  debug: createLogDispatcher('debug', isConsoleActive),
});
