import {
  Buyer,
  isAPIError,
  OutgoingBuyer,
} from '@ads-bread/shared/bread/codecs';
import { logger } from '../logger';
import { APIFetch, APIResponse, UnauthorizedError } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const CREATE_BUYER_URI = '/api/buyer';

export async function createBuyer(
  buyer: OutgoingBuyer,
  apiFetch: APIFetch
): Promise<HandlerResponse<Buyer>> {
  try {
    const res: APIResponse<Buyer> = await apiFetch<Buyer, OutgoingBuyer>(
      CREATE_BUYER_URI,
      { body: buyer },
      Buyer
    );
    return handleResponse(res);
  } catch (err) {
    if (err instanceof UnauthorizedError) {
      const responseBody = err.data.body;
      if (isAPIError(responseBody)) {
        return handleResponse({
          response: null,
          error: responseBody,
        });
      }
    }
    logger.error({ err }, 'Unexpected error in create-buyer');
    throw err;
  }
}
