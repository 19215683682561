import {
  EVT_TYPE_STORAGE_CHANGED,
  startMockAPIServerIfConfigured,
} from '@ads-bread/shared/bread/mirage';
import { useEffect } from 'react';
import { FCWithChildren } from '../lib/types';
import { Loader } from './LoadingManager';

declare const window: Window & {
  Cypress: any;
  handleFromCypress: (req: any) => Promise<any[]>;
};

type HttpMethod = 'get' | 'put' | 'patch' | 'post' | 'delete';
const HTTP_METHODS: HttpMethod[] = ['get', 'put', 'patch', 'post', 'delete'];

const loadMirageForCypress = async () => {
  // Ensure Cypress exists and that we are running in a Cypress env
  // where Mirage handlers are in place on the parent window.
  if (
    typeof window !== 'undefined' &&
    window.Cypress &&
    window.handleFromCypress
  ) {
    const { createServer, Response } = await import('miragejs');
    const otherDomains = [
      'http://localhost:3000',
      'https://api.npp-dev-ads.ue2.breadgateway.net/',
      'https://api.npp-qa-ads.ue2.breadgateway.net/',
    ];

    createServer({
      routes() {
        for (const domain of ['/', ...otherDomains]) {
          for (const method of HTTP_METHODS) {
            this[method](`${domain}*`, async (_: any, request: any) => {
              const [status, headers, body] = await window.handleFromCypress(
                request
              );
              return new Response(status, headers, body);
            });
          }
        }
      },
    });
  }
};

export const MirageLoader: FCWithChildren<{
  removeLoader: (loader: Loader) => void;
}> = ({ children, removeLoader }) => {
  useEffect(() => {
    addAppEventListeners();
    const maybeUseMirage = async () => {
      await loadMirageForCypress();
      await startMockAPIServerIfConfigured();
      removeLoader('MIRAGE');
    };
    maybeUseMirage();

    return () => {
      removeAppEventListeners();
    };
  }, []);
  return <>{children}</>;
};

function addAppEventListeners(): void {
  if (typeof window === 'undefined') {
    return;
  }
  window.addEventListener(
    EVT_TYPE_STORAGE_CHANGED,
    startMockAPIServerIfConfigured
  );
}

function removeAppEventListeners(): void {
  if (typeof window === 'undefined') {
    return;
  }
  window.removeEventListener(
    EVT_TYPE_STORAGE_CHANGED,
    startMockAPIServerIfConfigured
  );
}
