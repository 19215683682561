import React, { FunctionComponent } from 'react';
import Head from 'next/head';
import { hex } from 'wcag-contrast';
import { logger } from '../lib/logger';
import { useMerchantConfig } from './MerchantContext';
import { useAppConfig } from './AppConfigContext';

const HexColorPattern =
  /^#(?<r>(([a-zA-Z]|\d){2}))(?<b>(([a-zA-Z]|\d){2}))(?<g>(([a-zA-Z]|\d){2}))$/;
const HexColorPatternShort = /^(#)([a-fA-F\d])([a-fA-F\d])([a-fA-F\d])$/;

function parse16(val: string): number {
  return parseInt(val, 16);
}

export function hexColorToRGB(hexStr: string): string {
  // Expand shorthand
  hexStr = hexStr.replace(HexColorPatternShort, (_, p, r, g, b) => {
    return p + r + r + g + g + b + b;
  });

  const result = HexColorPattern.exec(hexStr);
  const r = result?.groups?.['r'];
  const b = result?.groups?.['b'];
  const g = result?.groups?.['g'];
  if (!r || !g || !b) {
    throw new Error(`could not parse hex color: ${hexStr}`);
  }
  return `${parse16(r)}, ${parse16(b)}, ${parse16(g)}`;
}

export function rgbWithOpacityRule(
  rgbColor: string,
  opacityVar: string
): string {
  return `rgba(${rgbColor}, var(${opacityVar}))`;
}

function colorUtilityWithOpacityVar(
  rgbColor: string,
  property: string,
  opacityVar: string
) {
  return `
    ${opacityVar}: 1;
    ${property}: ${rgbWithOpacityRule(rgbColor, opacityVar)} !important;
  `;
}

const WHITE = '255, 255, 255';
const BLACKBERRY = '15, 34, 51';
const GRAY = '147, 147, 147';

interface ThemeStylesProps {
  children?: (options: { styles: string }) => JSX.Element;
}

export const ThemeStyles: FunctionComponent<ThemeStylesProps> = ({
  children,
}) => {
  const { checkoutPrimaryColor } = useMerchantConfig();
  const {
    themeStyles: {
      dangerColor,
      warningColor,
      warningColorSecondary,
      fontFamily,
    },
  } = useAppConfig();
  let styles;
  try {
    if (!checkoutPrimaryColor) {
      throw new Error('checkoutPrimaryColor is undefined');
    }

    const checkoutPrimaryRGB = hexColorToRGB(checkoutPrimaryColor);
    const checkoutDangerRGB = hexColorToRGB(dangerColor);
    const checkoutWarningRGB = hexColorToRGB(warningColor);
    const checkoutWarningSecondaryRGB = hexColorToRGB(warningColorSecondary);

    const contrastToWhite = hex(checkoutPrimaryColor, '#ffffff');
    const buttonTextPrimaryRGB = contrastToWhite >= 4.5 ? WHITE : BLACKBERRY;
    const buttonBorderColorRGB =
      contrastToWhite >= 4.5 ? checkoutPrimaryRGB : GRAY;

    styles = `
  :root {
    --font-family-sans: ${fontFamily};
  }

  .bg-theme-primary, .before\\:bg-theme-primary::before {
    ${colorUtilityWithOpacityVar(
      checkoutPrimaryRGB,
      'background-color',
      '--tw-bg-opacity'
    )}
  }

  .text-theme-primary {
    ${colorUtilityWithOpacityVar(
      checkoutPrimaryRGB,
      'color',
      '--tw-text-opacity'
    )}
  }

  .button-text-theme-primary, .before\\:button-text-theme-primary::before {
    ${colorUtilityWithOpacityVar(
      buttonTextPrimaryRGB,
      'color',
      '--tw-text-opacity'
    )}
  }

  .button-border-theme-primary {
    ${colorUtilityWithOpacityVar(
      buttonBorderColorRGB,
      'border-color',
      '--tw-border-opacity'
    )}
  }

  .tabs-border-theme-primary {
    ${
      contrastToWhite > 4.5
        ? 'border-color: transparent;'
        : colorUtilityWithOpacityVar(
            buttonBorderColorRGB,
            'border-color',
            '--tw-border-opacity'
          )
    }
  }

  .border-theme-primary {
    ${colorUtilityWithOpacityVar(
      checkoutPrimaryRGB,
      'border-color',
      '--tw-border-opacity'
    )}
  }

  .fill-theme-primary {
    ${colorUtilityWithOpacityVar(checkoutPrimaryRGB, 'fill', '--tw-bg-opacity')}
  }

  .border-theme-danger {
    ${colorUtilityWithOpacityVar(
      checkoutDangerRGB,
      'border-color',
      '--tw-border-opacity'
    )}
  }

  .text-theme-danger {
    ${colorUtilityWithOpacityVar(
      checkoutDangerRGB,
      'color',
      '--tw-text-opacity'
    )}
  }

  .bg-theme-danger {
   ${colorUtilityWithOpacityVar(
     checkoutDangerRGB,
     'background-color',
     '--tw-bg-opacity'
   )}
  }

  .border-theme-warning {
    ${colorUtilityWithOpacityVar(
      checkoutWarningRGB,
      'border-color',
      '--tw-border-opacity'
    )}
  }

  .text-theme-warning {
    ${colorUtilityWithOpacityVar(
      checkoutWarningRGB,
      'color',
      '--tw-text-opacity'
    )}
  }

  .bg-theme-warning {
   ${colorUtilityWithOpacityVar(
     checkoutWarningRGB,
     'background-color',
     '--tw-bg-opacity'
   )}
  }

  .border-theme-warning-secondary {
    ${colorUtilityWithOpacityVar(
      checkoutWarningSecondaryRGB,
      'border-color',
      '--tw-border-opacity'
    )}
  }

  .text-theme-warning-secondary {
    ${colorUtilityWithOpacityVar(
      checkoutWarningSecondaryRGB,
      'color',
      '--tw-text-opacity'
    )}
  }

  .bg-theme-warning-secondary {
   ${colorUtilityWithOpacityVar(
     checkoutWarningSecondaryRGB,
     'background-color',
     '--tw-bg-opacity'
   )}
  }
`;
  } catch (e) {
    const err = e instanceof Error ? e : { message: e };
    logger.error({ err }, 'error assigning themes styles');
  }
  if (!styles) {
    return null;
  }
  return typeof children === 'function' ? (
    children({ styles })
  ) : (
    <Head>
      <style
        dangerouslySetInnerHTML={{ __html: styles }}
        data-testid="theme-styles"
      />
    </Head>
  );
};
