import { FunctionComponent } from 'react';
import Script from 'next/script';
import { useAppConfig } from '../AppConfigContext';

/**
 * Adds Adobe TMS scripts to head. Includes the Adobe data layer implementation.
 * @returns JSX.Element
 */
export const AdobeTMSScript: FunctionComponent = () => {
  const { environment } = useAppConfig();

  if (environment === 'production') {
    return (
      <Script
        src="https://assets.adobedtm.com/22a5c2057804/c0222dc19206/launch-3e06b1cbd57c.min.js"
        async
      />
    );
  }

  return (
    <Script
      src="https://assets.adobedtm.com/22a5c2057804/c0222dc19206/launch-15916450c691-development.min.js"
      async
    />
  );
};
