import {
  Application,
  ApplicationsResponse,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { handleResponse } from './base';

export const GET_APPLICATIONS_PATH =
  '/api/application?limit=1&showExpired.eq=false';

export async function getLatestApplication(
  apiFetch: APIFetch
): Promise<Application | null> {
  const res: APIResponse<ApplicationsResponse> =
    await apiFetch<ApplicationsResponse>(
      `${GET_APPLICATIONS_PATH}`,
      { method: 'GET' },
      ApplicationsResponse
    );
  const response = handleResponse(res);

  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }

  return response.result?.[0] ?? null;
}
