import * as io from 'io-ts';
var Disclosure = io.intersection([io.type({
  type: io.string
}), io.partial({
  acceptedAt: io.string,
  checksum: io.string
})]);
export var DisclosuresAttribute = io.union([io.array(Disclosure), io["null"]]);
export var HasDisclosures = io.type({
  disclosures: DisclosuresAttribute
});