export var ADSMerchantConfig = {
  logoUrl: '/bread.svg',
  checkoutPrimaryColor: '#1C8195',
  checkoutSecondaryColor: '#5156ea',
  disclosureSymbolInstallments: '*',
  disclosureSymbolSplitpay: '**',
  disclosureSymbolSplitpayInterest: '§',
  marketingDescription: '<b>Earn 5x Rewards</b> when using your Sony Visa Credit Card on Sony purchases.',
  marketingImageUrl: '/sony.svg',
  marketingUrl: 'https://www.breadpayments.com',
  // TODO: Figure out the correct learn more url
  merchantTier: 1,
  paymentMethodDisplay: {
    en: 'Pay Over Time',
    fr: 'Pay Over Time'
  },
  noOrderPromoTextInstallments: 'Low monthly payments',
  buttonConfig: {
    backgroundColor: '#FFFFFF',
    borderColor: '#0F2233',
    borderWidth: 2,
    includeLogo: true,
    logoColor: '#0F2233',
    textColor: '#0F2233',
    width: 343
  }
};
export var RBCMerchantConfig = {
  logoUrl: '/pay_plan_subsidiary_en.svg',
  checkoutPrimaryColor: '#006AC3',
  checkoutSecondaryColor: '#d900ff',
  disclosureSymbolInstallments: '*',
  disclosureSymbolSplitpay: '**',
  disclosureSymbolSplitpayInterest: '***',
  marketingDescription: '',
  marketingImageUrl: '',
  marketingUrl: '',
  merchantTier: 1,
  paymentMethodDisplay: {
    en: 'PayPlan by RBC',
    fr: 'finanC par RBC'
  },
  noOrderPromoTextInstallments: 'Pay over time',
  buttonConfig: {
    backgroundColor: 'transparent',
    borderColor: '#0F2233',
    borderWidth: 0,
    includeLogo: true,
    logoColor: '#000000',
    logoPosition: 'right',
    textColor: '#000000',
    width: 410,
    additionalText: {
      en: 'Prequalify now',
      fr: 'Établissez votre préadmissibilité'
    },
    additionalTextFontStyle: 'italic',
    additionalTextDecoration: 'underline'
  }
};