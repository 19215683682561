import { ExperienceKeys } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const GET_EXPERIENCE_KEYS_URI = '/api/experience-keys';

export async function getExperienceKeys(
  apiFetch: APIFetch,
  locationShortCode: string
): Promise<HandlerResponse<ExperienceKeys>> {
  const uri = `${GET_EXPERIENCE_KEYS_URI}/location-short-code/${locationShortCode}`;
  const res: APIResponse<ExperienceKeys> = await apiFetch<ExperienceKeys>(
    uri,
    { method: 'GET' },
    ExperienceKeys
  );
  return handleResponse(res);
}
