// Default props for dev/testing not in an inframe
import { PropsType, ZoidContext } from 'zoid/dist/zoid.frameworks';
import {
  ADSMerchantConfig,
  ADSProgram,
  merchantProgramValuesProperties,
  merchantDetailsProperties,
} from '@ads-bread/shared/bread/mirage';
import {
  createInstallmentsPaymentProduct,
  createSplitPayPaymentProduct,
  CheckoutXPropOptions,
  usd,
  toMoneyValue,
} from '@ads-bread/shared/bread/util';

type CheckoutProps = {
  close: () => void;
  hide: () => void;
  context: ZoidContext;
  options: CheckoutXPropOptions;
  uid: string;
  isDefaultXProps?: boolean;
};

export type CheckoutXProps = PropsType<CheckoutProps>;
export const defaultXProps: CheckoutXProps = {
  context: 'iframe',
  close: () => Promise.resolve(undefined),
  hide: () => undefined,
  options: {
    merchantOrigin: '',
    domID: 'placement-1',
    appID: '6ca8fd30-c8c1-426b-85d6-ddf136399732',
    merchantID: '0020fa5a-f107-4ba7-b7f0-d869ea0eed07',
    programID: 'b7beac2e-2714-4d1d-b110-72e405b41c34',
    locationID: '00000000-0000-0000-0000-000000000000',
    deviceID: '00000000-0000-0000-0000-000000000000',
    allowCheckout: true,
    name: undefined,
    billingAddress: undefined,
    shippingAddress: undefined,
    email: undefined,
    phone: undefined,
    onApproved: (application) => {
      global.alert(`Application approved: ${application.id}`);
      return Promise.resolve();
    },
    onOneTrustConsent: (data) => {
      global.alert(`OneTrust Consent: ${data}`);
      return data;
    },
    onCheckout: (application) => {
      global.alert(
        `Completed checkout! Response status: ${application.status}`
      );
      return Promise.resolve();
    },
    order: {
      items: [
        {
          name: 'Cannondale x1200 bike',
          quantity: 1,
          shippingCost: usd(1000),
          shippingDescription: 'Standard',
          unitTax: usd(1000),
          unitPrice: usd(129900),
        },
      ],
      subTotal: usd(129900),
      totalPrice: usd(131900),
      totalDiscounts: usd(0),
      totalShipping: usd(1000),
      totalTax: usd(1000),
    },
    modal: false,
    isDefaultOrder: false,
    isEmbedded: false,
    isPreview: false,
    isLocationEnabled: true,
    promoOffers: [
      {
        frequency: { length: 1, designator: 'M' },
        numberOfPayments: 12,
        paymentAmount: toMoneyValue(11906),
        rate: 999,
        type: 'INSTALLMENTS',
      },
    ],
    merchantPaymentProducts: [
      createInstallmentsPaymentProduct(),
      createSplitPayPaymentProduct(),
    ],
    merchantProgramValues: merchantProgramValuesProperties,
    merchantConfig: ADSMerchantConfig,
    program: ADSProgram,
    merchantDetails: merchantDetailsProperties,
    analytics: {
      experienceEntrypoint: '',
      locationType: 'PDP',
    },
    displayInterestRateBPS: 999,
  },
  onProps: () => undefined,
  uid: '',
  isDefaultXProps: true,
};
