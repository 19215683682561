import { createMachine } from 'xstate';
import {
  Buyer,
  Address,
  Name,
  Contact,
  ContactOutgoing,
  OutgoingBuyer,
  MaskedBuyerPhoneAndEmail,
  Cart,
} from '@ads-bread/shared/bread/codecs';
import { HandlerResponse } from '../../lib/handlers/base';

export interface BuyerMachineContext {
  buyer: Buyer | null;
  buyerID: string | null;
  buyerName: Name | null;
  email: string | null;
  phone: string | null;
  iin: string | null;
  dob: string | null;
  shippingAddress: Address | null;
  billingAddress: Address | null;
}

/**
 * Events - event send type definitions
 */
export type SendCreateBuyer = {
  type: 'SEND_CREATE_BUYER';
  buyer: OutgoingBuyer;
  contact?: ContactOutgoing;
  resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  reject: () => void;
};

export type SendUpdateBuyer = {
  type: 'SEND_UPDATE_BUYER';
  buyer: Buyer;
  contact?: ContactOutgoing;
  resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  reject: () => void;
};

export type SendFetchBuyer = {
  type: 'SEND_FETCH_BUYER';
  resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  reject: () => void;
};

export type SendFetchingPartialBuyer = {
  type: 'SEND_FETCHING_PARTIAL_BUYER';
  resolve: (buyerRes: HandlerResponse<MaskedBuyerPhoneAndEmail>) => void;
  reject: () => void;
};

export type SendBuyerReady = {
  type: 'SEND_BUYER_READY';
};

export type SendResetBuyerState = {
  type: 'SEND_RESET_BUYER_STATE';
};

export type SendUpdateBuyerName = {
  type: 'SEND_UPDATE_BUYER_NAME';
  buyerName: Name;
};

export type SendUpdateBuyerEmail = {
  type: 'SEND_UPDATE_BUYER_EMAIL';
  email: string;
};

export type SendUpdateBuyerPhone = {
  type: 'SEND_UPDATE_BUYER_PHONE';
  phone: string;
};

export type SendUpdateBuyerIIN = {
  type: 'SEND_UPDATE_BUYER_IIN';
  iin: string;
};

export type SendUpdateBuyerDOB = {
  type: 'SEND_UPDATE_BUYER_DOB';
  dob: string;
};

export type SendUpdateBuyerShippingAddress = {
  type: 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS';
  shippingAddress: Address;
};

export type SendUpdateBuyerBillingAddress = {
  type: 'SEND_UPDATE_BUYER_BILLING_ADDRESS';
  billingAddress: Address;
};

export type BuyerEvents =
  | SendCreateBuyer
  | SendUpdateBuyer
  | SendFetchBuyer
  | SendFetchingPartialBuyer
  | SendBuyerReady
  | SendResetBuyerState
  | SendUpdateBuyerName
  | SendUpdateBuyerEmail
  | SendUpdateBuyerPhone
  | SendUpdateBuyerIIN
  | SendUpdateBuyerDOB
  | SendUpdateBuyerShippingAddress
  | SendUpdateBuyerBillingAddress;

/**
 * Services - service type definitions
 */
export type CreateBuyer = {
  data: {
    buyerRes: HandlerResponse<Buyer>;
    resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  };
};

export type UpdateBuyer = {
  data: {
    buyerRes: HandlerResponse<Buyer>;
    contactRes?: HandlerResponse<Contact>;
    resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  };
};

export type FetchBuyer = {
  data: {
    buyerRes: HandlerResponse<Buyer>;
    resolve: (buyerRes: HandlerResponse<Buyer>) => void;
  };
};

export type FetchPartialBuyer = {
  data: {
    buyerRes: HandlerResponse<MaskedBuyerPhoneAndEmail>;
    resolve: (buyerRes: HandlerResponse<MaskedBuyerPhoneAndEmail>) => void;
  };
};

export type PrepareCartBuyer = {
  data: {
    cartRes: HandlerResponse<Cart>;
  };
};

export type GetErrorReasonFromResponse = {
  data: {
    reason: string;
  };
};

export type BuyerServiceErrorData = {
  error: unknown;
  reject: (error: unknown) => void;
};

export type BuyerService = {
  createBuyer: CreateBuyer;
  updateBuyer: UpdateBuyer;
  fetchBuyer: FetchBuyer;
  fetchPartialBuyer: FetchPartialBuyer;
  prepareCartBuyer: PrepareCartBuyer;
  getErrorReasonFromResponse: GetErrorReasonFromResponse;
};

/**
 * Schema definition
 */
export interface BuyerMachineSchema {
  context: BuyerMachineContext;
  events: BuyerEvents;
  services: BuyerService;
}

export const buyerMachine = createMachine({
  id: 'buyer',
  predictableActionArguments: true,
  initial: 'initial',
  context: {
    buyer: null,
    buyerID: null,
    buyerName: null,
    email: null,
    phone: null,
    iin: null,
    dob: null,
    shippingAddress: null,
    billingAddress: null,
  },
  tsTypes: {} as import('./buyerMachine.typegen').Typegen0,
  schema: {
    context: {} as BuyerMachineContext,
    events: {} as BuyerEvents,
    services: {} as BuyerService,
  },
  on: {
    SEND_UPDATE_BUYER_NAME: {
      actions: 'assignBuyerName',
    },
    SEND_UPDATE_BUYER_EMAIL: {
      actions: ['assignBuyerEmail', 'handleBuyerEmailAnalytics'],
    },
    SEND_UPDATE_BUYER_PHONE: {
      actions: ['assignBuyerPhone', 'handleBuyerPhoneAnalytics'],
    },
    SEND_UPDATE_BUYER_IIN: {
      actions: 'assignBuyerIIN',
    },
    SEND_UPDATE_BUYER_DOB: {
      actions: 'assignBuyerDOB',
    },
    SEND_UPDATE_BUYER_SHIPPING_ADDRESS: {
      actions: 'assignBuyerShippingAddress',
    },
    SEND_UPDATE_BUYER_BILLING_ADDRESS: {
      actions: 'assignBuyerBillingAddress',
    },
    SEND_RESET_BUYER_STATE: {
      target: 'initial',
      actions: 'resetContext',
    },
  },
  states: {
    initial: {
      on: {
        SEND_FETCH_BUYER: {
          target: 'fetchingBuyer',
        },
        SEND_FETCHING_PARTIAL_BUYER: {
          target: 'fetchingPartialBuyer',
        },
        SEND_BUYER_READY: [
          {
            cond: 'isCartBuyer',
            target: 'preparingCartBuyer',
          },
          {
            target: 'ready',
          },
        ],
      },
    },
    ready: {
      on: {
        SEND_UPDATE_BUYER: {
          target: 'updatingBuyer',
        },
        SEND_CREATE_BUYER: {
          target: 'creatingBuyer',
        },
        SEND_FETCH_BUYER: {
          target: 'fetchingBuyer',
        },
      },
      initial: 'initial',
      states: {
        initial: {},
        complete: {
          initial: 'initial',
          states: {
            initial: {},
            contactUpdated: {},
          },
        },
        incomplete: {},
        completeIdentity: {},
        exchanging: {},
        cartRetrieved: {},
      },
    },
    creatingBuyer: {
      invoke: {
        src: 'createBuyer',
        onDone: [
          {
            cond: 'isBuyerConflictErrorResponse',
            target: 'ready.initial',
          },
          {
            cond: 'isErrorResponse',
            actions: ['handleBuyerDone'],
            target: 'evaluateBuyerError',
          },
          {
            cond: 'isComplete',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.complete',
          },
          {
            cond: 'hasCompleteIdentity',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.completeIdentity',
          },
          {
            cond: 'hasBuyerResult',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.incomplete',
          },
          {
            actions: ['handleBuyerDone'],
            target: 'ready.initial',
          },
        ],
        onError: [
          {
            actions: ['handleBuyerServiceError', 'logError'],
            target: 'ready.initial',
          },
        ],
      },
    },
    fetchingBuyer: {
      invoke: {
        src: 'fetchBuyer',
        onDone: [
          {
            cond: 'isErrorResponse',
            target: 'evaluateBuyerError',
          },
          {
            cond: 'isComplete',
            actions: ['assignBuyer', 'identifyBuyer'],
            target: 'ready.complete',
          },
          {
            cond: 'hasCompleteIdentity',
            actions: ['assignBuyer', 'identifyBuyer'],
            target: 'ready.completeIdentity',
          },
          {
            cond: 'isValidDecodedBuyer',
            actions: ['assignBuyer', 'identifyBuyer'],
            target: 'ready.incomplete',
          },
          {
            target: 'initial',
            actions: ['logout'],
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'initial',
          },
        ],
      },
    },
    fetchingPartialBuyer: {
      invoke: {
        src: 'fetchPartialBuyer',
        onDone: [
          {
            cond: 'isPartialBuyerSuccessResponse',
            actions: 'assignPartialBuyer',
            target: 'ready.exchanging',
          },
          {
            target: 'ready.initial',
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'ready.initial',
          },
        ],
      },
    },
    updatingBuyer: {
      invoke: {
        src: 'updateBuyer',
        onDone: [
          {
            cond: 'isBuyerConflictErrorResponse',
            actions: ['handleBuyerDone'],
            target: 'ready.initial',
          },
          {
            cond: 'isErrorResponse',
            actions: ['handleBuyerDone'],
            target: 'evaluateBuyerError',
          },
          {
            cond: 'isCompleteAndContactUpdated',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.complete.contactUpdated',
          },
          {
            cond: 'isComplete',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.complete',
          },
          {
            cond: 'hasCompleteIdentity',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.completeIdentity',
          },
          {
            cond: 'hasBuyerResult',
            actions: ['assignBuyer', 'identifyBuyer', 'handleBuyerDone'],
            target: 'ready.incomplete',
          },
          {
            actions: ['handleBuyerDone'],
            target: 'ready.initial',
          },
        ],
        onError: [
          {
            actions: ['handleBuyerServiceError', 'logError'],
            target: 'ready.initial',
          },
        ],
      },
    },
    preparingCartBuyer: {
      invoke: {
        src: 'prepareCartBuyer',
        onDone: [
          {
            cond: 'isCartComplete',
            actions: ['assignCartBuyer'],
            target: 'ready.cartRetrieved',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    evaluateBuyerError: {
      invoke: {
        src: 'getErrorReasonFromResponse',
        onDone: [
          {
            cond: 'isVerifiedEmailError',
            target: 'errors.verifiedEmail',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            target: 'errors.unknown',
          },
        ],
      },
    },
    errors: {
      initial: 'unknown',
      states: {
        verifiedEmail: {},
        unknown: {},
      },
    },
  },
});
