import { FunctionComponent, createContext, useContext, useState } from 'react';

export type Loader =
  | 'X_PROPS'
  | 'APP_CONFIG'
  | 'ROUTE_SERVICE'
  | 'MIRAGE'
  | 'FEATURE_FLAGS';

type LoadingManagerValues = {
  loading: boolean;
  removeLoader: (loader: Loader) => void;
  addLoader: (loader: Loader) => void;
  isLoaded: (loader: Loader) => boolean;
};
interface LoadingManagerProps {
  children: (options: LoadingManagerValues) => JSX.Element;
  addLoader?: (loader: Loader) => void;
  removeLoader?: (loader: Loader) => void;
}

export const LoadingManagerContext = createContext<LoadingManagerValues>({
  loading: true,
  removeLoader: () => {
    // noOp
  },
  addLoader: () => {
    // noOp
  },
  isLoaded: () => false,
});

export const LoadingManager: FunctionComponent<LoadingManagerProps> = ({
  children,
}) => {
  const initialLoadersSet = new Set([
    'MIRAGE',
    'X_PROPS',
    'ROUTE_SERVICE',
    'FEATURE_FLAGS',
  ]);
  const [loaders, setLoaders] = useState(initialLoadersSet);
  const loading = !!loaders.size;

  const removeLoader = (loader: Loader) => {
    setLoaders((prevLoaders) => {
      const newLoaders = new Set(prevLoaders);
      newLoaders.delete(loader);
      return newLoaders;
    });
  };

  const addLoader = (loader: Loader) => {
    setLoaders((prevLoaders) => {
      const newLoaders = new Set(prevLoaders);
      newLoaders.add(loader);
      return newLoaders;
    });
  };

  const isLoaded = (loader: Loader): boolean => {
    return !loaders.has(loader);
  };

  return (
    <LoadingManagerContext.Provider
      value={{ addLoader, removeLoader, isLoaded, loading }}
    >
      {children({
        isLoaded,
        loading,
        removeLoader,
        addLoader,
      })}
    </LoadingManagerContext.Provider>
  );
};

export const useLoadingManager = (): LoadingManagerValues => {
  const context = useContext(LoadingManagerContext);

  return context;
};
