import * as io from 'io-ts';
export var POSCheckoutCapabilities = io.union([io.literal('TapToPay'), io.literal('KeyEntry')]);
export var MerchantDetails = io.type({
  id: io.string,
  name: io.string,
  url: io.string,
  merchantLogo: io.string,
  phoneNumber: io.string,
  hipaaRestricted: io["boolean"],
  instorePickupAvailable: io["boolean"],
  posCheckoutCapability: io.union([io.array(POSCheckoutCapabilities), io["null"], io.undefined // TODO: Remove me when API is ready
  ]),
  borrowRatioBPS: io.union([io.number, io.undefined]),
  canViewCapacity: io["boolean"]
});
export var MerchantDetailsResponse = io.type({
  merchant: MerchantDetails
});