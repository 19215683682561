import React, { ReactElement, useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import { Address } from '@ads-bread/shared/bread/codecs';
import { FormikValues } from 'formik';
import { APIForm, FormChildrenProps } from '../../../components/APIForm';
import { VerifyPostalValues } from '../../../components/VerifyPostalValues';
import { useAppConfig } from '../../AppConfigContext';
import { setAdditionalContext } from '../../../lib/analytics';
import { useBuyerMachine } from '../../BuyerMachineContext';

export function getInitialAddressFormValues(
  formAddress?: Address,
  country = 'US'
): Address {
  return {
    address1: formAddress?.address1 ?? '',
    address2: formAddress?.address2 ?? '',
    country: formAddress?.country || country,
    locality: formAddress?.locality ?? '',
    postalCode: formAddress?.postalCode ?? '',
    region: formAddress?.region ?? '',
  };
}

/**
 * Checks every property of an address to determine if there are no values
 * @param address
 * @returns boolean
 */
export function isEveryAddressPropertyEmpty(address?: Address): boolean {
  return !address || Object.values(address).every((field) => !field);
}

export function getFormAddress(
  primaryContactAddress: Address | undefined,
  billingAddress: Address | undefined | null,
  shippingAddress: Address | undefined | null
): Address | undefined {
  if (
    primaryContactAddress &&
    !isEveryAddressPropertyEmpty(primaryContactAddress)
  ) {
    return primaryContactAddress;
  }
  if (billingAddress && !isEveryAddressPropertyEmpty(billingAddress)) {
    return billingAddress;
  }
  if (shippingAddress && !isEveryAddressPropertyEmpty(shippingAddress)) {
    return shippingAddress;
  }
}

export interface BillingAddressProps<FormValues extends FormikValues> {
  validationSchema: ObjectSchema;
  heading: React.ElementType;
  initialFormValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  children: (
    options: FormChildrenProps<FormValues> & {
      isNameEditable: boolean;
    }
  ) => JSX.Element;
}

export function BillingAddress<FormValues extends Address>({
  heading: Heading,
  validationSchema,
  initialFormValues,
  children,
  onSubmit,
}: BillingAddressProps<FormValues>): ReactElement {
  const { buyer: contextBuyer, billingAddress, buyerName } = useBuyerMachine();
  const { googleApiKey } = useAppConfig();
  const [isConfirming, setIsConfirming] = useState(false);

  const primaryContact =
    contextBuyer?.contacts?.[contextBuyer?.primaryContactID];
  const name =
    primaryContact?.name || contextBuyer?.identity?.name || buyerName;

  // Shipping address is excluded from edit screen. We pre-fill form in that case
  const address = getFormAddress(
    primaryContact?.address,
    billingAddress,
    undefined
  );
  const hasPrefilledAddress =
    !!name && !!address && !isEveryAddressPropertyEmpty(address);
  const isNameEditable =
    !!primaryContact?.name || !!contextBuyer?.identity?.name;

  useEffect(() => {
    const confirmingAddress = isConfirming && !!name && !!address;

    setAdditionalContext({ is_confirming_address: confirmingAddress });
  }, [isConfirming]);

  // Run once, we don't want the review application screen to show when submitting
  useEffect(() => {
    setIsConfirming(
      hasPrefilledAddress && validationSchema.isValidSync(initialFormValues)
    );
  }, []);

  return (
    <>
      <Heading />

      <VerifyPostalValues
        isConfirming={isConfirming}
        country={initialFormValues.country}
        postalCode={initialFormValues.postalCode}
        region={initialFormValues.region}
        setIsConfirming={setIsConfirming}
        googleApiKey={googleApiKey}
      >
        <APIForm<FormValues>
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(options) => (
            <>
              {children({
                ...options,
                isNameEditable,
              })}
            </>
          )}
        </APIForm>
      </VerifyPostalValues>
    </>
  );
}

export default BillingAddress;
