import { FormattedMessage } from 'react-intl';
import React, { FunctionComponent } from 'react';
import { Lock } from '../svgs/Lock';

export const Encrypted: FunctionComponent = () => (
  <>
    <Lock className="w-4 fill-current" aria-hidden="true" />
    <span className="text-base font-normal">
      <FormattedMessage
        defaultMessage="Encrypted"
        description="Footer 'encrypted' message"
      />
    </span>
  </>
);
