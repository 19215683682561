import { useEffect } from 'react';
import { AnalyticsEvent, PageNames, track } from '../analytics';
import { logger } from '../logger';

export { PageNames };

interface UseTrackPageViewProps {
  pageName?: PageNames;
  mode?: 'manual' | 'auto';
}

interface TrackPageViewValues {
  trackPageView: (pageName: PageNames) => void;
}

/**
 * Track a defined page name on component mount
 * Note: Full page routes are already tracked via their respective routers using
 * the meta.analyticsPageName. Please only use this in limited circumstances such as popovers.
 * @param pageName
 * @param mode 'auto' | 'manual'
 * @returns TrackPageViewValues
 */
export const useTrackPageView = ({
  mode = 'auto',
  pageName,
}: UseTrackPageViewProps): TrackPageViewValues => {
  const trackPageView = (page: PageNames) => {
    track(AnalyticsEvent.PageLoaded, {
      page: { title: page },
    });
  };

  useEffect(() => {
    if (!pageName && mode === 'auto') {
      return logger.error(
        {},
        'useTrackPageView required pageName when mode is set to auto.'
      );
    } else if (pageName && mode === 'auto') {
      trackPageView(pageName);
    }
  }, [pageName, mode]);

  return { trackPageView };
};
