import * as io from 'io-ts';
var VirtualCardIssuanceScopeType = io.keyof({
  APPLICATION: null
});
/** A new issuance request for a virtual card.
 * Endpoint: POST /api/virtual-card/issuance
 **/

export var VirtualCardIssuanceRequest = io.type({
  /** Only supports APPLICATION */
  scopeType: VirtualCardIssuanceScopeType,

  /** Only supports applicationID */
  scopeID: io.string,

  /** ID of the program associated with this issuance */
  programID: io.string,

  /** ID of the buyer associated with this issuance */
  buyerID: io.string
});
var VirtualCardInStoreIssuance = io.type({
  applicationID: io.string,
  merchantID: io.string,
  merchantLocationID: io.string,
  offerID: io.string
});
export var VirtualCardIssuanceStatus;

(function (VirtualCardIssuanceStatus) {
  VirtualCardIssuanceStatus["REQUESTED"] = "REQUESTED";
  VirtualCardIssuanceStatus["PROCESSING"] = "PROCESSING";
  VirtualCardIssuanceStatus["ISSUED"] = "ISSUED";
  VirtualCardIssuanceStatus["FAILED"] = "FAILED";
  VirtualCardIssuanceStatus["CLOSED"] = "CLOSED";
})(VirtualCardIssuanceStatus || (VirtualCardIssuanceStatus = {}));

var VirtualCardIssuance = io.intersection([io.type({
  id: io.string,

  /** Provisioning status of the issuance */
  status: io.keyof(VirtualCardIssuanceStatus),
  scopeType: io.string,
  scopeID: io.string
}), io.partial({
  inStore: VirtualCardInStoreIssuance,
  buyerID: io.string,
  programID: io.string,
  tenantID: io.string,
  paymentProductID: io.string
})]);
var VirtualCardCapacity = io.type({
  currency: io.string,
  value: io.number
});
export var VirtualCardGPayIssuanceRequest = io.type({
  /** A string that identifies the backend session. Provided by Google pay backend. */
  serverSessionId: io.string,

  /** A string that identifies the client session. Provided by Google pay backend. */
  clientSessionId: io.string,

  /** 1 => Tokenization will be attempted.; 0 => Tokenization will not be attempted. */
  tokenSetting: io.number,

  /** 1 => FPAN save will be attempted.; 0 => FPAN save will not be attempted. */
  cardSetting: io.number,

  /** String identifying the device-scoped Google Pay wallet that will receive the token.
   * Provided by Google Pay backend. */
  publicWalletId: io.string,

  /** String identifying the Android device that will receive the token.
   * Provided by Google Pay backend */
  publicDeviceId: io.string,

  /** A Google-assigned string that uniquely identifies both the integrator that is
   * initiating the session and the issuer of the payment card. */
  integratorId: io.string
});
export var VirtualCardGPayIssuanceResponse = io.intersection([io.type({
  /** ID for the wallet issuance request */
  walletID: io.string
}), io.partial({
  state: io.string
})]);
export var VirtualCardStatus;

(function (VirtualCardStatus) {
  VirtualCardStatus["NOT_CREATED"] = "NOT_CREATED";
  VirtualCardStatus["REQUESTED"] = "REQUESTED";
  VirtualCardStatus["PROVISIONING"] = "PROVISIONING";
  VirtualCardStatus["PROVISIONED"] = "PROVISIONED";
  VirtualCardStatus["ACTIVE"] = "ACTIVE";
  VirtualCardStatus["FAILED"] = "FAILED";
})(VirtualCardStatus || (VirtualCardStatus = {}));

export var VirtualCard = io.intersection([io.type({
  cardID: io.string,

  /** Provisioning status of the virtual card */
  status: io.keyof(VirtualCardStatus)
}), io.partial({
  capacity: VirtualCardCapacity,
  lastFour: io.string,
  issuanceDate: io.string,

  /** Expiration date of the virtual card */
  expiry: io.string,
  pan: io.string,
  cvv: io.string
})]);
export var WalletType;
/** Provisioning status of a digital wallet token */

(function (WalletType) {
  WalletType["APPLEPAY"] = "APPLEPAY";
  WalletType["GOOGLEPAY"] = "GOOGLEPAY";
})(WalletType || (WalletType = {}));

export var WalletStatus;

(function (WalletStatus) {
  WalletStatus["PROVISIONING"] = "PROVISIONING";
  WalletStatus["ACTIVE"] = "ACTIVE";
  WalletStatus["FAILED"] = "FAILED";
  WalletStatus["SUSPENDED"] = "SUSPENDED";
  WalletStatus["TERMINATED"] = "TERMINATED";
})(WalletStatus || (WalletStatus = {}));

var ApplePay = io.type({
  state: io.string
});
var GooglePay = io.type({
  publicWalletId: io.string,
  publicDeviceId: io.string
});
var VirtualCardWallet = io.intersection([io.type({
  id: io.string,
  type: io.keyof(WalletType),

  /** Provisioning status of a digital wallet token */
  status: io.keyof(WalletStatus)
}), io.partial({
  /** Wallet DPAN. Only available with r.vc.restricted scope. */
  pan: io.string,
  applePay: ApplePay,
  googlePay: GooglePay,

  /** indicates if this wallet is unrecoverable because of red path */
  error: io["boolean"]
})]);
var VirtualCardIssuanceSummary = io.type({
  /** Total authorized transaction */
  authorized: io.number,

  /** Total settled transaction */
  settled: io.number,

  /** Total refunded transaction */
  refunded: io.number
});
export var VirtualCardIssuanceResponse = io.intersection([io.type({
  issuance: VirtualCardIssuance
}), io.partial({
  card: VirtualCard,

  /** Wallets associated with the virtual card */
  wallets: io.array(VirtualCardWallet),

  /** Total authorized/settled/refunded transactions */
  summary: VirtualCardIssuanceSummary
})]);
export var ApplePayJWSHeader = io.type({
  kid: io.string
});
export var ApplePayJWS = io.type({
  header: ApplePayJWSHeader,
  "protected": io.string,
  payload: io.string,
  signature: io.string
});
export var AppleWalletIssuanceRequest = io.type({});
export var AppleWalletIssuanceResponse = io.intersection([io.type({
  walletID: io.string
}), io.partial({
  state: io.string,
  jws: ApplePayJWS
})]);