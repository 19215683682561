/**
 * Gets valid offers and warns to console if there are any invalid offers.
 *
 * @param offers - An array of offers.
 * @param merchantPaymentProducts - The merchant payment products.
 *
 * @returns - Returns an offer array
 *
 */
export function validateOfferProductTypes(offers, merchantPaymentProducts) {
  var groupedOffers = groupValidAndInvalidOffers(offers, merchantPaymentProducts);

  if (groupedOffers.invalidOffers.length > 0) {
    var nonMatchingOffers = groupedOffers.invalidOffers.map(function (offer) {
      return offer.paymentProduct.id;
    });
    var availableProducts = merchantPaymentProducts === null || merchantPaymentProducts === void 0 ? void 0 : merchantPaymentProducts.map(function (product) {
      return product.paymentProduct.id;
    });
    console.warn("Could not find matching product types for all buyer offers.\n        Available merchant payment products ".concat(availableProducts, "\n        Buyer offers with payment products that do not match ").concat(nonMatchingOffers));
  }

  return groupedOffers.validOffers;
}
/**
 * Checks if all offers are valid.
 *
 * @param offers - An array of offers.
 * @param merchantPaymentProducts - The merchant payment products.
 *
 * @returns - Returns true if all offers are valid
 *
 */

export function hasValidOffers(offers, merchantPaymentProducts) {
  return groupValidAndInvalidOffers(offers, merchantPaymentProducts).invalidOffers.length === 0;
}

function groupValidAndInvalidOffers(offers, merchantPaymentProducts) {
  var groupedOffers = {
    validOffers: [],
    invalidOffers: []
  };
  return offers.reduce(function (acc, curr) {
    var _merchantPaymentProdu;

    var matchingPaymentProductType = (_merchantPaymentProdu = merchantPaymentProducts.find(function (product) {
      return product.paymentProduct.id === curr.paymentProduct.id;
    })) === null || _merchantPaymentProdu === void 0 ? void 0 : _merchantPaymentProdu.paymentProduct.type;

    if (matchingPaymentProductType) {
      acc.validOffers.push(curr);
    } else {
      acc.invalidOffers.push(curr);
    }

    return acc;
  }, groupedOffers);
}