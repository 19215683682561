import React from 'react';
import { FCWithChildren } from '../lib/types';

interface PProps {
  className?: string;
  dataTestID?: string;
  style?: React.CSSProperties;
}

export const P: FCWithChildren<PProps> = ({
  className = '',
  children,
  dataTestID,
}) => {
  return (
    <p data-testid={dataTestID} className={`text-blackberry mb-4 ${className}`}>
      {children}
    </p>
  );
};
