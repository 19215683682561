import {
  AppleWalletIssuanceRequest,
  AppleWalletIssuanceResponse,
  VirtualCardIssuanceRequest,
  VirtualCardIssuanceResponse,
  VirtualCardIssuanceStatus,
  VirtualCard,
  VirtualCardStatus,
  VirtualCardGPayIssuanceRequest,
  VirtualCardGPayIssuanceResponse,
  WalletStatus,
} from '@ads-bread/shared/bread/codecs';
import { poll } from '../poll';
import { logger } from '../logger';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const ISSUANCE_URI = '/api/virtual-card/issuance';

export async function createVirtualCardIssuance(
  virtualCardIssuanceRequest: VirtualCardIssuanceRequest,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardIssuanceResponse>> {
  const res = handleResponse(
    await apiFetch<VirtualCardIssuanceResponse, VirtualCardIssuanceRequest>(
      ISSUANCE_URI,
      { body: virtualCardIssuanceRequest },
      VirtualCardIssuanceResponse
    )
  );
  return res.error ||
    res.result.issuance.status === VirtualCardIssuanceStatus.FAILED
    ? res
    : await pollVirtualCardIssuance(res.result.issuance.id, apiFetch);
}

export async function getVirtualCardIssuancePAN(
  issuanceID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCard>> {
  const response = await apiFetch<VirtualCard>(
    `${ISSUANCE_URI}/${issuanceID}/card/pan`,
    { method: 'GET' },
    VirtualCard
  );
  return handleResponse(response);
}

export async function getVirtualCardIssuance(
  issuanceID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardIssuanceResponse>> {
  const response = await apiFetch<VirtualCardIssuanceResponse>(
    `${ISSUANCE_URI}/${issuanceID}`,
    { method: 'GET' },
    VirtualCardIssuanceResponse
  );

  const issuanceResponse = handleResponse(response);
  if (
    issuanceResponse.error ||
    issuanceResponse.result.card?.status === VirtualCardStatus.FAILED
  ) {
    return issuanceResponse;
  }

  // Pan cannot be requested until the card is ISSUED
  const isIssued = issuanceResponse.result.issuance.status === 'ISSUED';

  if (isIssued) {
    const panRes = await getVirtualCardIssuancePAN(
      issuanceResponse.result.issuance.id,
      apiFetch
    );
    if (panRes.error) {
      logger.error(
        { err: panRes.error },
        'Virtual Card Error: Error getting issuance FPan'
      );
      return panRes;
    }
    if (issuanceResponse.result.card) {
      issuanceResponse.result.card.pan = panRes.result.pan;
      issuanceResponse.result.card.cvv = panRes.result.cvv;
    }
  }

  return issuanceResponse;
}

export async function pollVirtualCardIssuance(
  issuanceID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardIssuanceResponse>> {
  const issuanceResponse = await poll<
    HandlerResponse<VirtualCardIssuanceResponse>
  >(
    async () => await getVirtualCardIssuance(issuanceID, apiFetch),
    (response) => {
      return (
        !!response.error ||
        response.result.card?.status === VirtualCardStatus.ACTIVE ||
        response.result.card?.status === VirtualCardStatus.FAILED
      );
    },
    2000,
    10
  );
  return issuanceResponse;
}

export async function pollVirtualCardWalletForActiveWallet(
  issuanceID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardIssuanceResponse>> {
  const issuanceResponse = await poll<
    HandlerResponse<VirtualCardIssuanceResponse>
  >(
    async () => await getVirtualCardIssuance(issuanceID, apiFetch),
    (response) => {
      return (
        !!response.error ||
        !!response.result.wallets?.some((w) => w.status === WalletStatus.ACTIVE)
      );
    },
    1000,
    5,
    false
  );
  return issuanceResponse;
}

export async function pollVirtualCardWalletsForError(
  issuanceID: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardIssuanceResponse>> {
  const issuanceResponse = await poll<
    HandlerResponse<VirtualCardIssuanceResponse>
  >(
    async () => await getVirtualCardIssuance(issuanceID, apiFetch),
    (response) => {
      return (
        !!response.error ||
        !!response.result.wallets?.some(
          (w) =>
            w.status === WalletStatus.ACTIVE ||
            (w.status === WalletStatus.TERMINATED && w.error === true)
        )
      );
    },
    1000,
    5,
    false
  );
  return issuanceResponse;
}

export async function processIssuanceForAppleWallet(
  issuanceId: string,
  apiFetch: APIFetch
): Promise<HandlerResponse<AppleWalletIssuanceResponse>> {
  const applePayIssuanceURI = `${ISSUANCE_URI}/${issuanceId}/wallet/applepay`;
  const res: APIResponse<AppleWalletIssuanceResponse> = await apiFetch<
    AppleWalletIssuanceResponse,
    AppleWalletIssuanceRequest
  >(applePayIssuanceURI, { body: {} }, AppleWalletIssuanceResponse);
  return handleResponse(res);
}

export async function provisionGPayWallet(
  issuanceID: string,
  gpayIssuance: VirtualCardGPayIssuanceRequest,
  apiFetch: APIFetch
): Promise<HandlerResponse<VirtualCardGPayIssuanceResponse>> {
  const gPayIssuanceURI = `${ISSUANCE_URI}/${issuanceID}/wallet/googlepay`;
  const res: APIResponse<VirtualCardGPayIssuanceResponse> = await apiFetch<
    VirtualCardGPayIssuanceResponse,
    VirtualCardGPayIssuanceRequest
  >(gPayIssuanceURI, { body: gpayIssuance }, VirtualCardGPayIssuanceResponse);
  return handleResponse(res);
}
