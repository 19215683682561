import { createMachine } from 'xstate';
import {
  RouteMachineServiceEvents,
  getRoutingMachineLevelEvents,
} from '../../components/RouteMachineService';
import { PageNames } from '../analytics';

export enum Routes {
  Intro = '/cart/[cartID]',
  Email = '/cart/[cartID]/email',
  Mobile = '/cart/[cartID]/mobile',
  Verification = '/cart/[cartID]/verification',
  ReviewApplication = '/cart/[cartID]/review-application',
  BillingAddress = '/cart/[cartID]/billing-address',
  Eligibility = '/cart/[cartID]/eligibility',
  Terms = '/cart/[cartID]/terms',
  Review = '/cart/[cartID]/review',
  Confirmation = '/cart/[cartID]/confirmation',
  LoginEligibility = '/cart/[cartID]/login-eligibility',
  ConfirmEmail = '/cart/[cartID]/confirm-email',
  NeedsFullIIN = '/cart/[cartID]/needs-full-iin',
  IDVerificationInstructions = '/cart/[cartID]/id-verification/instructions',
  IDVerificationUploadFront = '/cart/[cartID]/id-verification/upload-front',
  IDVerificationUploadBack = '/cart/[cartID]/id-verification/upload-back',
  IDVerificationReview = '/cart/[cartID]/id-verification/review',
  IDVerificationNeedsAction = '/cart/[cartID]/id-verification/needs-action',
  IDVerificationAlloyNeedsAction = '/cart/[cartID]/id-verification/alloy-needs-action',
  IDVerificationAlloy = '/cart/[cartID]/id-verification/alloy',
  // Error Routes
  AgeAlabamaMilitary = '/cart/[cartID]/errors/age-alabama-military',
  AgeIneligible = '/cart/[cartID]/errors/age-ineligible',
  BuyerHashFailed = '/cart/[cartID]/errors/buyer-hash-failed',
  BuyerSkippedInstallments = '/cart/[cartID]/errors/buyer-skipped-installments',
  BuyerStatusIneligible = '/cart/[cartID]/errors/buyer-status-ineligible',
  CreditDenial = '/cart/[cartID]/errors/credit-denial',
  CreditFreeze = '/cart/[cartID]/errors/credit-freeze',
  IDVerificationFailure = '/cart/[cartID]/errors/id-verification-failure',
  IDVerificationMaxAttemptsExceeded = '/cart/[cartID]/errors/id-verification-max-attempts-exceeded',
  IDVerificationNeedsActionWarning = '/cart/[cartID]/errors/id-verification-needs-action-warning',
  KycDenial = '/cart/[cartID]/errors/kyc-denial',
  LoanStatusIneligible = '/cart/[cartID]/errors/loan-status-ineligible',
  LocationIneligible = '/cart/[cartID]/errors/location-ineligible',
  MaxCardIneligible = '/cart/[cartID]/errors/max-card-ineligible',
  NeedsActionWarning = '/cart/[cartID]/errors/needs-action-warning',
  OFACDenial = '/cart/[cartID]/errors/ofac-denial',
  SanctionDenial = '/cart/[cartID]/errors/sanctions-denial',
  OutstandingLoansIneligible = '/cart/[cartID]/errors/outstanding-loans-ineligible',
  POBoxAddressIneligible = '/cart/[cartID]/errors/po-box-address-ineligible',
  PreviousDenialIneligible = '/cart/[cartID]/errors/previous-denial-ineligible',
  VerifiedEmail = '/cart/[cartID]/errors/verified-email',
  FraudAlertDenial = '/cart/[cartID]/errors/fraud-alert-denial',
  FraudDenial = '/cart/[cartID]/errors/fraud-denial',
  VirtualCardError = '/cart/[cartID]/errors/card-error',
  VirtualCardErrorFailed = '/cart/[cartID]/errors/card-error-failed',
  VirtualCardErrorInvalidConfig = '/cart/[cartID]/errors/card-error-invalid-config',
  VirtualCardErrorUnsupportedDevice = '/cart/[cartID]/errors/card-error-unsupported-device',
  CapacityRecheck = '/cart/[cartID]/errors/capacity-recheck',
  Unknown = '/cart/[cartID]/errors/unknown-error',
}

export const routingMachine = createMachine({
  id: 'router',
  predictableActionArguments: true,
  initial: 'initializing',
  tsTypes: {} as import('./cartRoutingMachine.typegen').Typegen0,
  schema: {
    events: {} as RouteMachineServiceEvents,
  },
  on: {
    ...getRoutingMachineLevelEvents(Routes),
  },
  states: {
    initializing: {
      on: {
        SEND_RESET_ROUTER_STATE: {
          target: Routes.Intro,
        },
        SEND_ROUTE_COMPLETE_BUYER: [
          {
            cond: 'hasReviewedApplication',
            target: Routes.Terms,
          },
          {
            target: Routes.ReviewApplication,
          },
        ],
        SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN: {
          target: Routes.NeedsFullIIN,
        },
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
      },
      always: [
        {
          cond: 'isUnauthenticatedInitial',
          target: Routes.Intro,
        },
      ],
      exit: ['removeLoadingIndicator'],
    },
    [Routes.Intro]: {
      meta: {
        analyticsPageName: PageNames.Intro,
        prefetchRoutes: [Routes.Email],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Email]: {
      meta: {
        analyticsPageName: PageNames.Email,
        prefetchRoutes: [Routes.Mobile],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Mobile,
        },
        SEND_BACK: {
          target: Routes.Intro,
        },
      },
    },
    [Routes.Mobile]: {
      meta: {
        analyticsPageName: PageNames.Mobile,
        prefetchRoutes: [Routes.Verification],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Verification,
        },
        SEND_BACK: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Verification]: {
      meta: {
        analyticsPageName: PageNames.Verification,
        prefetchRoutes: [Routes.ReviewApplication, Routes.BillingAddress],
      },
      on: {
        SEND_BACK: {
          target: Routes.Mobile,
        },
        SEND_ROUTE_NEW_BUYER: [
          {
            cond: 'isCartRetrieved',
            target: Routes.ReviewApplication,
          },
          {
            target: Routes.BillingAddress,
          },
        ],
        SEND_ROUTE_COMPLETE_BUYER: [
          {
            cond: 'hasReviewedApplication',
            target: Routes.Terms,
          },
          {
            target: Routes.ReviewApplication,
          },
        ],
        SEND_ROUTE_AUTH_RETRY_WITH_PII: {
          target: Routes.LoginEligibility,
        },
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
      },
    },
    [Routes.BillingAddress]: {
      meta: {
        analyticsPageName: PageNames.BillingAddress,
        prefetchRoutes: [Routes.Terms, Routes.Eligibility],
      },
      on: {
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.ReviewApplication]: {
      meta: {
        analyticsPageName: PageNames.ReviewApplication,
        prefetchRoutes: [Routes.Terms, Routes.BillingAddress],
      },
      on: {
        SEND_FORWARD: [
          {
            cond: 'isBuyerReadyComplete',
            target: Routes.Terms,
          },
          {
            target: Routes.BillingAddress,
          },
        ],
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
        SEND_BACK: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION: {
          target: Routes.IDVerificationNeedsAction,
        },
      },
    },
    [Routes.Eligibility]: {
      meta: {
        analyticsPageName: PageNames.Eligibility,
        prefetchRoutes: [Routes.Terms, Routes.ReviewApplication],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: [
          {
            cond: 'hasReviewedApplication',
            target: Routes.Terms,
          },
          {
            target: Routes.ReviewApplication,
          },
        ],
        SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN: {
          target: Routes.NeedsFullIIN,
        },
        SEND_BACK: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.LoginEligibility]: {
      meta: {
        analyticsPageName: PageNames.LoginEligibility,
        prefetchRoutes: [
          Routes.ConfirmEmail,
          Routes.BillingAddress,
          Routes.ReviewApplication,
        ],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: [
          // Returning mismatched - authentication.authenticated.complete.mismatchedBuyerPII
          {
            cond: 'isAuthenticatedCompleteMismatchedBuyerPII',
            target: Routes.ConfirmEmail,
          },
          {
            target: Routes.ReviewApplication,
          },
        ],
        SEND_ROUTE_NEW_BUYER: [
          {
            cond: 'isCartRetrieved',
            target: Routes.ReviewApplication,
          },
          {
            target: Routes.BillingAddress,
          },
        ],
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_BACK: {
          target: Routes.Verification,
        },
      },
    },
    [Routes.ConfirmEmail]: {
      meta: {
        analyticsPageName: PageNames.ConfirmEmail,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.NeedsFullIIN]: {
      meta: {
        analyticsPageName: PageNames.NeedsFullIIN,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.Terms]: {
      meta: {
        analyticsPageName: PageNames.Terms,
        prefetchRoutes: [Routes.Review],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Review,
        },
        SEND_ROUTE_WITH_CONDITION: {
          cond: 'isUnknownErrorRouteCondition',
          target: Routes.Unknown,
        },
      },
    },
    [Routes.Review]: {
      meta: {
        analyticsPageName: PageNames.Review,
        prefetchRoutes: [Routes.Confirmation],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Confirmation,
        },
        SEND_BACK: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION: [
          {
            cond: 'isIDVerificationAlloy',
            target: Routes.IDVerificationAlloyNeedsAction,
          },
          {
            target: Routes.IDVerificationNeedsAction,
          },
        ],
        SEND_ROUTE_WITH_CONDITION: [
          {
            cond: 'isBillingAddressRouteCondition',
            target: Routes.BillingAddress,
          },
        ],
      },
    },
    [Routes.Confirmation]: {
      meta: {
        analyticsPageName: PageNames.Confirmation,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationNeedsAction]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationNeedsAction,
        prefetchRoutes: [Routes.IDVerificationInstructions],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationInstructions,
        },
      },
    },
    [Routes.IDVerificationInstructions]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationInstructions,
        prefetchRoutes: [Routes.IDVerificationUploadFront],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationUploadFront,
        },
        SEND_BACK: {
          target: Routes.IDVerificationNeedsAction,
        },
      },
    },
    [Routes.IDVerificationUploadFront]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationUploadFront,
        prefetchRoutes: [Routes.IDVerificationUploadBack],
      },
      on: {
        SEND_FORWARD: [
          {
            cond: 'hasBackImageData',
            target: Routes.IDVerificationReview,
          },
          {
            target: Routes.IDVerificationUploadBack,
          },
        ],
        SEND_BACK: {
          target: Routes.IDVerificationInstructions,
        },
      },
    },
    [Routes.IDVerificationUploadBack]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationUploadBack,
        prefetchRoutes: [Routes.IDVerificationReview],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationReview,
        },
        SEND_BACK: {
          target: Routes.IDVerificationUploadFront,
        },
      },
    },
    [Routes.IDVerificationReview]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationReview,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_WITH_CONDITION: [
          {
            cond: 'isIDVerificationUploadFrontRouteCondition',
            target: Routes.IDVerificationUploadFront,
          },
          {
            cond: 'isIDVerificationUploadBackRouteCondition',
            target: Routes.IDVerificationUploadBack,
          },
        ],
      },
    },
    [Routes.IDVerificationAlloyNeedsAction]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationAlloyNeedsAction,
        prefetchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationAlloy,
        },
      },
    },
    [Routes.IDVerificationAlloy]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationAlloy,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Review,
        },
      },
    },
    // Error Routes
    [Routes.AgeAlabamaMilitary]: {
      meta: {
        analyticsPageName: PageNames.AgeAlabamaMilitaryError,
        prefetchRoutes: [],
      },
    },
    [Routes.AgeIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerAgeIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerHashFailed]: {
      meta: {
        analyticsPageName: PageNames.BuyerHashError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.BuyerSkippedInstallments]: {
      meta: {
        analyticsPageName: PageNames.BuyerSkippedInstallmentsError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerStatusIneligible,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditDenial]: {
      meta: {
        analyticsPageName: PageNames.CreditDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditFreeze]: {
      meta: {
        analyticsPageName: PageNames.CreditFreezeError,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationFailure]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationFailure,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationMaxAttemptsExceeded]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationMaxAttempts,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationNeedsActionWarning]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationNeedsActionWarningError,
        prefetchRoutes: [],
      },
    },
    [Routes.KycDenial]: {
      meta: {
        analyticsPageName: PageNames.KYCDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.LoanStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.LoanStatusIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.LocationIneligible]: {
      meta: {
        analyticsPageName: PageNames.LocationIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.MaxCardIneligible]: {
      meta: {
        analyticsPageName: PageNames.MaxCardIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.NeedsActionWarning]: {
      meta: {
        analyticsPageName: PageNames.NeedsActionWarningError,
        prefetchRoutes: [],
      },
    },
    [Routes.OFACDenial]: {
      meta: {
        analyticsPageName: PageNames.OFACError,
        prefetchRoutes: [],
      },
    },
    [Routes.OutstandingLoansIneligible]: {
      meta: {
        analyticsPageName: PageNames.OutstandingLoanIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.POBoxAddressIneligible]: {
      meta: {
        analyticsPageName: PageNames.POBoxAddressIneligibleError,
        prefetchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.PreviousDenialIneligible]: {
      meta: {
        analyticsPageName: PageNames.PreviousDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.VerifiedEmail]: {
      meta: {
        analyticsPageName: PageNames.VerifiedEmailError,
        prefetchRoutes: [],
      },
    },
    [Routes.SanctionDenial]: {
      meta: {
        analyticsPageName: PageNames.SanctionDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudAlertDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudAlertDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CapacityRecheck]: {
      meta: {
        analyticsPageName: PageNames.CapacityRecheckError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_COMPLETE_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.VirtualCardError]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardFraudStopError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorFailed]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardFailedError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorInvalidConfig]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardInvalidMerchantConfigError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorUnsupportedDevice]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardUnsupportedDeviceError,
        prefetchRoutes: [],
      },
    },
    [Routes.Unknown]: {
      meta: {
        analyticsPageName: PageNames.UnknownError,
        prefetchRoutes: [],
      },
    },
  },
});
