// Autogenerated file. Run yarn update-openapi-specs to update this file.
export default {
  openapi: '3.0.3',
  info: {
    title: 'Status codes',
    description: 'Defines shared status codes for the bread payments api',
    version: '2.0.0'
  },
  paths: {},
  components: {
    schemas: {
      ApplicationExpired: {
        type: 'string',
        description: 'Application expired',
        "enum": ['010200'],
        'x-enum-constant': true,
        'x-enum-domain': 'APPLICATION',
        'x-enum-status': 'EXPIRED',
        'x-enum-substatus': ''
      },
      ApplicationCancelledBuyerHashCheckFailed: {
        type: 'string',
        description: 'Buyer hash check failed',
        "enum": ['010101'],
        'x-enum-constant': true,
        'x-enum-domain': 'APPLICATION',
        'x-enum-status': 'CANCELLED',
        'x-enum-substatus': 'BUYER_HASH_CHECK_FAILED'
      },
      ApplicationCancelledPaymentProductNotAvailable: {
        type: 'string',
        description: 'Payment product not available',
        "enum": ['010102'],
        'x-enum-constant': true,
        'x-enum-domain': 'APPLICATION',
        'x-enum-status': 'CANCELLED',
        'x-enum-substatus': 'PAYMENT_PRODUCT_NOT_AVAILABLE'
      },
      CapacityCancelledContingent: {
        type: 'string',
        description: 'Application cancelled contingent on restart with parent',
        "enum": ['080101'],
        'x-enum-constant': true,
        'x-enum-domain': 'CAPACITY',
        'x-enum-status': 'CANCELLED',
        'x-enum-substatus': 'CANCELLED_CONTINGENT'
      },
      CapacityCancelledPartial: {
        type: 'string',
        description: 'Application cancelled partial on restart with parent',
        "enum": ['080102'],
        'x-enum-constant': true,
        'x-enum-domain': 'CAPACITY',
        'x-enum-status': 'CANCELLED',
        'x-enum-substatus': 'CANCELLED_PARTIAL'
      },
      EligibilityEligibleEligible: {
        type: 'string',
        description: 'Buyer is eligible',
        "enum": ['020101'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'ELIGIBLE',
        'x-enum-substatus': 'ELIGIBLE'
      },
      EligibilityIneligibleBuyerStatus: {
        type: 'string',
        description: 'Buyer is ineligible (status)',
        "enum": ['020202'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'BUYER_STATUS'
      },
      EligibilityIneligibleBuyerCountry: {
        type: 'string',
        description: 'Buyer is ineligible (country)',
        "enum": ['020203'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'BUYER_COUNTRY'
      },
      EligibilityIneligibleBuyerRegion: {
        type: 'string',
        description: 'Buyer is ineligible (region)',
        "enum": ['020204'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'BUYER_REGION'
      },
      EligibilityIneligibleBuyerAge: {
        type: 'string',
        description: 'Buyer is ineligible (age)',
        "enum": ['020205'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'BUYER_AGE'
      },
      EligibilityIneligibleBuyerAgeAL: {
        type: 'string',
        description: 'Buyer is ineligible (age - AL)',
        "enum": ['020206'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'BUYER_AGE_AL'
      },
      EligibilityIneligibleInvalidAddressPOBox: {
        type: 'string',
        description: 'Buyer is ineligible due to invalid PO box address',
        "enum": ['020207'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'INVALID_ADDRESS_POBOX'
      },
      EligibilityIneligibleInvalidAddressNoMatch: {
        type: 'string',
        description: 'Buyer is ineligible due to address being No Match',
        "enum": ['020208'],
        'x-enum-constant': true,
        'x-enum-domain': 'ELIGIBILITY',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'INVALID_ADDRESS_NOMATCH'
      },
      FraudPass: {
        type: 'string',
        description: 'Fraud pass',
        "enum": ['040100'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'PASS',
        'x-enum-substatus': ''
      },
      FraudIncompleteLowRisk: {
        type: 'string',
        description: 'Fraud Stopped - Low risk tags',
        "enum": ['040201'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'LOW_RISK'
      },
      FraudIncompleteHighRisk: {
        type: 'string',
        description: 'Fraud Stopped - High risk tags',
        "enum": ['040202'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'HIGH_RISK'
      },
      FraudDeniedPreciseIDDenied: {
        type: 'string',
        description: 'Fraud - Precise ID Denied',
        "enum": ['040307'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'PRECISE_ID_DENIAL'
      },
      FraudIncompleteExtendedFraudAlert: {
        type: 'string',
        description: 'Fraud Stopped - Extended fraud alert',
        "enum": ['040204'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'EXTENDED_FRAUD_ALERT'
      },
      FraudDenied: {
        type: 'string',
        description: 'Fraud Failed - user on blocklist (+ generic fail)',
        "enum": ['040300'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': ''
      },
      FraudDeniedFraudFail: {
        type: 'string',
        description: 'Fraud Failed',
        "enum": ['040303'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'FRAUD_FAIL'
      },
      FraudDeniedIDDenial: {
        type: 'string',
        description: 'Applicant fails ID verification',
        "enum": ['040305'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'ID_DENIAL'
      },
      FraudDeniedManualDenial: {
        type: 'string',
        description: 'Fraud Failed - Applicant fails manual review',
        "enum": ['040306'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'MANUAL_DENIAL'
      },
      FraudDeniedBlockListDenial: {
        type: 'string',
        description: 'Fraud Failed - Applicant fails block list check',
        "enum": ['040308'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_BLOCKLIST_DENIAL'
      },
      FraudFailed: {
        type: 'string',
        description: 'Fraud Failed',
        "enum": ['040400'],
        'x-enum-constant': true,
        'x-enum-domain': 'FRAUD',
        'x-enum-status': 'FAILED',
        'x-enum-substatus': ''
      },
      KYCPassed: {
        type: 'string',
        description: 'KYC Pass',
        "enum": ['050100'],
        'x-enum-constant': true,
        'x-enum-domain': 'KYC',
        'x-enum-status': 'PASSED',
        'x-enum-substatus': ''
      },
      KYCIncompleteCreditFreeze: {
        type: 'string',
        description: 'KYC Incomplete credit freeze on file',
        "enum": ['050305'],
        'x-enum-constant': true,
        'x-enum-domain': 'KYC',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'CREDIT_FREEZE'
      },
      KYCIncompleteNeedsMoreInfo: {
        type: 'string',
        description: 'KYC Incomplete need more info',
        "enum": ['050303'],
        'x-enum-constant': true,
        'x-enum-domain': 'KYC',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'NEEDS_MORE_INFO'
      },
      KYCIncompleteFailed: {
        type: 'string',
        description: 'KYC Incomplete failed',
        "enum": ['050301'],
        'x-enum-constant': true,
        'x-enum-domain': 'KYC',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'FAILED'
      },
      KYCFailed: {
        type: 'string',
        description: 'KYC failed',
        "enum": ['050400'],
        'x-enum-constant': true,
        'x-enum-domain': 'KYC',
        'x-enum-status': 'FAILED',
        'x-enum-substatus': ''
      },
      SanctionsPassed: {
        type: 'string',
        description: 'Sanctions Pass',
        "enum": ['060100'],
        'x-enum-constant': true,
        'x-enum-domain': 'SANCTIONS',
        'x-enum-status': 'PASSED',
        'x-enum-substatus': ''
      },
      SanctionsIncompleteFailed: {
        type: 'string',
        description: 'Sanctions (OFAC) Stopped',
        "enum": ['060301'],
        'x-enum-constant': true,
        'x-enum-domain': 'SANCTIONS',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'FAILED'
      },
      SanctionsFailed: {
        type: 'string',
        description: 'Sanctions Stopped',
        "enum": ['060400'],
        'x-enum-constant': true,
        'x-enum-domain': 'SANCTIONS',
        'x-enum-status': 'FAILED',
        'x-enum-substatus': ''
      },
      DownPaymentAuth: {
        type: 'string',
        description: 'SplitPay Checkout Complete',
        "enum": ['070100'],
        'x-enum-constant': true,
        'x-enum-domain': 'DOWN_PAYMENT_AUTH',
        'x-enum-status': 'AUTHORIZED',
        'x-enum-substatus': ''
      },
      DownPaymentAuthAuthorized: {
        type: 'string',
        description: 'SplitPay Checkout Complete',
        "enum": ['070101'],
        'x-enum-constant': true,
        'x-enum-domain': 'DOWN_PAYMENT_AUTH',
        'x-enum-status': 'AUTHORIZED',
        'x-enum-substatus': 'NONE'
      },
      DownPaymentAuthDeclined: {
        type: 'string',
        description: 'SplitPay payment auth failed, retry not allowed',
        "enum": ['070201'],
        'x-enum-constant': true,
        'x-enum-domain': 'DOWN_PAYMENT_AUTH',
        'x-enum-status': 'DECLINED',
        'x-enum-substatus': 'NONE'
      },
      DownPaymentAuthDeclinedDeclinedRetryPaymentMethod: {
        type: 'string',
        description: 'SplitPay payment auth failed, retry allowed',
        "enum": ['070202'],
        'x-enum-constant': true,
        'x-enum-domain': 'DOWN_PAYMENT_AUTH',
        'x-enum-status': 'DECLINED',
        'x-enum-substatus': 'DECLINED_RETRY_PAYMENT_METHOD'
      },
      DecisionApprovedApprovedFull: {
        type: 'string',
        description: 'Application approved for checkout',
        "enum": ['030101'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'APPROVED',
        'x-enum-substatus': 'APPROVED_FULL'
      },
      DecisionApprovedApprovedPartial: {
        type: 'string',
        description: 'Application partially approved for checkout',
        "enum": ['030102'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'APPROVED',
        'x-enum-substatus': 'APPROVED_PARTIAL'
      },
      DecisionApprovedApprovedContingent: {
        type: 'string',
        description: 'Application contingent approved for checkout',
        "enum": ['030103'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'APPROVED',
        'x-enum-substatus': 'APPROVED_CONTINGENT'
      },
      DecisionDeniedInsufficientData: {
        type: 'string',
        description: 'Full SSN mismatch or credit no hit (with full SSN)',
        "enum": ['030404'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'INSUFFICIENT_DATA'
      },
      DecisionIneligibleIneligibleCart: {
        type: 'string',
        description: "Merchant/payment product configured cart size doesn't fit",
        "enum": ['030205'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'INELIGIBLE_CART'
      },
      DecisionIncompleteIncompleteCreditFreeze: {
        type: 'string',
        description: 'Credit Freeze',
        "enum": ['030306'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'INCOMPLETE_CREDIT_FREEZE'
      },
      DecisionIncompleteNeedsFullIIN: {
        type: 'string',
        description: 'DOB, short SSN  mismatch or Credit No Hit',
        "enum": ['030307'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INCOMPLETE',
        'x-enum-substatus': 'NEEDS_FULL_IIN'
      },
      DecisionDeniedDeniedCredit: {
        type: 'string',
        description: 'Credit Denial',
        "enum": ['030408'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CREDIT'
      },
      DecisionIneligiblePreviousDenial: {
        type: 'string',
        description: 'Ineligible denied in last 90 Days',
        "enum": ['030209'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'PREVIOUS_DENIAL'
      },
      DecisionDeniedDeniedFraudAlert: {
        type: 'string',
        description: 'Credit freeze denial',
        "enum": ['030410'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_FRAUD_ALERT'
      },
      DecisionIneligibleIneligibleSkippedInstallments: {
        type: 'string',
        description: 'Participating in Skip Payments',
        "enum": ['030211'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'INELIGIBLE_SKIPPED_INSTALLMENTS'
      },
      DecisionDeniedDeniedNoCreditFile: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030412'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_NO_CREDIT_FILE'
      },
      DecisionDeniedDeniedNoCreditScore: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030413'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_NO_CREDIT_SCORE'
      },
      DecisionDeniedDeniedThinFile: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030414'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_THIN_FILE'
      },
      DecisionDeniedDeniedRepossessions: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030415'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_REPOSSESSIONS'
      },
      DecisionDeniedDeniedForeclosure: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030416'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_FORECLOSURE'
      },
      DecisionDeniedDeniedDerogatoryRecord: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030417'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_DEROGATORY_RECORD'
      },
      DecisionDeniedDeniedTradePastDue: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030418'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_TRADE_PAST_DUE'
      },
      DecisionDeniedDeniedCollections: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030419'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_COLLECTIONS'
      },
      DecisionDeniedDeniedRevolvingCreditUtilization: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030420'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_REVOLVING_CREDIT_UTILIZATION'
      },
      DecisionDeniedDeniedInsufficientCapacity: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030421'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_INSUFFICIENT_CAPACITY'
      },
      DecisionDeniedDeniedAuthorizedTradelines: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030422'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_AUTHORIZED_TRADELINES'
      },
      DecisionDeniedDeniedLowFICO: {
        type: 'string',
        description: 'Denied low FICO',
        "enum": ['030423'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_LOW_FICO'
      },
      DecisionDeniedDeniedCrust: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030424'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CRUST'
      },
      DecisionDeniedDeniedBreadChargeOff: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030425'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_BREAD_CHARGE_OFF'
      },
      DecisionDeniedDeniedPastLoanOverdue: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030426'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_PAST_LOAN_OVERDUE'
      },
      DecisionDeniedDeniedLoanCurrentlyOverdue: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030427'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_LOAN_CURRENTLY_OVERDUE'
      },
      DecisionDeniedDeniedChargeOff: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030428'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CHARGE_OFF'
      },
      DecisionDeniedDeniedAccountPastDue: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030429'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_ACCOUNT_PAST_DUE'
      },
      DecisionDeniedDeniedInquiries: {
        type: 'string',
        description: 'ADS Credit file denial from underwriting service',
        "enum": ['030430'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_INQUIRIES'
      },
      DecisionIneligibleMaxCardAttempt: {
        type: 'string',
        description: 'Max card attempts last 30 days',
        "enum": ['030231'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'MAX_CARD_ATTEMPT'
      },
      DecisionIneligibleOutstandingLoans: {
        type: 'string',
        description: 'Unpaid outstanding loans',
        "enum": ['030232'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'OUTSTANDING_LOANS'
      },
      DecisionDeniedLowCreditLimit: {
        type: 'string',
        description: 'Denied low credit limit',
        "enum": ['030433'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'LOW_CREDIT_LIMIT'
      },
      DecisionDeniedLowFicoLowCreditLimit: {
        type: 'string',
        description: 'SplitPay Denied Low Credit Limit & Low FICO',
        "enum": ['030434'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'LOW_FICO_LOW_CREDIT_LIMIT'
      },
      DecisionDeniedDeniedRevolvingDebtTooHigh: {
        type: 'string',
        description: 'Credit card debt (bankcard + retail) >50k',
        "enum": ['030435'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_REVOLVING_DEBT_TOO_HIGH'
      },
      DecisionDeniedDeniedRevolvingDebtTooLow: {
        type: 'string',
        description: 'Credit card debt (bankcard + retail) <2k',
        "enum": ['030436'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_REVOLVING_DEBT_TOO_LOW'
      },
      DecisionDeniedDeniedSelfReportedIncome: {
        type: 'string',
        description: 'Self reported income <20,000',
        "enum": ['030437'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_SELF_REPORTED_INCOME'
      },
      DecisionDeniedDeniedLowVantage: {
        type: 'string',
        description: 'Low vantage (<660)',
        "enum": ['030438'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_LOW_VANTAGE'
      },
      DecisionDeniedDenied: {
        type: 'string',
        description: 'Buyer status is insolvent',
        "enum": ['030439'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED'
      },
      DecisionIneligibleMaxBureauAttempts: {
        type: 'string',
        description: 'Credit pulled 5 or more times within 24 hours',
        "enum": ['030240'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'INELIGIBLE',
        'x-enum-substatus': 'MAX_BUREAU_ATTEMPTS'
      },
      DecisionDeniedDeniedCreditAbuse: {
        type: 'string',
        description: 'Buyer has previous credit abuse',
        "enum": ['030441'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CREDIT_ABUSE'
      },
      DecisionDeniedDeniedCreditopticsDeceased: {
        type: 'string',
        description: 'Buyer is deceased per Credit Optics',
        "enum": ['030442'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CREDITOPTICS_DECEASED'
      },
      DecisionDeniedDeniedCreditopticsDecline: {
        type: 'string',
        description: 'Buyer is declined per Credit Optics',
        "enum": ['030443'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_CREDITOPTICS_DECLINE'
      },
      DecisionDeniedDeniedBureauDeceased: {
        type: 'string',
        description: 'Buyer is deceased',
        "enum": ['030444'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_BUREAU_DECEASED'
      },
      DecisionDeniedDeniedPreciseidDeceased: {
        type: 'string',
        description: 'Buyer is deceased per Precise ID',
        "enum": ['030445'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_PRECISEID_DECEASED'
      },
      DecisionDeniedDeniedPreciseidDecline: {
        type: 'string',
        description: 'Buyer is declined per Precise ID',
        "enum": ['030446'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_PRECISEID_DECLINE'
      },
      DecisionDeniedDeniedEquifaxDeceased: {
        type: 'string',
        description: 'Buyer is deceased per Equifax',
        "enum": ['030447'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_EQUIFAX_DECEASED'
      },
      DecisionDeniedDeniedBureauData: {
        type: 'string',
        description: 'Miscellaneous Denials for Bureau Data reasons',
        "enum": ['030448'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_BUREAU_DATA'
      },
      DecisionDeniedDeniedLowBureau: {
        type: 'string',
        description: 'Buyer credit score denial',
        "enum": ['030449'],
        'x-enum-constant': true,
        'x-enum-domain': 'DECISION',
        'x-enum-status': 'DENIED',
        'x-enum-substatus': 'DENIED_LOW_BUREAU'
      }
    }
  }
};