/**
 * Converts number to usd money object.
 *
 * @param value - A number to convert.
 *
 * @returns - Returns money object.
 *
 */
export function usd(value) {
  return {
    currency: 'USD',
    value: toMoneyValue(value)
  };
}
/**
 * Converts number to cad money object.
 *
 * @param value - A number to convert.
 *
 * @returns - Returns money object.
 *
 */

export function cad(value) {
  return {
    currency: 'CAD',
    value: toMoneyValue(value)
  };
}
/**
 * Creates money object.
 *
 * @param value - The value of the currency.
 * @param currency - The currency code.
 *
 * @returns - Returns money object.
 *
 */

export function toMoneyObj(value, currency) {
  return {
    currency: currency,
    value: toMoneyValue(value)
  };
}
/**
 * Formats money object as string representation of currency.
 *
 * @param money - The value of the currency.
 *
 * @returns - Returns string representation of currency.
 *
 */

export function formatMoney(money) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: money.currency
  }).format(money.value / 100);
}
/**
 * Converts number to a money value object.
 *
 * @param value - The value of the currency.
 *
 * @returns - Returns money value object.
 *
 */

export var toMoneyValue = function toMoneyValue(value) {
  if (value !== undefined && isNaN(value)) {
    return toMoneyValue(0);
  }

  if (!isMoneyValue(value)) {
    throw new Error('value must be an integer');
  }

  return value;
};
/**
 * Determine if a value is of Money type
 * @param val
 * @returns boolean
 */

export var isMoney = function isMoney(val) {
  return typeof val === 'object' && typeof val.currency === 'string' && Number.isInteger(val.value);
};
/**
 * Determine if value is a money value. This is a type guard
 * for a money value with branded integer type.
 *
 * @param value - The value of the currency.
 *
 * @returns - Returns if value is a money value.
 *
 */

export var isMoneyValue = function isMoneyValue(value) {
  return Number.isInteger(value) || value === Infinity;
};
/**
 * Returns currency formatted to USD
 * @todo We may want to improve this if we support new currencies in the future
 * @param amount number | Money
 * @returns string
 */

export var formatCurrency = function formatCurrency(amount) {
  var value = isMoney(amount) ? amount.value : amount;
  var result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value / 100);
  return result;
};
/**
 * Formats BPS to decimal APR
 * @param bps interest rate in BPS
 * @returns string
 */

export var aprFormatter = function aprFormatter(bps) {
  var result = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2
  }).format(bps / 10000);
  return result;
};