export var merchantDetailsProperties = {
  id: '79884213-58bd-4146-947e-f256f7afb79e',
  name: 'MemoryPedic',
  url: 'https://www.memorypedic.com/',
  merchantLogo: 'https://assets.platform.breadpayments.com/ads/merchant_logo/memorypedic/memorypedic_deda2f4c9d248ff520203eb91b5e8abf8d16ed23d65a821ddaf953d36273d6ef.svg',
  phoneNumber: '+18882289204',
  hipaaRestricted: false,
  posCheckoutCapability: null,
  borrowRatioBPS: 200,
  instorePickupAvailable: true,
  canViewCapacity: true
};