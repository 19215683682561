import { useMemo } from 'react';
import {
  Application,
  APPLICATION_STATUS,
  Money,
  Offer,
  PaymentType,
  PaymentTypes,
} from '@ads-bread/shared/bread/codecs';
import {
  toMoneyValue,
  validateOfferProductTypes,
  usd,
  APPLICATION_STATUS_CODES,
} from '@ads-bread/shared/bread/util';
import { useMerchantPaymentProducts } from '../../components/XPropsContext';
import { useBuyerMachine } from '../../components/BuyerMachineContext';
import { useApplicationMachine } from '../../components/ApplicationMachineContext';
import { getMaxCapacities } from '../../lib/capacity';
import { groupOffersByType } from '../../lib/offers';
import { useAddressSchema } from './useAddressSchema';

export interface UseDerivedAppState {
  hasOverflowCapacity: boolean;
  isSplitPaySelected: boolean;
  isDownPaymentApplication: boolean;
  isDownPaymentSelected: boolean;
  isPartiallyApproved: Record<PaymentType, boolean>;
  isContingentlyApproved: Record<PaymentType, boolean>;
  isCheckoutableApproved: Record<PaymentType, boolean>;
  anyPartiallyApproved: boolean;
  anyContingentlyApproved: boolean;
  downPaymentAmount: Money;
  maxCapacities: Record<PaymentType, Money>;
  overflowCapacity: Money;
  offersByType: Record<PaymentType, Offer[]>;
  orderTotal: Money;
  hasValidBuyerAddressAndNameData: boolean;
}
const PARTIAL_APPROVED_STATUSES = [
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial,
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent,
];

const CHECKOUTABLE_APPROVED_STATUSES = [
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedFull,
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial,
  APPLICATION_STATUS_CODES.SanctionsPassed,
];

type TopLevelStatusKey = keyof typeof APPLICATION_STATUS;

const CHECKOUTABLE_TOPLEVEL_STATUSES: TopLevelStatusKey[] = [
  APPLICATION_STATUS.APPROVED,
  APPLICATION_STATUS.CHECKOUT_PREPARED,
];

/**
 * Returns calculated state data based on current state of the application.
 */
export const useDerivedAppState = (): UseDerivedAppState => {
  const { billingAddress, buyerName } = useBuyerMachine();
  const { application, selectedOffer } = useApplicationMachine();
  const addressSchema = useAddressSchema();
  const { merchantPaymentProducts } = useMerchantPaymentProducts();

  const hasValidBillingAddress =
    billingAddress && addressSchema.isValidSync(billingAddress);

  const buyerNameFields = ['familyName', 'givenName'] as const;
  const hasValidBuyerName =
    buyerName && buyerNameFields.every((field) => buyerName[field].length > 2);

  const hasValidBuyerAddressAndNameData = Boolean(
    hasValidBillingAddress && hasValidBuyerName
  );

  const isSplitPaySelected = selectedOffer?.paymentProduct.type === 'SPLITPAY';

  const isDownPaymentApplication = isDownPaymentOnApplication(application);

  const hasOverflowCapacity = applicationHasOverflowCapacity(application);

  const isDownPaymentSelected = isDownPaymentOnSelectedOffer(
    application,
    selectedOffer
  );

  // Get the difference between application capacity and application order total
  const orderTotal = application?.order?.totalPrice || usd(0);
  const applicationCapacity = application?.capacity || usd(0);
  const overflowCapacity =
    application?.offers?.filter(
      (offer) => (offer.overflowCapacity?.value || 0) > 0
    )[0]?.overflowCapacity || usd(0);

  const downPaymentAmount: Money = {
    currency: orderTotal.currency,
    value: toMoneyValue(
      Math.min(orderTotal.value, overflowCapacity.value) -
        applicationCapacity.value
    ),
  };

  const offers = useMemo(
    () =>
      validateOfferProductTypes(
        application?.offers ?? [],
        merchantPaymentProducts
      ),
    [application?.offers, merchantPaymentProducts]
  );

  const approvedPaymentProductIDs = offers.map(
    (offer) => offer.paymentProduct.id
  );

  const approvedPaymentProducts = merchantPaymentProducts?.filter((pp) =>
    approvedPaymentProductIDs.includes(pp.paymentProduct.id)
  );

  // Calculate payments from Estimated spend if enabled
  const offersByType = useMemo(() => groupOffersByType(offers), [offers]);

  const maxCapacities = useMemo(
    () => getMaxCapacities(offersByType, approvedPaymentProducts),
    [offersByType, approvedPaymentProducts]
  );

  const isPartiallyApproved = Object.values(PaymentTypes).reduce(
    (accum, paymentType) => {
      accum[paymentType] = !!offersByType[paymentType]?.some((o) =>
        o.statusCodes?.some((sc) => PARTIAL_APPROVED_STATUSES.includes(sc))
      );
      return accum;
    },
    { INSTALLMENTS: false, SPLITPAY: false }
  );
  const isContingentlyApproved = Object.values(PaymentTypes).reduce(
    (accum, paymentType) => {
      accum[paymentType] = !!offersByType[paymentType]?.some((o) =>
        o.statusCodes?.some(
          (sc) =>
            sc === APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent
        )
      );

      return accum;
    },
    { INSTALLMENTS: false, SPLITPAY: false }
  );
  const topLevelApplicationStatus = application?.status;
  const topLevelStatusCheckoutable =
    topLevelApplicationStatus &&
    CHECKOUTABLE_TOPLEVEL_STATUSES.includes(topLevelApplicationStatus);
  const isCheckoutableApproved = Object.values(PaymentTypes).reduce(
    (accum, paymentType) => {
      accum[paymentType] = !!offersByType[paymentType]?.some((o) =>
        o.statusCodes?.some(
          (sc) =>
            topLevelStatusCheckoutable &&
            CHECKOUTABLE_APPROVED_STATUSES.includes(sc)
        )
      );
      return accum;
    },
    { INSTALLMENTS: false, SPLITPAY: false }
  );

  const anyPartiallyApproved =
    isPartiallyApproved['INSTALLMENTS'] || isPartiallyApproved['SPLITPAY'];

  const anyContingentlyApproved =
    isContingentlyApproved['INSTALLMENTS'] ||
    isContingentlyApproved['SPLITPAY'];

  return {
    hasOverflowCapacity,
    isSplitPaySelected,
    isDownPaymentApplication,
    isDownPaymentSelected,
    isPartiallyApproved,
    isContingentlyApproved,
    isCheckoutableApproved,
    anyPartiallyApproved,
    anyContingentlyApproved,
    downPaymentAmount,
    maxCapacities,
    overflowCapacity,
    offersByType,
    orderTotal,
    hasValidBuyerAddressAndNameData,
  };
};

function applicationHasOverflowCapacity(
  application: Application | null
): boolean {
  return (application?.offers || []).some(
    (offer) => (offer?.overflowCapacity?.value || 0) > 0
  );
}

/**
 * Returns true if the application is partially approved and
 * any offer on it contains down payment information.
 * @param application the application to check
 */
export function isDownPaymentOnApplication(
  application: Application | null
): boolean {
  return (
    !!application?.statusCodes?.includes(
      APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial
    ) &&
    !!(application?.offers || []).some(
      (offer) => (offer?.overflowCapacity?.value || 0) > 0
    )
  );
}

/**
 * Returns true if the application is partially approved and the
 * selected offer on the application contains down payment information.
 * @param application the application to check
 */
export function isDownPaymentOnSelectedOffer(
  application: Application | null,
  offer: Offer | null
): boolean {
  return (
    !!application?.statusCodes?.includes(
      APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial
    ) && (offer?.overflowCapacity?.value || 0) > 0
  );
}
