import { usd } from '@ads-bread/shared/bread/util';
export var merchantProgramValuesProperties = {
  id: 'b4fb7fa0-c011-4a2b-8fd1-31bba79b534b',
  merchantID: '0020fa5a-f107-4ba7-b7f0-d869ea0eed07',
  programID: 'b7beac2e-2714-4d1d-b110-72e405b41c34',
  defaultOTPMethod: 'SMS',
  fundingPaymentMethodID: 'ae3f3ace-d260-4ee5-9297-bedc9edbfe75',
  defaultCartAmount: usd(50000),
  policies: {
    virtualCardSettings: {
      enabled: false,
      provider: 'MARQUETA'
    }
  },
  capabilities: {
    downPayment: {
      installments: false
    }
  },
  derivedCapabilities: {
    downPayment: {
      installments: false
    }
  },
  createdAt: '2021-03-30T01:48:01.102806Z',
  deletedAt: null
};