import * as io from 'io-ts';
export var AuthPayload = io.intersection([io.type({
  merchantID: io.string,
  programID: io.string,
  referenceID: io.string,
  credentials: io.intersection([io.type({
    code: io.string
  }), io.partial({
    dob: io.string,
    iin: io.string,
    firstName: io.string,
    lastName: io.string
  })])
}), io.partial({
  buyerID: io.string
})]);
export var AuthResult = io.type({
  token: io.string,
  tokenExpiresAt: io.string,
  buyerID: io.string
});
export var LogoutResult = io.string;