import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { Dispatch, FunctionComponent, useEffect, useState } from 'react';
import * as yup from 'yup';
import { logger } from '../lib/logger';
import {
  UnauthorizedError,
  UnauthorizedFromAuthError,
} from '../lib/hooks/apiFetch';
import { Action } from './forms/SubmitError';
import { YupValidationSchemaProvider } from './YupValidationSchemaContext';
import { FormErrorTracker } from './APIFormErrorTracking';
import { useAuthentication } from './AuthenticationContext';
import { useRouteMachineService } from './RouteMachineService';

export type FormErrorContext = {
  reason: string;
  location?: Action;
  attemptsRemaining?: number;
};

export type SetFormError = Dispatch<FormErrorContext | null>;

type ExtendedOptions = {
  isFormDisabled: boolean;
  error: FormErrorContext | null;
  setFormError: SetFormError;
  isSubmitting: boolean;
};

export type FormChildrenProps<FV extends FormikValues> = Omit<
  FormikProps<FV>,
  'isSubmitting'
> &
  ExtendedOptions;

interface APIFormProps<FV extends FormikValues> {
  initialValues: FV;
  onSubmit: (
    values: FV,
    formikHelpers: FormikHelpers<FV>,
    setFormError: SetFormError
  ) => void | Promise<unknown>;
  validationSchema?: yup.ObjectSchema | yup.Lazy;
  children: (options: FormChildrenProps<FV>) => JSX.Element;
  other?: FV;
  autoSubmit?: boolean;
  id?: string;
}

interface HasMessage {
  message: string;
}

function hasMessage(val: unknown): val is HasMessage {
  return (val as HasMessage).message !== undefined;
}

export const APIForm = <FV extends FormikValues>({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  autoSubmit = false,
  id,
}: APIFormProps<FV>): JSX.Element => {
  const [formError, setFormError] = useState<FormErrorContext | null>(null);
  const { logoutUnauthorized } = useAuthentication();
  const { isTransitioning } = useRouteMachineService();

  const wrappedOnSubmit: (
    values: FV,
    formikHelpers: FormikHelpers<FV>
  ) => void | Promise<void> = async (values, formikHelpers) => {
    try {
      await onSubmit(values, formikHelpers, setFormError);
    } catch (err) {
      let reason: string;

      if (
        err instanceof UnauthorizedError &&
        !(err instanceof UnauthorizedFromAuthError)
      ) {
        logoutUnauthorized();
        return;
      } else if (hasMessage(err)) {
        reason = err.message;
      } else {
        reason = `encountered error: ${err}`;
      }
      logger.error({ err }, `Form submit handler errored:\n%s`, reason);
      setFormError({ reason });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={wrappedOnSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          isValid,
          isSubmitting: formikSubmitting,
          submitForm,
          resetForm,
          dirty,
        } = props;

        const hasNoFields = !Object.keys(values).length;
        const isSubmitting = formikSubmitting || isTransitioning;
        const isFormDisabled = hasNoFields ? false : !isValid || isSubmitting;

        return (
          <YupValidationSchemaProvider validationSchema={validationSchema}>
            {autoSubmit && (
              <AutoSubmit
                autoSubmit={autoSubmit}
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                isValid={isValid}
                resetForm={resetForm}
                dirty={dirty}
              />
            )}
            <Form id={id}>
              <FormErrorTracker<FV> formProps={props}>
                {typeof children === 'function'
                  ? children({
                      ...props,
                      isFormDisabled,
                      error: formError,
                      setFormError,
                      isSubmitting,
                    })
                  : null}
              </FormErrorTracker>
            </Form>
          </YupValidationSchemaProvider>
        );
      }}
    </Formik>
  );
};

interface AutoSubmitProps {
  submitForm: () => Promise<void>;
  resetForm: () => void;
  isValid: boolean;
  isSubmitting: boolean;
  autoSubmit: boolean;
  dirty: boolean;
}

const AutoSubmit: FunctionComponent<AutoSubmitProps> = ({
  submitForm,
  isValid,
  isSubmitting,
  autoSubmit,
  resetForm,
  dirty,
}) => {
  const [working, setWorking] = useState(false);
  useEffect(() => {
    const autoSubmitEffect = async () => {
      setWorking(true);
      await submitForm();
      resetForm();
      setWorking(false);
    };
    if (autoSubmit && dirty && isValid && !isSubmitting && !working) {
      autoSubmitEffect();
    }
  }, [autoSubmit, isValid, dirty, isSubmitting, working]);
  return null;
};
