import { fromUnixTime, differenceInSeconds } from 'date-fns';
import { decode } from 'jsonwebtoken';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { isBreadJWT } from '../bread-jwt';
interface AuthTokenState {
  authToken: string;
  setAuthToken: (authToken: string) => void;
}

interface UseAuthTokenStore extends AuthTokenState {
  isAuthTokenActive: () => boolean;
}

export const useAuthTokenStore = (): UseAuthTokenStore => {
  const getStore = create<AuthTokenState>()(
    persist(
      (set) => ({
        authToken: '',
        setAuthToken: (authToken: string) => set({ authToken }),
      }),
      { name: 'auth-token' }
    )
  );
  const { authToken, setAuthToken } = getStore();

  const isAuthTokenActive = (): boolean => {
    if (!authToken) {
      return false;
    }
    const decodedJwt = decode(authToken);
    if (isBreadJWT(decodedJwt)) {
      const expiresAt = fromUnixTime(decodedJwt.exp);
      const now = new Date();
      const timeToExpiration = differenceInSeconds(expiresAt, now);
      if (timeToExpiration >= 0) {
        return true;
      }
    }
    return false;
  };

  return { authToken, setAuthToken, isAuthTokenActive };
};
