import { FunctionComponent, createContext, useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { Routes, routingMachine } from '../lib/machines/ecommRoutingMachine';
import { useHasReviewedApplication } from '../lib/hooks/useHasReviewedApplication';
import { useDerivedAppState } from '../lib/hooks/useDerivedAppState';
import { useBuyerMachine } from './BuyerMachineContext';
import { useAuthentication } from './AuthenticationContext';
import { useApplicationMachine } from './ApplicationMachineContext';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
  getDefaultApplicationErrorRouteGuards,
  getDefaultBuyerErrorRouteGuards,
  getLoadingIndicatorActions,
  getRoutePathConditionGuards,
  useSyncRouterStateEventEmitter,
  useBuyerCompleteApplicationApprovedEventEmitter,
  useApplicationMachineStateEventEmitter,
  useBuyerMachineStateEventEmitter,
  useAuthenticationMachineStateEventEmitter,
  useHandleStateRouteTransition,
  useRouteTransitionActions,
  useLogUnhandledRouteEvent,
} from './RouteMachineService';
import { useLoadingManager } from './LoadingManager';
import { useFeatureFlags } from './FeatureFlagsContext';

type EcommRoutingMachineService = InterpreterFrom<typeof routingMachine>;

export const EcommRoutingMachineContext =
  createContext<RouteMachineContextProps<EcommRoutingMachineService | null> | null>(
    null
  );

/**
 * Provider to support Ecomm routing
 * @param React.Children
 * @returns FunctionComponent<RouteMachineServiceProps>
 */
export const EcommRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const { state: authState } = useAuthentication();
    const { state: buyerState } = useBuyerMachine();
    const {
      idVerification: { backImageData },
    } = useApplicationMachine();
    const { hasReviewedApplication } = useHasReviewedApplication();
    const { hasValidBuyerAddressAndNameData } = useDerivedAppState();
    const { enableAlloyJourney } = useFeatureFlags();
    const { addLoader, removeLoader } = useLoadingManager();
    const logUnhandledRouteEvent = useLogUnhandledRouteEvent();

    const routingMachineService = useInterpret(routingMachine, {
      guards: {
        isIDVerificationAlloy: () => enableAlloyJourney,
        hasReviewedApplication: () => {
          if (authState.matches('authenticated.anonymous')) {
            return true;
          }
          return hasReviewedApplication;
        },
        isBuyerReadyComplete: () => {
          return buyerState.matches('ready.complete');
        },
        isUnauthenticatedInitial: () => {
          return authState.matches('unAuthenticated.initial');
        },
        isAuthenticatedCompleteMismatchedBuyerPII: () => {
          return authState.matches('authenticated.complete.mismatchedBuyerPII');
        },
        hasValidAddressAndName: () => {
          return hasValidBuyerAddressAndNameData;
        },
        hasBackImageData: () => {
          return !!backImageData;
        },
        isIntroEmailAB: () => {
          // TODO - Add check on useFeatureFlags when Intro page AB phase 2 begins
          return false;
        },
        // Route path condition guards
        ...getRoutePathConditionGuards(),
        // Application Error condition routing checks
        ...getDefaultApplicationErrorRouteGuards(),
        // Buyer Error condition routing checks
        ...getDefaultBuyerErrorRouteGuards(),
      },
      actions: {
        // TODO: Remove - Added for debugging purposes.
        logUnhandledLoginEligibilityRouteEvent: (_, e) => {
          logUnhandledRouteEvent(Routes.LoginEligibility, e);
        },
        ...getLoadingIndicatorActions({ addLoader, removeLoader }),
      },
    });

    // Handle state route transitions
    const { isTransitioning } = useHandleStateRouteTransition(
      routingMachineService
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerCompleteApplicationApprovedEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <EcommRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </EcommRoutingMachineContext.Provider>
    );
  };
