import { logger } from '../logger';
interface GeocodePlaceIDParams {
  kind: 'PLACE_ID';
  googleApiKey: string;
  placeID: string;
}

interface GeocodePostalCodeParams {
  kind: 'POSTAL_CODE';
  googleApiKey: string;
  postalCode: string;
  country: string;
}

type GeocodeParams = GeocodePlaceIDParams | GeocodePostalCodeParams;

type RequestParms = {
  key: string;
  place_id?: string;
  components?: string;
};

export async function geocode(
  params: GeocodeParams
): Promise<GeocodePlace | undefined> {
  const apiURLBase = 'https://maps.googleapis.com/maps/api/geocode/json';
  const requestParams: RequestParms = { key: params.googleApiKey };
  if (params.kind === 'PLACE_ID') {
    requestParams.place_id = params.placeID;
  } else if (params.kind === 'POSTAL_CODE') {
    requestParams.components = `postal_code:${params.postalCode}|country:${params.country}`;
  }

  const query = new URLSearchParams({
    language: 'en',
    ...requestParams,
  }).toString();
  const apiUrl = `${apiURLBase}?${query}`;

  try {
    const response = await fetch(apiUrl);
    const json = await response.json();

    if (!['OK', 'ZERO_RESULTS'].includes(json.status)) {
      throw new Error('Error connecting to API');
    }

    const result = json.results[0];

    return result && parsePlace(result.address_components);
  } catch (e) {
    const err = e instanceof Error ? e : { message: e };
    logger.error({ err }, 'Error geocoding postal code with google maps api');
    throw e;
  }
}

export type GoogleGeocodeResult = {
  long_name: string;
  short_name: string;
  types: string[];
}[];

export type GeocodePlace = {
  [key: string]: string;
};

const addressMapper: Record<string, string> = {
  street_number: 'number',
  route: 'route',
  postal_code: 'postalCode',
  locality: 'locality',
  administrative_area_level_1: 'region',
  administrative_area_level_2: 'county',
  country: 'country',
};

export const parsePlace = function (
  googlePlace?: GoogleGeocodeResult
): GeocodePlace {
  if (!googlePlace || googlePlace.length === 0) {
    throw new Error('No address properties to parse');
  }

  const place: GeocodePlace = googlePlace.reduce<Record<string, string>>(
    (placeSoFar, addressComponent) => {
      addressComponent.types.forEach((type) => {
        const addressMapperType = addressMapper[type];
        if (addressMapperType) {
          placeSoFar[addressMapperType] = addressComponent.short_name;
        }
      });
      return placeSoFar;
    },
    {}
  );

  if (place.route) {
    place.address = (place.number ? place.number + ' ' : '') + place.route;
  }
  if (!place.locality) {
    place.locality = place.county ?? '';
  }

  return place;
};
