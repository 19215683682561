import React from 'react';
import { SVGIcon } from './svgHelpers';

export const Lock: SVGIcon = (props) => (
  <svg
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 5.33332H9.33334V3.99999C9.33334 2.15999 7.84 0.666656 6 0.666656C4.16 0.666656 2.66667 2.15999 2.66667 3.99999V5.33332H2.00001C1.26667 5.33332 0.666672 5.93332 0.666672 6.66666V13.3333C0.666672 14.0667 1.26667 14.6667 2.00001 14.6667H10C10.7333 14.6667 11.3333 14.0667 11.3333 13.3333V6.66666C11.3333 5.93332 10.7333 5.33332 10 5.33332ZM4 3.99999C4 2.89332 4.89334 1.99999 6 1.99999C7.10667 1.99999 8 2.89332 8 3.99999V5.33332H4V3.99999ZM10 13.3333H2.00001V6.66666H10V13.3333ZM6 11.3333C6.73334 11.3333 7.33334 10.7333 7.33334 9.99999C7.33334 9.26666 6.73334 8.66666 6 8.66666C5.26667 8.66666 4.66667 9.26666 4.66667 9.99999C4.66667 10.7333 5.26667 11.3333 6 11.3333Z" />
  </svg>
);
