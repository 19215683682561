import { FunctionComponent, createContext, useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { routingMachine } from '../lib/machines/rbcRoutingMachine';
import { useAuthentication } from './AuthenticationContext';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
  getDefaultApplicationErrorRouteGuards,
  getDefaultBuyerErrorRouteGuards,
  getLoadingIndicatorActions,
  getRoutePathConditionGuards,
  useSyncRouterStateEventEmitter,
  useBuyerCompleteApplicationApprovedEventEmitter,
  useApplicationMachineStateEventEmitter,
  useBuyerMachineStateEventEmitter,
  useAuthenticationMachineStateEventEmitter,
  useHandleStateRouteTransition,
  useRouteTransitionActions,
} from './RouteMachineService';
import { useLoadingManager } from './LoadingManager';

type RBCRoutingMachineService = InterpreterFrom<typeof routingMachine>;

export const RBCRoutingMachineContext =
  createContext<RouteMachineContextProps<RBCRoutingMachineService | null> | null>(
    null
  );

export const RBCRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const { state: authState } = useAuthentication();
    const { addLoader, removeLoader } = useLoadingManager();

    const routingMachineService = useInterpret(routingMachine, {
      guards: {
        isUnauthenticatedInitial: () => {
          return authState.matches('unAuthenticated.initial');
        },
        isAuthenticatedCompleteMismatchedBuyerPII: () => {
          return authState.matches('authenticated.complete.mismatchedBuyerPII');
        },
        // Route path condition guards
        ...getRoutePathConditionGuards(),
        // Application Error condition routing checks
        ...getDefaultApplicationErrorRouteGuards(),
        // Buyer Error condition routing checks
        ...getDefaultBuyerErrorRouteGuards(),
      },
      actions: {
        ...getLoadingIndicatorActions({ addLoader, removeLoader }),
      },
    });

    // Handle state route transitions
    const { isTransitioning } = useHandleStateRouteTransition(
      routingMachineService
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerCompleteApplicationApprovedEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <RBCRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </RBCRoutingMachineContext.Provider>
    );
  };
