import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FCWithChildren } from '../../lib/types';
import { TextLink } from '../TextLink';
import { RbcLogo } from '../svgs/RbcLogo';
import { useLocale } from '../IntlContext';
import { useAppConfig } from '../AppConfigContext';
import { Encrypted } from './Encrypted';

export const RBCFooter: FunctionComponent = () => {
  return (
    <div className="mb-8" data-testid="footer">
      <div className="text-sm flex items-center space-x-1 mt-10">
        <Encrypted />
        <PoweredBy />
      </div>
      <div>
        <FooterLinks />
        <Copyright />
      </div>
    </div>
  );
};

const PoweredBy: FunctionComponent = () => {
  return (
    <div className="flex flex-1 justify-end align-middle items-center text-base text-theme-primary">
      <FormattedMessage
        defaultMessage="Powered by"
        description="Footer RBC branding pre logo"
      />
      <RbcLogo className="w-6 ml-2" />
    </div>
  );
};

const Copyright: FunctionComponent = () => {
  return (
    <div className="mt-2 text-xs text-base text-theme-primary">
      <FormattedMessage
        defaultMessage="PayPlan by RBC financing is provided by RBC Ampli Inc."
        description="Footer RBC copyright text"
      />
    </div>
  );
};

const FooterLink: FCWithChildren<{ href: string; testID: string }> = ({
  href,
  testID,
  children,
}) => (
  <TextLink
    external
    href={href}
    testID={testID}
    className="underline text-theme-primary"
  >
    {children}
  </TextLink>
);

const FooterLinks: FunctionComponent = () => {
  const { locale } = useLocale();
  const { linkUrls } = useAppConfig();

  return (
    <ul className="flex space-x-4 mt-6 text-xs">
      <li>
        <FooterLink
          href={
            (linkUrls && linkUrls['legal'] && linkUrls['legal'][locale]) ?? '#'
          }
          testID="footer-legal"
        >
          <FormattedMessage
            defaultMessage="Legal"
            description="RBC footer 'Legal' link"
          />
        </FooterLink>
      </li>
      <li>
        <FooterLink
          href={
            (linkUrls && linkUrls['privacy'] && linkUrls['privacy'][locale]) ??
            '#'
          }
          testID="footer-privacy-and-security"
        >
          <FormattedMessage
            defaultMessage="Privacy & Security"
            description="RBC footer 'Privacy & Security' link"
          />
        </FooterLink>
      </li>
      <li>
        <FooterLink
          href={
            (linkUrls &&
              linkUrls['accessibility'] &&
              linkUrls['accessibility'][locale]) ??
            '#'
          }
          testID="footer-accessibility"
        >
          <FormattedMessage
            defaultMessage="Accessibility"
            description="RBC footer 'Accessibility' link"
          />
        </FooterLink>
      </li>
    </ul>
  );
};
