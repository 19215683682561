import _defineProperty from "/opt/bread/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { PaymentTypes } from '@ads-bread/shared/bread/codecs';
import { usd } from './money';
export var merchantPaymentProductProperties = {
  paymentProduct: {
    id: 'fb3e24a9-4030-4ccf-a209-93de0a4358a4',
    programID: 'b7beac2e-2714-4d1d-b110-72e405b41c34',
    name: 'Positive APR Payment Product',
    type: PaymentTypes.INSTALLMENTS,
    description: 'This is a description of a product.',
    numPayments: 12,
    paymentFrequency: 'P1M',
    minInterestRateBPS: 0,
    maxInterestRateBPS: 2999,
    paymentMethodTypes: ['ACH', 'DEBIT'],
    autopayRequired: false,
    legalAgreements: {},
    policies: {},
    workflowIDs: {},
    requiredDownPaymentBPS: 0,
    createdAt: '2021-02-27T00:36:59.6195794Z',
    deletedAt: null
  },
  merchantValues: {
    maxCartSize: usd(2000000),
    minCartSize: usd(30000),
    preauthAmount: null
  }
};
export var paymentProductID = {
  INSTALLMENTS: 'fb3e24a9-4030-4ccf-a209-93de0a4358a4',
  SPLITPAY: '7d80c76e-b0aa-41c3-b3d8-f54959ee67da'
};
/**
 * Creates installment payment product.
 *
 * @param paymentProduct - A payment product.
 * @param merchantValues - Merchant program values.
 *
 * @returns - Returns merchant payment product.
 *
 */

export function createInstallmentsPaymentProduct() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      paymentProduct = _ref.paymentProduct,
      merchantValues = _ref.merchantValues;

  return _objectSpread(_objectSpread({}, merchantPaymentProductProperties), {}, {
    paymentProduct: _objectSpread(_objectSpread({}, merchantPaymentProductProperties.paymentProduct), {}, {
      id: paymentProductID.INSTALLMENTS,
      type: 'INSTALLMENTS'
    }, paymentProduct),
    merchantValues: _objectSpread(_objectSpread({}, merchantPaymentProductProperties.merchantValues), {}, {
      minCartSize: usd(30000)
    }, merchantValues)
  });
}
/**
 * Creates split pay payment product.
 *
 * @param paymentProduct - A payment product.
 * @param merchantValues - Merchant program values.
 *
 * @returns - Returns merchant payment product.
 *
 */

export function createSplitPayPaymentProduct() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      paymentProduct = _ref2.paymentProduct,
      merchantValues = _ref2.merchantValues;

  return _objectSpread(_objectSpread({}, merchantPaymentProductProperties), {}, {
    paymentProduct: _objectSpread(_objectSpread({}, merchantPaymentProductProperties.paymentProduct), {}, {
      id: paymentProductID.SPLITPAY,
      type: 'SPLITPAY',
      maxInterestRateBPS: 0,
      name: '0% APR Payment Product',
      numPayments: 4,
      paymentFrequency: 'P2W',
      paymentMethodTypes: ['DEBIT', 'CREDIT'],
      requiredDownPaymentBPS: 2500,
      autopayRequired: true
    }, paymentProduct),
    merchantValues: _objectSpread(_objectSpread({
      minCartSize: usd(3000),
      maxCartSize: usd(150000)
    }, merchantValues), {}, {
      preauthAmount: 3000
    })
  });
}