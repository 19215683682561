import {
  Application,
  Offer,
  Order,
  PaymentTypes,
} from '@ads-bread/shared/bread/codecs';
import { CheckoutDetails, EventProperties, Values } from '.';

export function flattenDetailProperties(
  options: CheckoutDetails
): EventProperties {
  const { promoOffers, analytics, merchantPaymentProducts, isInStore } =
    options;

  const checkoutProperties: CheckoutProperties = {
    tenant_id: options.tenantID,
    program_id: options.programID,
    location_id: options.locationID,
    program_name: options.programName,
    is_checkout_enabled: options.allowCheckout,
    merchant_id: options.merchantID,
    merchant_name: options.merchantName,
    offered_installment: Boolean(
      !isInStore
        ? promoOffers?.some((offer) => offer.type === PaymentTypes.INSTALLMENTS)
        : merchantPaymentProducts?.some(
            (mpp) => mpp.paymentProduct.type === 'INSTALLMENTS'
          )
    ),
    offered_splitPay: Boolean(
      !isInStore
        ? promoOffers?.some((offer) => offer.type === PaymentTypes.SPLITPAY)
        : merchantPaymentProducts?.some(
            (mpp) => mpp.paymentProduct.type === 'SPLITPAY'
          )
    ),
    experience_entrypoint: analytics.experienceEntrypoint,
    location_type: analytics.locationType,
    embedded: options.isEmbedded,
    is_in_store: options.isInStore,
    is_carts: options.isCarts,
    is_virtual_card: options.isVirtualCard,
    is_merchant_tap_to_pay_capable: options.isMerchantTapToPayCapable,
    is_merchant_key_entry_capable: options.isMerchantKeyEntryCapable,
    is_down_payment_enabled: options.isDownPaymentEnabled,
  };
  return checkoutProperties;
}

export function flattenOrderProperties(
  order: Order,
  isDefaultOrder: boolean
): EventProperties {
  const orderProperties = flattenOrder(order, isDefaultOrder);
  const orderItemsProperties = flattenOrderItems(order);

  return {
    ...orderProperties,
    ...orderItemsProperties,
  };
}

export function flattenOrder(
  order: Order,
  isDefaultOrder: boolean
): OrderProperties {
  const cart_amount = isDefaultOrder ? undefined : order.totalPrice.value;

  return {
    is_empty_cart: isDefaultOrder,
    subTotal: order.subTotal.value,
    subTotal_currency: order.subTotal.currency,
    totalTax: order.totalTax.value,
    totalTax_currency: order.totalTax.currency,
    totalShipping: order.totalShipping.value,
    totalShipping_currency: order.totalShipping.currency,
    totalDiscounts: order.totalDiscounts.value,
    totalDiscounts_currency: order.totalDiscounts.currency,
    totalPrice: order.totalPrice.value,
    totalPrice_currency: order.totalPrice.currency,
    count_order_items: order.items?.length || 0,
    cart_amount,
  };
}

function flattenOrderItems(order: Order): Record<string, Values> {
  return (
    order.items?.reduce((info, item, index) => {
      return {
        ...info,
        [`order_orderItem_${index}_name`]: item.name,
        [`order_orderItem_${index}_sku`]: item.sku,
        [`order_orderItem_${index}_category`]: item.category,
        [`order_orderItem_${index}_unitPrice`]: item.unitPrice.value,
        [`order_orderItem_${index}_currency`]: item.unitPrice.currency,
        [`order_orderItem_${index}_unitTax`]: item.unitTax.value,
        [`order_orderItem_${index}_brand`]: '',
        [`order_orderItem_${index}_quantity`]: item.quantity,
        [`order_orderItem_${index}_shippingProvider`]: item.shippingProvider,
      };
    }, {}) || {}
  );
}

type OrderProperties = {
  cart_amount?: number;
  is_empty_cart: boolean;
  subTotal: number;
  subTotal_currency: string;
  totalTax: number;
  totalTax_currency: string;
  totalShipping: number;
  totalShipping_currency: string;
  totalDiscounts: number;
  totalDiscounts_currency: string;
  totalPrice: number;
  totalPrice_currency: string;
  count_order_items: number;
};

type CheckoutProperties = {
  tenant_id: string;
  program_id: string;
  location_id: string;
  program_name: string;
  is_checkout_enabled: boolean;
  merchant_id: string;
  merchant_name: string;
  offered_splitPay: boolean;
  offered_installment: boolean;
  experience_entrypoint: string;
  location_type: string;
  embedded: boolean;
  is_in_store: boolean;
  is_carts: boolean;
  is_virtual_card: boolean;
  is_merchant_tap_to_pay_capable: boolean;
  is_merchant_key_entry_capable: boolean;
  is_down_payment_enabled: boolean;
};

function flattenOffers(offers: Offer[]) {
  return offers.reduce((details, offer, index) => {
    return {
      ...details,
      [`payment_agreement_${index}_id`]: offer.paymentAgreement.id,
      [`payment_agreement_${index}_apr`]: offer.paymentAgreement.terms.rate,
      [`payment_agreement_${index}_term_length`]:
        offer.paymentAgreement.terms.length,
      [`payment_agreement_${index}_term_interval`]:
        offer.paymentAgreement.terms.interval,
    };
  }, {});
}

function creditInfo(application: Application) {
  const capacityValue = application.capacity.value;
  const orderTotalValue = application.order.totalPrice.value;
  const is_fully_covered = capacityValue >= orderTotalValue;
  const overflow_amount = is_fully_covered
    ? undefined
    : orderTotalValue - capacityValue;
  const remaining_capacity = is_fully_covered
    ? capacityValue - orderTotalValue
    : undefined;
  return {
    credit_limit_extended_value: capacityValue,
    credit_limit_extended_currency: application.capacity.currency,
    is_fully_covered,
    overflow_amount,
    remaining_capacity,
  };
}

export function flattenApplicationDetails(
  application: Application
): EventProperties {
  const offers = application.offers || [];
  const offerDetails = flattenOffers(offers);

  const creditInfoDetails = creditInfo(application);
  return {
    application_id: application.id,
    application_status: application.status,
    transaction_id: application.transactionID || '',
    count_payment_agreements_offered: offers.filter(
      (o) => o.paymentAgreement?.status === 'OFFERED'
    ).length,
    statusCodes: `${application.statusCodes || ''}`,
    ...creditInfoDetails,
    ...offerDetails,
  };
}

export function flattenSelectedOffer(offer: Offer): EventProperties {
  return {
    selected_payment_agreement_id: offer.paymentAgreement.id,
    selected_payment_agreement_pmt: offer.paymentProduct.type,
  };
}
