import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCloseModal } from '../lib/hooks/useCloseModal';
import { useKeyPress } from '../lib/hooks/useKeyPress';
import { FCWithChildren } from '../lib/types';
import { useTrapFocus } from '../lib/hooks/useTrapFocus';
import { useSDKModalOptions, useSDKOptions } from './XPropsContext';

const defaultModalContext = {
  setAllowCloseModal: (_: boolean) => {
    /* noOp */
  },
};
type ModalContext = typeof defaultModalContext;

const ModalContext = createContext<ModalContext>(defaultModalContext);

export const Modal: FCWithChildren = ({ children }) => {
  const { ref: modalRef, onClose: trapFocusOnClose } =
    useTrapFocus<HTMLDivElement>();
  const { modal } = useSDKModalOptions();
  const { hideModal } = useCloseModal();
  const { isEmbedded } = useSDKOptions();
  const [allowCloseModal, setAllowCloseModal] = useState(true);
  const [ariaLabelledBy, setAriaLabelledBy] = useState('modal-headline');
  const hideAction = () => {
    allowCloseModal && hideModal && hideModal();
  };
  const mouseDownTarget = useRef<EventTarget | null>(null);

  useKeyPress('Escape', hideAction);

  useEffect(() => {
    modalRef?.current?.focus();
    return () => {
      trapFocusOnClose();
    };
  }, []);

  useEffect(() => {
    const headingElement = document.getElementById('modal-headline');

    if (headingElement) {
      const headingText = headingElement.textContent?.trim();
      if (headingText) {
        setAriaLabelledBy(`modal-headline ${headingText}`);
      }
    } else {
      setAriaLabelledBy('modal-headline');
    }
  }, [children]);

  if (!modal) {
    return (
      <div
        data-testid="no-modal-wrapper"
        className="p-4 lg:p-12 text-blackberry max-w-modal mx-auto relative overflow-x-hidden min-h-screen h-2"
      >
        <div className="flex flex-col min-h-full justify-between">
          <ModalContext.Provider value={{ setAllowCloseModal }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex ${
        isEmbedded ? 'max-w-modal mx-auto min-h-modal' : 'min-h-screen'
      } justify-center text-center sm:block relative overflow-x-hidden`}
      onClick={(e) => {
        if (mouseDownTarget.current === e.currentTarget && allowCloseModal) {
          hideModal?.();
        }
      }}
      onMouseDown={(event) => {
        mouseDownTarget.current = event.target;
      }}
      data-testid="modal-wrapper"
    >
      <div
        className={`inline-block ${
          isEmbedded ? 'min-h-fit' : 'overflow-y-scroll sm:my-8'
        } bg-white sm:max-w-modal sm:rounded-sm shadow-xl transform transition-all align-bottom text-left sm:align-middle w-full relative`}
        id="bread-modal-root"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabelledBy}
        ref={modalRef}
      >
        <div
          className="p-4 sm:p-12 text-blackberry w-full sm:min-h-modal min-h-screen"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalContext.Provider value={{ setAllowCloseModal }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

export const useModal = (): ModalContext => useContext(ModalContext);
