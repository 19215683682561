import React, { useContext, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Locale, isLocale } from '@ads-bread/shared/bread/codecs';
import {
  getItemFromStorage,
  setItemInStorage,
  StorageKeys,
} from '@ads-bread/shared/bread/util';
import messagesEn from '../compiled-lang/en.json';
import messagesFr from '../compiled-lang/fr.json';
import { noOp } from '../lib/noOp';
import { FCWithChildren } from '../lib/types';

import { useAppConfig } from './AppConfigContext';

const getMessages = (locale: Locale) => {
  switch (locale) {
    case 'fr':
      return messagesFr;
    default:
      return messagesEn;
  }
};

export type IntlProviderContextType = {
  setLocale: (locale: Locale) => void;
  setPreviousLocale: (locale: Locale) => void;
  shouldSetPreviousLocale: (revertLocale: boolean) => void;
  locale: Locale;
  prevLocale: Locale;
  revertLocale: boolean;
};

export const IntlProviderContext = React.createContext<IntlProviderContextType>(
  {
    setLocale: noOp,
    setPreviousLocale: noOp,
    shouldSetPreviousLocale: noOp,
    locale: 'en',
    prevLocale: 'en',
    revertLocale: false,
  }
);

// Wrap in try/catch in case user has disabled cookies, which causes an error
// to be thrown
function getStoredLocalePreference(): Locale | null {
  const locale = getItemFromStorage<string>(StorageKeys.LOCALE_KEY);
  return isLocale(locale) ? locale : null;
}

export const IntlProvider: FCWithChildren = ({ children }) => {
  const { locales } = useAppConfig();
  const multipleLocales = locales.length > 1;
  const storageLocale = multipleLocales ? getStoredLocalePreference() : null;
  const navigatorLocale =
    typeof navigator !== 'undefined'
      ? navigator?.language?.split('-')[0]
      : undefined;
  const defaultLocale = storageLocale || navigatorLocale;
  const initialLocale =
    isLocale(defaultLocale) && locales.includes(defaultLocale)
      ? defaultLocale
      : 'en';

  const [locale, setLocale] = useState<Locale>(initialLocale);
  const [prevLocale, setPreviousLocale] = useState<Locale>(initialLocale);
  const [revertLocale, shouldSetPreviousLocale] = useState<boolean>(false);
  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);

  const handleLocale = (l: Locale) => {
    setItemInStorage<Locale>(StorageKeys.LOCALE_KEY, l);
    setLocale(l);
  };

  const handlePreviousLocale = (l: Locale) => {
    setPreviousLocale(l);
  };

  const handleShouldSetPreviousLocale = (newRevertLocale: boolean) => {
    shouldSetPreviousLocale(newRevertLocale);
  };

  return (
    <IntlProviderContext.Provider
      value={{
        setLocale: handleLocale,
        shouldSetPreviousLocale: handleShouldSetPreviousLocale,
        setPreviousLocale: handlePreviousLocale,
        locale,
        prevLocale,
        revertLocale,
      }}
    >
      <ReactIntlProvider
        locale={locale}
        messages={getMessages(locale)}
        defaultRichTextElements={{
          b: (s) => <b>{s}</b>,
          sup: (s) => <sup>{s}</sup>,
        }}
      >
        {children}
      </ReactIntlProvider>
    </IntlProviderContext.Provider>
  );
};

export const useLocale = (): IntlProviderContextType => {
  return useContext(IntlProviderContext);
};
