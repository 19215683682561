import * as io from 'io-ts';
import { Address } from './Buyer';
export var Location = io.type({
  id: io.string,
  name: io.string,
  merchantID: io.string,
  externalReferenceID: io.string,
  partnerReferenceID: io.string,
  enabled: io["boolean"],
  address: Address
});
export var LocationResponse = io.type({
  location: Location
});