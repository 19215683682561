import React, { FunctionComponent } from 'react';
import { useAppConfig } from '../AppConfigContext';
import { RBCFooter } from './RBC';
import { ADSFooter } from './ADS';

const FOOTER_COMPONENTS = {
  ADSFooter,
  RBCFooter,
};

export const Footer: FunctionComponent = () => {
  const { footerComponent } = useAppConfig();
  const FooterComponent = FOOTER_COMPONENTS[footerComponent];

  return <FooterComponent />;
};
