import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkToFAQs } from '../LinkToFAQs';
import { useNamespace } from '../../lib/hooks/useNamespace';
import { INSTORE } from '../../lib/namespace';
import { PageNames, useTrackPageView } from '../../lib/hooks/useTrackPageView';
import { useAppConfig } from '../AppConfigContext';
import { EcommFAQs } from './EcommFAQs';
import { InStoreFAQs } from './InStoreFAQs';

type Props = {
  onBack: () => void;
  className?: string;
};

interface GetFaqLinkOptions {
  faqUrl: string;
  showSplitPay: boolean;
  showInstallments: boolean;
}

function getFaqLink({
  faqUrl,
  showSplitPay,
  showInstallments,
}: GetFaqLinkOptions) {
  if (showSplitPay && !showInstallments) {
    return faqUrl + '?topic=splitpay';
  } else if (showInstallments && !showSplitPay) {
    return faqUrl + '?topic=installment-loans';
  }
  return faqUrl;
}

export const FAQs: FunctionComponent<Props> = ({ onBack }) => {
  useTrackPageView({
    pageName: PageNames.FAQ,
  });
  const [isShowing, setIsShowing] = useState(true);
  const namespace = useNamespace();
  switch (namespace) {
    case INSTORE:
      return (
        <InStoreFAQs
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          onBack={onBack}
        />
      );
    default:
      return (
        <EcommFAQs
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          onBack={onBack}
        />
      );
  }
};

export const LearnMore: FunctionComponent<{
  showSplitPay: boolean;
  showInstallments: boolean;
}> = ({ showSplitPay, showInstallments }) => {
  const { faqUrl } = useAppConfig();
  return (
    <div className="pt-2 pb-9 text-sm">
      <p>
        <FormattedMessage
          defaultMessage="Learn more at <link>BreadPayments.com</link>"
          description="FAQ Screen FAQ link"
          values={{
            link: (text: string) => (
              <LinkToFAQs
                url={getFaqLink({ faqUrl, showSplitPay, showInstallments })}
                text={text}
                testID="external-faq"
              />
            ),
          }}
        />
      </p>
    </div>
  );
};
