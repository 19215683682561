import React, {
  Component,
  ReactNode,
  ErrorInfo,
  CSSProperties,
  FunctionComponent,
} from 'react';
import { logger } from '../lib/logger';
import { Heading } from './Heading';
import { P } from './P';
import { SpeechBubble } from './svgs/SpeechBubble';

interface GlobalErrorBoundaryProps {
  children: ReactNode;
}

interface GlobalErrorBoundaryState {
  hasError: boolean;
  error: string;
}

export class GlobalErrorBoundary extends Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(err: Error): GlobalErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logger.error({ error, errorInfo }, 'Global Error Boundary reached!');
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FallbackErrorScreen />;
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;

/**
 * Inline styled fallback when the application can not render with context due
 * to catastrophic or uncaught errors and promise rejections.
 * @returns JSX.Element
 */
export const FallbackErrorScreen: FunctionComponent = () => {
  return (
    <div
      className="flex justify-center items-center h-screen"
      style={containerStyle}
    >
      <div className="p-8 bg-white">
        <Heading>{'Uh oh...'}</Heading>

        <P>{'An unexpected error occurred. Please try again later.'}</P>

        <div className="flex justify-center pt-4 pb-8">
          <SpeechBubble
            aria-hidden="true"
            className="text-theme-primary"
            style={bubbleStyle}
          />
        </div>
      </div>
    </div>
  );
};

const containerStyle: CSSProperties = {
  fontFamily: 'Nunito Sans, Arial, "Helvetica, sans-serif',
};

const bubbleStyle: CSSProperties = {
  fill: '#1C8195',
};
