import React, { FunctionComponent } from 'react';
import { classNames } from '../lib/classNames';
import { FCWithChildren } from '../lib/types';
import { ExclamationMarkTriangle } from './svgs/ExclamationMarkTriangle';
import { Info } from './svgs/Info';
import { ExclamationMark } from './svgs/ExclamationMark';

export type Type = 'Error' | 'Warning' | 'Info';

export interface CallOutProps {
  Title?: FunctionComponent;
  type?: Type;
  dataTestId?: string;
}
const CallOut: FCWithChildren<CallOutProps> = ({
  children,
  Title,
  type = 'Info',
  dataTestId = 'call-out',
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        'flex items-start p-3 rounded-sm mb-4 bg-opacity-10 border border-opacity-70',
        type === 'Info' && 'bg-theme-primary border-theme-primary',
        type === 'Error' && 'bg-theme-danger border-theme-danger',
        type === 'Warning' && 'bg-theme-warning-secondary border-theme-warning'
      )}
    >
      <div>
        {type === 'Warning' && (
          <ExclamationMarkTriangle
            aria-hidden="true"
            className="w-5 h-5 mr-2 text-theme-warning"
          />
        )}
        {type === 'Info' && (
          <Info
            aria-hidden="true"
            className="w-5 h-5 mr-2 text-theme-primary"
          />
        )}

        {type === 'Error' && (
          <ExclamationMark
            aria-hidden="true"
            className="w-5 h-5 mr-2 text-theme-danger"
          />
        )}
      </div>
      <div>
        {Title && (
          <div className="text-sm font-extrabold">
            <Title />
          </div>
        )}
        <div role="status" className="text-sm">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CallOut;
