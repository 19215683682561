import { Offer, PaymentTypes } from '@ads-bread/shared/bread/codecs';

export function groupOffersByType(
  offers: Offer[]
): Record<PaymentTypes, Offer[]> {
  return offers.reduce<Record<PaymentTypes, Offer[]>>(
    (accum, offer) => {
      const productType = offer.paymentProduct.type;
      if (productType) {
        if (!accum[productType]) {
          accum[productType] = [];
        }
        const group = accum[productType];
        group && group.push(offer);
      }
      return accum;
    },
    { INSTALLMENTS: [], SPLITPAY: [] }
  );
}
