import { LogoutResult } from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { handleResponse, HandlerResponse } from './base';

export const LOGOUT_PATH = '/api/logout';

export async function logout(
  apiFetch: APIFetch
): Promise<HandlerResponse<LogoutResult>> {
  const res: APIResponse<LogoutResult> = await apiFetch<LogoutResult>(
    LOGOUT_PATH,
    { method: 'GET' },
    LogoutResult
  );
  return handleResponse(res);
}
