import { Transition } from '@headlessui/react';
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { noOp } from '../lib/noOp';
import { FCWithChildren } from '../lib/types';
import { Info } from './svgs/Info';
import { X } from './svgs/X';

export type Notify = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => void;
type OnClose = () => void;

type ToastContext = {
  notify: Notify;
  closeNotification: OnClose;
};

const ToastContext = createContext<ToastContext>({
  notify: noOp,
  closeNotification: noOp,
});

type Toast = {
  visible: boolean;
  title: string;
  message: string;
  onClose: () => void;
};

const transition = 'transition-all duration-300';
const slideIn = 'translate-y-0 opacity-100';
const slideOut = '-translate-y-full opacity-0';

const Toast: FunctionComponent<Toast> = ({
  visible,
  title,
  message,
  onClose,
}) => {
  return (
    <Transition
      data-testid="toast"
      className="filter drop-shadow-lg m-0 p-4 text-white text-sm bg-theme-primary fixed top-0 left-0 w-full transform flex justify-between"
      enter={transition}
      enterFrom={slideOut}
      enterTo={slideIn}
      leave={transition}
      leaveFrom={slideIn}
      leaveTo={slideOut}
      afterLeave={onClose}
      show={visible}
    >
      <div className="flex">
        <div className="mr-4">
          <Info aria-hidden="true" className="w-6 h-6" />
        </div>
        <div className="flex flex-col sm:mr-2 md:mr-4">
          <div className="font-extrabold mt-1 mb-1">{title}</div>
          {message}
        </div>
      </div>
      <button
        data-testid="close-toast"
        className="flex p-1 h-min"
        onClick={onClose}
      >
        <X aria-hidden="true" className="w-4 h-4" />
      </button>
    </Transition>
  );
};

function useMobile() {
  const check = () => global.innerWidth <= 640;

  const [isMobile, setIsMobile] = useState<boolean>(check());
  const handleWindowSizeChange = () => {
    setIsMobile(check());
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { isMobile };
}

export const ToastProvider: FCWithChildren = ({ children }) => {
  const [displayToast, setDisplayToast] = useState(false);

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [timeoutId, setTimeoutId] = useState<number>();

  const { isMobile } = useMobile();

  const notify: Notify = useCallback(({ title: aTitle, message: aMessage }) => {
    setTitle(aTitle);
    setMessage(aMessage);

    setDisplayToast(true);

    const id = window.setTimeout(() => {
      setDisplayToast(false);
    }, 6000);

    setTimeoutId(id);
  }, []);

  const close: OnClose = useCallback(() => {
    clearTimeout(timeoutId);

    setDisplayToast(false);
  }, []);

  const toast = (
    <Toast
      visible={displayToast}
      title={title}
      message={message}
      onClose={close}
    />
  );

  return (
    <>
      {isMobile ? ReactDOM.createPortal(toast, document.body) : toast}

      <ToastContext.Provider value={{ notify, closeNotification: close }}>
        {children}
      </ToastContext.Provider>
    </>
  );
};

export const useToast = (): ToastContext => useContext(ToastContext);
