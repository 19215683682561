import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { geocode } from '../lib/handlers/geocode';
import { useMounted } from '../lib/hooks/useMounted';
import { FCWithChildren } from '../lib/types';

interface VerifyPostalValuesProps {
  isConfirming: boolean;
  setIsConfirming: Dispatch<SetStateAction<boolean>>;
  country: string;
  postalCode: string;
  region: string;
  googleApiKey?: string;
}

export const VerifyPostalValues: FCWithChildren<VerifyPostalValuesProps> = ({
  children,
  isConfirming,
  setIsConfirming,
  country,
  postalCode,
  region,
  googleApiKey,
}) => {
  const [showing, setShowing] = useState(false);
  const mounted = useMounted();
  useEffect(() => {
    // TODO: remove googleApiKey condition after implementing integration for RBC
    if (!isConfirming || !googleApiKey) {
      setShowing(true);
    } else {
      const verifyValues = async () => {
        try {
          const geoRes = await geocode({
            kind: 'POSTAL_CODE',
            postalCode,
            country,
            googleApiKey,
          });
          if (geoRes && mounted.current) {
            const { region: verifiedRegion } = geoRes;
            if (region !== verifiedRegion) {
              setIsConfirming(false);
            }
          }
        } catch (e) {
          if (mounted.current) {
            setIsConfirming(false);
          }
        } finally {
          if (mounted.current) {
            setShowing(true);
          }
        }
      };
      verifyValues();
    }
  }, [isConfirming]);

  return showing ? <>{children}</> : null;
};
