import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { GetServerSideProps } from 'next';
import { ClientAppConfig } from '@ads-bread/shared/bread/codecs';
import Error from 'next/error';
import { IntlProvider } from '../components/IntlContext';
import { Heading } from '../components/Heading';
import { P } from '../components/P';
import { ThemeStyles } from '../components/ThemeStyles';
import { MerchantConfigProvider } from '../components/MerchantContext';
import { XPropsProvider } from '../components/XPropsContext';
import { SpeechBubble } from '../components/svgs/SpeechBubble';
import { noOp } from '../lib/noOp';
import { AppConfigProvider } from '../components/AppConfigContext';
import { Footer } from '../components/footer';
import { PageNames, useTrackPageView } from '../lib/hooks/useTrackPageView';

interface PageProps {
  config: ClientAppConfig;
  statusCode: number;
}

const Page: FunctionComponent<PageProps> = ({ config, statusCode }) => {
  return (
    <>
      <AppConfigProvider config={config}>
        <IntlProvider>
          <XPropsProvider removeLoader={noOp}>
            <MerchantConfigProvider>
              <ThemeStyles />
              <div className="p-4 lg:p-12 text-blackberry max-w-modal mx-auto relative overflow-x-hidden">
                {statusCode === 404 ? (
                  <Custom404 />
                ) : (
                  <Error statusCode={statusCode} />
                )}
              </div>
            </MerchantConfigProvider>
          </XPropsProvider>
        </IntlProvider>
      </AppConfigProvider>
    </>
  );
};
export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  res,
}) => {
  const { clientAppConfig } = await import('../lib/config');
  const { statusCode } = res;
  return { props: { config: clientAppConfig, statusCode } };
};

export default Page;

interface Custom404Props {
  HeaderComponent?: FunctionComponent;
}

export const Custom404: FunctionComponent<Custom404Props> = ({
  HeaderComponent,
}) => {
  useTrackPageView({ pageName: PageNames.NotFoundError });
  return (
    <>
      {HeaderComponent && <HeaderComponent />}
      <Heading className="text-center">
        <FormattedMessage
          defaultMessage="Uh oh..."
          description="404 Error Header"
        />
      </Heading>

      <P className="text-center">
        <FormattedMessage
          defaultMessage="We are unable to find the page you are looking for."
          description="404 Error Description"
        />
      </P>

      <div className="flex justify-center pt-4 pb-20">
        <SpeechBubble aria-hidden="true" className="text-theme-primary" />
      </div>
      <Footer />
    </>
  );
};
