import {
  PaymentType,
  MerchantPaymentProduct,
} from '@ads-bread/shared/bread/codecs';

/** Helper function to find a {@link MerchantPaymentProduct} by {@link PaymentType} */
export function findMerchantPaymentProduct(
  merchantPaymentProducts: MerchantPaymentProduct[] | undefined,
  productType: PaymentType
): MerchantPaymentProduct | undefined {
  return merchantPaymentProducts?.find(
    ({ paymentProduct }: MerchantPaymentProduct) =>
      paymentProduct.type === productType
  );
}
