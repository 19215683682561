/**
 * Converts a string that possibly represents a boolean to a boolean type
 * @param value - the string to convert
 * @returns the boolean, or false if undefined is passed as the value
 * @throws Error if the string is not a valid boolean value.
 **/
export function booleanFromString(value?: string): boolean {
  if (value === undefined) {
    return false;
  }
  return JSON.parse(value.toLowerCase());
}
