import React, { createContext, useContext, useEffect, useState } from 'react';
import { FlagResponse } from '@getbread/feature-flag-sdk';
import { getFeatureFlags } from '../lib/handlers/get-feature-flags';
import { logger } from '../lib/logger';
import { FCWithChildren } from '../lib/types';
import { setAdditionalContext } from '../lib/analytics';
import { useAppConfig } from './AppConfigContext';
import { useDeviceID, useExperienceKeys } from './XPropsContext';
import { Loader } from './LoadingManager';

export enum IntroPageABPhaseOneResult {
  Control = 'control',
  SideBySide = 'side_by_side',
  Streamlined = 'streamlined',
  Off = 'off',
}

export interface FeatureFlags {
  enableIDVerification: boolean;
  enableSplitPayAuthEnhancement: boolean;
  disableColoradoAPR: boolean;
  introPageABPhaseOne: IntroPageABPhaseOneResult;
  enableAlloyJourney: boolean;
  enableBNPLRuling: boolean;
}

export const featureFlagDefaults: FeatureFlags = {
  enableIDVerification: false,
  enableSplitPayAuthEnhancement: false,
  disableColoradoAPR: false,
  introPageABPhaseOne: IntroPageABPhaseOneResult.Off,
  enableAlloyJourney: false,
  enableBNPLRuling: false,
};

const FeatureFlagsContext = createContext<FeatureFlags>(featureFlagDefaults);

interface FeatureFlagsProviderProps {
  removeLoader: (loader: Loader) => void;
}

export const FeatureFlagsProvider: FCWithChildren<FeatureFlagsProviderProps> =
  ({ removeLoader, children }) => {
    const [featureFlags, setFeatureFlags] =
      useState<FeatureFlags>(featureFlagDefaults);
    const deviceID = useDeviceID();
    const { merchantID, programID } = useExperienceKeys();
    const { apiUrl, tenantID, featureFlagsEnabled } = useAppConfig();

    /**
     * Checks feature flag response for any flags that match a common stem.
     * If any of the shared flags are enabled this function will return true.
     * If there aren't any results or all of the shared flags are disabled it
     * will return false.
     *
     * @LORE Feature flags use the ID as if it were a UUID so certain changes
     * can't be made once it is created. There is also a desire to do phased
     * roll-outs where multiple feature flags sharing a common stem can controls
     * different subsets of our merchant base.
     *
     * @param flags - The response object from the feature flag service.
     * @param stem - The common stem that all flags for a specific functionality
     * should share. For example, 'my-project' would match 'my-project-rollout-1'.
     */
    const isMultiFlagEnabled = (
      flags: Record<string, FlagResponse>,
      stem: string
    ): boolean =>
      Object.entries(flags).some(([key, value]) => {
        if (key.includes(stem)) {
          return value.result === 'true';
        }
      });

    useEffect(() => {
      const fetchFeatureFlags = async () => {
        let flagResults: FeatureFlags = featureFlagDefaults;
        let response: Record<string, FlagResponse> | null = null;

        try {
          response = await getFeatureFlags(
            apiUrl,
            merchantID,
            tenantID,
            deviceID,
            programID
          );

          const enableIDVerification =
            response['id_verification']?.result === 'true';
          const enableSplitPayAuthEnhancement =
            response['splitpay_payment_auth_enhancement']?.result === 'true';
          const disableColoradoAPR = response['ColoradoAPR']?.result === 'true';
          const introPageABPhaseOne =
            (response['intro_page_ab_phase_one']
              ?.result as IntroPageABPhaseOneResult) ||
            IntroPageABPhaseOneResult.Off;
          const enableAlloyJourney = isMultiFlagEnabled(
            response,
            'enable-alloy'
          );
          const enableBNPLRuling = isMultiFlagEnabled(
            response,
            'enable-bnpl-ruling'
          );

          flagResults = {
            enableIDVerification,
            enableSplitPayAuthEnhancement,
            disableColoradoAPR,
            introPageABPhaseOne,
            enableAlloyJourney,
            enableBNPLRuling,
          };

          setFeatureFlags(flagResults);
        } catch (err) {
          logger.error({ error: err }, 'Error accessing feature flags!');
        } finally {
          setAdditionalContext({
            feature_flags: JSON.stringify(flagResults).replace(/{|}|"/g, ''),
          });
          removeLoader('FEATURE_FLAGS');
        }
      };

      if (featureFlagsEnabled) {
        fetchFeatureFlags();
      }
    }, []);

    return (
      <FeatureFlagsContext.Provider value={featureFlags}>
        {children}
      </FeatureFlagsContext.Provider>
    );
  };

export const useFeatureFlags = (): FeatureFlags => {
  return useContext(FeatureFlagsContext);
};
