/**
 * Calls a given function and keeps calling it after the specified delay has passed.
 *
 * @param fn The function to call.
 * @param fnCondition A callback function indicating whether to stop polling.
 * Return true to stop polling
 * @param delay The delay (in milliseconds) to wait before calling the function again.
 * @param maxAttempts The maximum number of polling attempts before the promise
 * is rejected. Default is unlimited.
 * @param throwErrorIfMaxAttemptsExceeded Throw an error if the maxAttempts is exceeded.
 * Defaults to true. If false, this function will return the last poll result.
 */
export const poll = async <T>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  delay: number,
  maxAttempts?: number,
  throwErrorIfMaxAttemptsExceeded = true
): Promise<T> => {
  let attempts = 1;
  let result = await fn();
  while (!fnCondition(result)) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    if (maxAttempts && attempts >= maxAttempts) {
      if (throwErrorIfMaxAttemptsExceeded) {
        throw new Error('Maximum polling attempts reached.');
      } else {
        return result;
      }
    }
    attempts++;
    result = await fn();
  }
  return result;
};
