import React, {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Section } from '../Accordion';
import { TextLink } from '../TextLink';
import {
  useOrder,
  useMerchantPaymentProducts,
  useSDKPromoOffers,
  useMerchantProgramValues,
} from '../XPropsContext';
import SlideOut from '../SlideOut';
import {
  groupPromoOffersByType,
  hasInstallmentsPromoOffer,
  hasSplitPayPromoOffer,
} from '../../lib/groupPromoOffersByType';
import {
  groupPaymentProductByType,
  hasMerchantPaymentProducts,
} from '../../lib/groupPaymentProductsByType';
import { useAppConfig } from '../AppConfigContext';
import { LearnMore } from './FAQs';

type Props = {
  onBack: () => void;
  className?: string;
};

type FAQProps = Props & {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<boolean>>;
};

const faqFormattingValues = {
  p: (chunks: ReactNode) => <p className="mt-4 first:mt-0 mb-1">{chunks}</p>,
};

export const EcommFAQs: FunctionComponent<FAQProps> = ({
  isShowing,
  setIsShowing,
  onBack,
}) => {
  const intl = useIntl();
  const promoOffers = useSDKPromoOffers();
  const { isDefaultOrder } = useOrder();
  const { merchantPaymentProducts } = useMerchantPaymentProducts();
  const { supportUrl } = useAppConfig();
  const { isDownPaymentEnabled } = useMerchantProgramValues();
  const promoOffersByType = groupPromoOffersByType(promoOffers);
  const hasInstallments = hasInstallmentsPromoOffer(promoOffersByType);
  const hasSplitPay = hasSplitPayPromoOffer(promoOffersByType);
  // Show FAQs for default order based on available payment products
  const paymentProductsByType = groupPaymentProductByType(
    merchantPaymentProducts
  );
  const { hasInstallmentProduct, hasSplitPayProduct } =
    hasMerchantPaymentProducts(paymentProductsByType);

  const showInstallments =
    hasInstallments || (!!isDefaultOrder && hasInstallmentProduct);
  const showSplitPay = hasSplitPay || (!!isDefaultOrder && hasSplitPayProduct);

  return (
    <SlideOut
      isOpen={isShowing}
      onClose={() => setIsShowing(false)}
      afterLeave={() => onBack()}
      ariaLabel={intl.formatMessage({
        defaultMessage: 'Frequently Asked Questions',
        description: 'FAQ Screen aria label',
      })}
    >
      <div>
        <h2 id="general-questions" className="text-lg font-extrabold mb-4">
          <FormattedMessage
            defaultMessage="General Questions"
            description="FAQ Screen General heading"
          />
        </h2>
        <Accordion>
          <Section
            title={intl.formatMessage({
              defaultMessage: 'What is Bread Pay™?',
              description: 'FAQ Screen General question 1',
            })}
          >
            <FormattedMessage
              defaultMessage="<p>Bread Pay™ is a pay-over-time technology solution that helps you make purchases with easy-to-understand equal payments. Bread Pay™ products include the Bread Pay™ Installment plan, with terms from 3 to 48 months, and the Bread Pay™ SplitPay plan, with four payments made every two weeks, each payment being 25% of the original purchase amount.</p>"
              description="FAQ Screen General answer 1"
              values={faqFormattingValues}
            />
          </Section>

          <Section
            title={intl.formatMessage({
              defaultMessage:
                'Does prequalifying for Bread Pay™ impact my credit score?',
              description: 'FAQ Screen General question 2',
            })}
          >
            <FormattedMessage
              defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

              <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
              description="FAQ Screen General answer 2"
              values={faqFormattingValues}
            />
          </Section>

          <Section
            title={intl.formatMessage({
              defaultMessage:
                'I want to find out if I’m eligible for Bread Pay™. Will this impact my credit score?',
              description: 'FAQ Screen General question 3',
            })}
          >
            <FormattedMessage
              defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

              <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
              description="FAQ Screen General answer 3"
              values={faqFormattingValues}
            />
          </Section>

          <Section
            title={intl.formatMessage({
              defaultMessage:
                'What should I do if I am not receiving my mobile verification code?',
              description: 'FAQ Screen General question 4',
            })}
          >
            <FormattedMessage
              defaultMessage='<p>Please wait a few minutes before requesting a new mobile verification code to avoid receiving multiple texts with different codes all at once.
              The phone number you provide must be a mobile number that is able to receive text messages.</p>

              <p>You’ll have five attempts to validate your mobile verification code before being blocked for 15 minutes.
                 If you are still not receiving a mobile verification code, we recommend you request a new verification code by selecting "Resend code".</p>

              <p>We’re unable to send codes to prepaid or Cricket phones. If you have this type of phone or need additional assistance receiving a code, please contact our Member Support team <link>here</link>.</p>'
              description="FAQ Screen General answer 4"
              values={{
                ...faqFormattingValues,
                link: (chunks: ReactNode) => (
                  <TextLink
                    href={supportUrl}
                    target="_blank"
                    testID="customer-support"
                  >
                    {chunks}
                  </TextLink>
                ),
              }}
            />
          </Section>
        </Accordion>
      </div>

      {showInstallments && <ADSInstallmentFAQs />}
      {showSplitPay && <ADSSplitPayFAQs />}
      {isDownPaymentEnabled && <DownPaymentsFAQs />}

      <LearnMore
        showSplitPay={showSplitPay}
        showInstallments={showInstallments}
      />
    </SlideOut>
  );
};

const ADSInstallmentFAQs: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <div>
      <h2 id="installments-faqs" className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="Installments Questions"
          description="FAQ Screen Installments heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is a Bread Pay™ Installment plan?',
            description: 'FAQ Screen Installments question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>A Bread Pay™ Installment plan is a pay-over-time technology solution that helps you make purchases with easy-to-understand, equal payments. With our installment plans, you pay us back monthly over 3 to 48 months with APRs ranging from 0% to 29.99%. Keep in mind, the available loan terms may vary based on the retailer.</p>"
            description="FAQ Screen Installments answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Am I eligible to apply for a Bread Pay™ Installment plan?',
            description: 'FAQ Screen Installments question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>To apply for a Bread Pay™ Installment plan, you must meet your state’s age of majority – the age your state of residence defines as required to legally enter into a contract. You’ll also need a valid phone number, email address and mobile device that allows for a digital wallet.</p>

            <p>Bread Financial™ must be able to verify your identity based on the information you provide in the application. Checking your eligibility does not affect your credit score since only a soft inquiry is completed.</p>"
            description="FAQ Screen Installments answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How is my credit eligibility determined?',
            description: 'FAQ Screen Installments question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>We work with our bank affiliate, Comenity Capital Bank, which determines eligibility by reviewing factors such as your credit score and repayment history.</p>"
            description="FAQ Screen Installments answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'How is the APR on my Bread Pay™ Installment plan determined?',
            description: 'FAQ Screen Installments question 4',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Our bank affiliate, Comenity Capital Bank, determines the APR based on a variety of factors. These factors typically include your credit history and credit score, amount financed and length of the term, though other factors may be considered.</p>"
            description="FAQ Screen Installments answer 4"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Does checking out with a Bread installment loan impact my credit score?',
            description: 'FAQ Screen Installments question 5',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score as only a soft inquiry is completed. If you choose a Bread payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>
            <p>Bread may report repayment information about your account to the consumer reporting agencies, which may impact your credit score. *</p>"
            description="FAQ Screen Installments answer 5"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What installment plan payment methods do you accept?',
            description: 'FAQ Screen Installments question 6',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can make payments on Bread Pay™ Installment plans using ACH funds transfer or a personal debit card. You cannot make payments on Bread Pay™ Installment plans with a credit card, business debit card, bill pay or check. Additionally, Bread Pay™ does not accept Flexible Spending Account (FSA) or Health Savings Account (HSA) payment cards because we are not designated as a health services retailer. Payments must be received by 5:00 p.m. Eastern Time (ET) on your due date.</p>"
            description="FAQ Screen Installments answer 6"
            values={faqFormattingValues}
          />
        </Section>
      </Accordion>
    </div>
  );
};

const ADSSplitPayFAQs: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <div>
      <h2 id="splitpay-faqs" className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="SplitPay Questions"
          description="FAQ Screen SplitPay heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is Bread Pay™ SplitPay?',
            description: 'FAQ Screen SplitPay question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Bread Pay™ SplitPay is a payment option that enables you to pay for your purchase with four equal and interest-free payments using a personal credit card or debit card.</p>"
            description="FAQ Screen SplitPay answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Am I eligible to apply for a Bread Pay™ SplitPay plan?',
            description: 'FAQ Screen SplitPay question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>To apply for a Bread Pay™ SplitPay plan, you must meet your state’s age of majority – the age your state of residence defines as required to legally enter into a contract. You’ll also need a valid phone number, email address and mobile device that allows for a digital wallet.</p>

            <p>Bread Financial™ must be able to verify your identity based on the information you provide in the application. Checking your eligibility does not affect your credit score since only a soft inquiry is completed.</p>"
            description="FAQ Screen SplitPay answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'I want to find out if I’m eligible for Bread Pay™ SplitPay. Will this impact my credit score?',
            description: 'FAQ Screen SplitPay question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

            <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
            description="FAQ Screen SplitPay answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What Bread Pay™ SplitPay payment methods do you accept?',
            description: 'FAQ Screen SplitPay question 4',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can make payments on Bread Pay™ SplitPay using a personal debit card or credit card. You cannot make Bread Pay™ SplitPay payments with a business debit card, ACH funds transfer or check. Additionally, Bread Pay™ does not accept Flexible Spending Account (FSA) or Health Savings Account (HSA) payment cards because we are not designated as a health services retailer. Please note, payments must be received by 5:00 p.m. Eastern Time (ET) on your due date.</p>"
            description="FAQ Screen SplitPay answer 4"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Why do I see two pending authorizations on my card?',
            description: 'FAQ Screen SplitPay question 5',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>When you check out with SplitPay, a temporary authorization hold will be placed on your debit or credit card for the second payment. This hold will be voided immediately but depending on your financial institution it may remain for up to 7 days. If you have any questions about this authorization, please contact your financial institution.</p>"
            description="FAQ Screen SplitPay answer 5"
            values={faqFormattingValues}
          />
        </Section>
      </Accordion>
    </div>
  );
};

export const DownPaymentsFAQs: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <div>
      <h2 id="down-payments-faqs" className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="Down Payments FAQs"
          description="FAQ Screen Down Payments heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Do I need to make a down payment as part of my pay-over-time plan?',
            description: 'FAQ Screen Down Payments question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="
            <p>You may be asked to make a down payment as part of using a Bread Pay™ plan. This will depend on the amount your purchase amount and your credit eligibility.</p>

            <p>Let’s say the total amount you’re purchasing is $2,000 - and you’re eligible to pay $1,500 over time. When presented with your plan options, you might be asked to make a $500 down payment at checkout.</p>
            <p>You select your plan for your eligible amount, $1,500.00 in this case, and make your down payment at checkout.  You'd then make regular monthly payments as per your loan agreement.</p>
            "
            description="FAQ Screen Down Payments answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Why am I asked to reduce my purchase amount when using a pay-over-time plan?',
            description: 'FAQ Screen Down Payments question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="
          <p>In some cases you may be asked to reduce your cart amount as a part of using a Bread Pay™ plan. That’s because the amount you’re eligible to pay over time is less than your purchase amount.</p>

          <p>Let’s say the total purchase amount is $2,500 - and you’re eligible to pay $1,500 of this over time. You may be asked to reduce your purchase amount by $1,000.</p>
          <p>After doing this, you would have a balance of $1,500. You'd then make regular monthly payments as per your loan agreement.</p>
        "
            description="FAQ Screen Down Payments answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Why am I asked to reduce my purchase amount and make a downpayment when using a pay-over-time plan?',
            description: 'FAQ Screen Down Payments question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="
          <p>In some cases you may be asked to reduce your total purchase amount and make a down payment to use a Bread Pay™ plan. That’s because the the pay-over-time amount you’re eligible for is less than your purchase amount and there are limits on how large of a down payment you can make, as set by Bread Pay™ and the retailer.</p>

          <p>Let’s say the total purchase amount is $3,500 - and you’re eligible to pay $1,500 of this over time. You may be asked to reduce your purchase amount by $1,000 and also make a down payment of $1,000 at checkout.</p>
          <p>After reducing the purchase amount and making the down payment, you would have a balance of $1,500.00 to pay over time. You'd then make regular monthly payments as per your loan agreement.</p>
        "
            description="FAQ Screen Down Payments answer 3"
            values={faqFormattingValues}
          />
        </Section>
      </Accordion>
    </div>
  );
};
