import {
  PaymentMethod,
  PaymentMethodResponse,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { handleResponse } from './base';

export const GET_PAYMENT_METHOD = '/api/payments/payment-method';

export const getPaymentMethod = async (
  apiFetch: APIFetch,
  paymentMethodID: string,
  buyerId: string
): Promise<PaymentMethod> => {
  const uri = `${GET_PAYMENT_METHOD}/${paymentMethodID}?scopeID.eq=${buyerId}&scopeType.eq=BUYER`;
  const res: APIResponse<PaymentMethodResponse> =
    await apiFetch<PaymentMethodResponse>(
      uri,
      { method: 'GET' },
      PaymentMethodResponse
    );

  const response = handleResponse(res);

  if (response.error) {
    throw response.error;
  }

  return response.result.paymentMethod;
};
