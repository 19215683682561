import {
  SendCodePayload,
  CodeResult,
  isAPIError,
} from '@ads-bread/shared/bread/codecs';
import { logger } from '../logger';
import {
  APIFetch,
  APIResponse,
  UnauthorizedFromAuthError,
} from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const SEND_CODE_URI = '/api/auth/send-code';

export const getCartSendCodeURI = (cartID: string): string =>
  `/api/cart/${cartID}/send-code`;

export const getExchangeSendCodeURI = (buyerID?: string): string =>
  `/api/buyer/${buyerID}/send-code`;

export interface SendCodeOptions {
  uri: string;
  email?: string | null;
  phone?: string | null;
}

export async function sendCode(
  options: SendCodeOptions,
  apiFetch: APIFetch,
  environment?: string
): Promise<HandlerResponse<CodeResult>> {
  try {
    const isProduction = environment === 'production';
    const { uri, email, phone } = options;

    const res: APIResponse<CodeResult> = await apiFetch<
      CodeResult,
      SendCodePayload
    >(
      uri,
      {
        body: {
          email,
          phone,
          deliveryMethod: 'SMS',
          disclosures: [
            {
              type: 'SMS',
            },
            {
              type: 'privacy-policy-choices',
            },
            {
              type: 'terms-of-use',
            },
          ],
          uat: !isProduction ? { auth: { token: '1234' } } : undefined,
        },
      },
      CodeResult
    );
    return handleResponse(res);
  } catch (err) {
    if (err instanceof UnauthorizedFromAuthError) {
      const responseBody = err.data.body;
      if (isAPIError(responseBody)) {
        return handleResponse({
          response: null,
          error: responseBody,
        });
      }
    }
    logger.error({ err }, 'Unexpected error in send-code');
    throw err;
  }
}
