import {
  Buyer,
  Contact,
  ContactOutgoing,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const BUYER_URI = '/api/buyer';

export async function updateContact(
  buyer: Buyer,
  contact: ContactOutgoing,
  apiFetch: APIFetch
): Promise<HandlerResponse<Contact>> {
  const res: APIResponse<Contact> = await apiFetch<Contact, ContactOutgoing>(
    `${BUYER_URI}/${buyer.id}/contact/${buyer.primaryContactID}`,
    {
      method: 'PUT',
      body: contact,
    },
    Contact
  );
  return handleResponse(res);
}
