import React from 'react';
import { useUniqueId } from '../../lib/hooks/useUniqueId';
import { SVGProps } from './svgHelpers';

interface BreadLogoProps extends SVGProps {
  fillLogo?: string;
  fill?: string;
}

export const BreadLogo = (props: BreadLogoProps): JSX.Element => {
  const {
    className = '',
    fillLogo = '#5156EA',
    fill = '#0F233F',
    ...otherProps
  } = props;

  const breadLogoId = useUniqueId();

  return (
    <svg
      className={`fill-current ${className}`}
      viewBox="0 0 103 40"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={breadLogoId}
      {...otherProps}
    >
      <title id={breadLogoId}>{'Bread'}</title>
      <path
        d="m53.111 18.526c-2.0394 0-3.5201 0.7543-4.5258 2.0395v-1.6483h-3.604v19.556h3.604v-7.2358c1.0057 1.2852 2.4864 2.0395 4.5258 2.0395 3.7436 0 6.8169-3.2128 6.8169-7.3755s-3.0733-7.3755-6.8169-7.3755zm-0.6705 11.315c-2.207 0-3.8553-1.5924-3.8553-3.9392 0-2.3467 1.6483-3.9392 3.8553-3.9392 2.235 0 3.8836 1.5925 3.8836 3.9392 0 2.3468-1.6486 3.9392-3.8836 3.9392zm20.645-10.924v1.6483c-1.0061-1.2572-2.5144-2.0395-4.5539-2.0395-3.7155 0-6.7889 3.2128-6.7889 7.3755s3.0734 7.3755 6.7889 7.3755c2.0395 0 3.5478-0.7823 4.5539-2.0395v1.6483h3.6039v-13.969h-3.6039zm-3.8833 10.924c-2.2072 0-3.8556-1.5924-3.8556-3.9392 0-2.3467 1.6484-3.9392 3.8556-3.9392 2.235 0 3.8833 1.5925 3.8833 3.9392 0 2.3468-1.6483 3.9392-3.8833 3.9392zm19.863-10.924-3.1011 9.3869-3.6317-9.3869h-3.9395l5.7273 13.522c-0.6706 1.8719-1.6761 2.71-3.66 2.682v3.3525c3.5483 0.1956 5.9227-1.5645 7.3755-5.5875l5.085-13.969h-3.8555z"
        fill={fill}
      />
      <path
        d="m95.975 30.556h-2.0861v0.3273h0.8511v2.2756h0.38v-2.2756h0.855v-0.3273z"
        fill={fill}
      />
      <path
        d="m99.062 30.556h-0.4339l-0.9172 1.3758-0.9212-1.3758h-0.4338v2.6029h0.3844v-2.0302l0.9461 1.4129h0.0495l0.9461-1.4167v2.034h0.38v-2.6029z"
        fill={fill}
      />
      <path
        d="m50.423 6.5287c-1.1242 0-1.9404 0.41584-2.4949 1.1242v-3.9887h-1.9866v10.78h1.9866v-0.9086c0.5545 0.7083 1.3707 1.1242 2.4949 1.1242 2.0636 0 3.7577-1.7711 3.7577-4.0657 0-2.2947-1.6941-4.0657-3.7577-4.0657zm-0.3696 6.2372c-1.2167 0-2.1253-0.8779-2.1253-2.1715 0-1.2937 0.9086-2.1714 2.1253-2.1714 1.232 0 2.1406 0.87778 2.1406 2.1714 0 1.2936-0.9086 2.1715-2.1406 2.1715zm7.5147-4.6972v-1.3244h-1.9861v7.7002h1.9861v-3.6807c0-1.6171 1.3095-2.0791 2.3411-1.9558v-2.2177c-0.97 0-1.9405 0.43122-2.3411 1.4784zm4.8961 3.3419h5.8056c0.0461-0.2618 0.0772-0.5236 0.0772-0.8162 0-2.2639-1.6172-4.0657-3.8961-4.0657-2.4183 0-4.0661 1.7711-4.0661 4.0657 0 2.2946 1.6328 4.0657 4.22 4.0657 1.4783 0 2.6333-0.6006 3.3572-1.6479l-1.6017-0.924c-0.3388 0.4467-0.955 0.77-1.725 0.77-1.0472 0-1.8938-0.4312-2.1711-1.4476zm-0.0311-1.54c0.2311-0.98561 0.955-1.5554 2.0178-1.5554 0.8311 0 1.6628 0.44661 1.9094 1.5554h-3.9272zm13.093-3.1263v0.90861c-0.5544-0.693-1.3861-1.1242-2.5106-1.1242-2.0483 0-3.7422 1.7711-3.7422 4.0657 0 2.2946 1.6939 4.0657 3.7422 4.0657 1.1245 0 1.9562-0.4312 2.5106-1.1242v0.9086h1.9867v-7.7002h-1.9867zm-2.1406 6.0216c-1.2166 0-2.1255-0.8779-2.1255-2.1715 0-1.2937 0.9089-2.1714 2.1255-2.1714 1.2317 0 2.1406 0.87778 2.1406 2.1714 0 1.2936-0.9089 2.1715-2.1406 2.1715zm11.765-9.1016v3.9887c-0.5544-0.70839-1.3705-1.1242-2.4944-1.1242-2.0639 0-3.7578 1.7711-3.7578 4.0657 0 2.2946 1.6939 4.0657 3.7578 4.0657 1.1239 0 1.94-0.4159 2.4944-1.1242v0.9086h1.9867v-10.78h-1.9867zm-2.125 9.1016c-1.2322 0-2.1405-0.8779-2.1405-2.1715 0-1.2937 0.9083-2.1714 2.1405-2.1714 1.2167 0 2.125 0.87778 2.125 2.1714 0 1.2936-0.9083 2.1715-2.125 2.1715z"
        fill={fill}
      />
      <path
        d="m19.435 1.0216c-10.734 0-19.435 8.7002-19.435 19.435 0 10.734 8.7002 19.435 19.435 19.435 10.734 0 19.435-8.7003 19.435-19.435 0.0182-10.734-8.682-19.435-19.435-19.435zm3.7416 33.584-0.2542 0.1454c-1.7074 0.9263-3.8507 0.2724-4.777-1.4349l-10.571-19.635c-0.92633-1.7073-0.27244-3.8506 1.4349-4.7769l0.25428-0.14528c1.7073-0.92633 3.8506-0.29061 4.7769 1.4349l10.571 19.634c0.9081 1.7255 0.2724 3.8506-1.435 4.7769zm4.2684-5.4489-8.2098-15.239c4.2139-2.2705 9.4631-0.6903 11.734 3.5236 2.2704 4.1958 0.6902 9.4449-3.5237 11.715z"
        fill={fillLogo}
      />
    </svg>
  );
};
