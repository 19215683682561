import * as io from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
export var APIError = io.type({
  code: io.number,
  domain: io.string,
  message: io.string,
  metadata: io.record(io.string, io.string),
  reason: io.string
});
export var PaymentsAPIError = io.type({
  code: io.number,
  error: io.string,
  message: io.string,
  details: io.array(io.type({
    reason: io.string,
    domain: io.string,
    metadata: io.record(io.string, io.string)
  }))
});
export function isAPIError(err) {
  return pipe(APIError.decode(err), isRight);
}