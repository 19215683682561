import { useState } from 'react';
import uniqueId from 'lodash.uniqueid';

/**
 * Generates a unique id that is the same across renders.
 * @param prefix - optional, prepends the string to the unique id.
 * @returns unique id.
 */
export const useUniqueId = (prefix?: string): string => {
  const [uid] = useState(() => uniqueId(prefix));
  return uid;
};
