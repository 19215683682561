import { createContext, useContext } from 'react';
import { MerchantConfig } from '@ads-bread/shared/bread/codecs';
import { FCWithChildren } from '../lib/types';
import { useRawMerchantConfig } from './XPropsContext';
import { useAppConfig } from './AppConfigContext';

export const MerchantConfigContext = createContext<MerchantConfig>({});

export const MerchantConfigProvider: FCWithChildren = ({ children }) => {
  const { merchantConfig } = useRawMerchantConfig();
  const { defaultMerchantConfig } = useAppConfig();

  const mergedMerchantConfig = { ...defaultMerchantConfig, ...merchantConfig };
  return (
    <MerchantConfigContext.Provider value={mergedMerchantConfig}>
      {children}
    </MerchantConfigContext.Provider>
  );
};

export const useMerchantConfig = (): MerchantConfig =>
  useContext(MerchantConfigContext);

export type TierCustomization = {
  showIntroPageBreadSubHeading: boolean;
  showIntroPageFooter: boolean;
  showFooterBreadBranding: boolean;
};

export const TIER1_CUSTOMIZATIONS: TierCustomization = Object.freeze({
  showIntroPageBreadSubHeading: false,
  showIntroPageFooter: false,
  showFooterBreadBranding: false,
});

export const TIER2_CUSTOMIZATIONS: TierCustomization = Object.freeze({
  showIntroPageBreadSubHeading: true,
  showIntroPageFooter: false,
  showFooterBreadBranding: true,
});

export function useTierCustomizations(): TierCustomization {
  const { merchantTier } = useMerchantConfig();
  return merchantTier === 1 ? TIER1_CUSTOMIZATIONS : TIER2_CUSTOMIZATIONS;
}
