import { Application, Offer, Order } from '@ads-bread/shared/bread/codecs';
import {
  flattenApplicationDetails,
  flattenDetailProperties,
  flattenOrderProperties,
  flattenSelectedOffer,
} from './util';
import { EventProperties, Analytics, CheckoutDetails } from './';

interface AdobeClient {
  push: (props: object) => void;
  getState: (cb: (dl: AdobeClientDataLayer) => void) => void;
}

interface AdobeClientDataLayer {
  push: (
    item: object | ((dl: Omit<AdobeClientDataLayer, 'push'>) => void)
  ) => void;
  getState: (key?: string) => object;
  addEventListener: (
    topic: string,
    listener: (event: object) => void,
    options?: { path?: string; scope?: 'past' | 'future' | 'all' }
  ) => void;
  removeEventListener: (topic: string) => void;
}

export type WindowWithAdobe = Window & { adobeDataLayer: AdobeClientDataLayer };
declare let window: WindowWithAdobe;

if (typeof window !== 'undefined') {
  window.adobeDataLayer = window.adobeDataLayer || [];
}

// A set to keep track of property names to clear upon logout
const transientProperties: Set<string> = new Set();

function addTransientProperties(properties: EventProperties): void {
  Object.keys(properties).forEach((property) =>
    transientProperties.add(property)
  );
}

export const adobeClient: Analytics = {
  initialize(): void {
    adobe.getState((dl) => {
      const props = dl.getState();
      Object.keys(props).forEach((key: string) => {
        adobe.push({ [key]: undefined });
      });
    });
  },
  setApplication(application: Application): void {
    const applicationDetails = flattenApplicationDetails(application);
    addTransientProperties(applicationDetails);
    adobe.push(applicationDetails);
  },
  setSelectedOffer(offer: Offer): void {
    const properties = flattenSelectedOffer(offer);
    addTransientProperties(properties);
    adobe.push(properties);
  },
  setOrder(order: Order, isDefaultOrder = false): void {
    const orderDetails = flattenOrderProperties(order, isDefaultOrder);
    adobe.push(orderDetails);
  },
  identify(buyerId: string): void {
    addTransientProperties({ buyer_id: buyerId });
    adobe.push({ buyer_id: buyerId });
  },
  setCheckoutDetails(details: CheckoutDetails): void {
    const properties = flattenDetailProperties(details);
    adobe.push(properties);
  },
  trackLogout(): void {
    transientProperties.forEach((property) =>
      adobe.push({ [property]: undefined })
    );
  },
  track(event: string, properties?: EventProperties) {
    const trackedEvent = {
      event,
      ...properties,
    };
    adobe.push(trackedEvent);
  },
  setAdditionalContext(properties: EventProperties): void {
    addTransientProperties(properties);
    adobe.push(properties);
  },
};

const adobe: AdobeClient = {
  push(props: object): void {
    window.adobeDataLayer.push(props);
  },
  getState(cb: (dl: AdobeClientDataLayer) => void): void {
    window.adobeDataLayer.push((dl: AdobeClientDataLayer) => {
      cb(dl);
    });
  },
};
