import { FormikErrors, FormikProps, FormikTouched, FormikValues } from 'formik';
import { useEffect } from 'react';
import { AnalyticsEvent, track } from '../lib/analytics';
import { ErrorValue, isYupErrorHash } from './forms/InlineError';

type Fields = Record<string, never>;

function buildFormikObjectReducer(
  condition?: (value: ErrorValue) => boolean
): (fields: Fields, [key, value]: any) => Fields {
  return (fields: Fields, [key, value]: any) => {
    if (typeof value !== 'object') {
      return { ...fields, [key]: value };
    }

    if (condition) {
      return condition(value)
        ? { ...fields, ...value }
        : { ...fields, [key]: value };
    }

    return { ...fields, ...value };
  };
}

export function filterCurrentErrors<FV>(
  errors: FormikErrors<FV>,
  touched: FormikTouched<FV>
): Record<string, any> {
  const flattenedNestedFormErrors = Object.entries(errors).reduce(
    buildFormikObjectReducer((value) => !isYupErrorHash(value)),
    {}
  );

  const flattenedTouchedHash = Object.entries(touched).reduce(
    buildFormikObjectReducer(),
    {}
  );

  const currentErrors = Object.keys(flattenedNestedFormErrors).filter(
    (key) => flattenedTouchedHash[key]
  );

  return currentErrors;
}

export const FormErrorTracker = <FV extends FormikValues>({
  children,
  formProps,
}: {
  children: React.ReactElement | null;
  formProps: FormikProps<FV>;
}): React.ReactElement | null => {
  const { errors, touched } = formProps;

  useEffect(() => {
    const currentErrors = filterCurrentErrors<FV>(errors, touched);
    if (!currentErrors.length) {
      return;
    }

    track(AnalyticsEvent.FormInlineError, {
      invalid_fields: JSON.stringify(currentErrors),
    });
  }, [errors, touched]);

  return children;
};
