import { FunctionComponent, createContext, useContext } from 'react';
import { InterpreterFrom, assign } from 'xstate';
import { useActor, useInterpret } from '@xstate/react';
import { decode } from 'jsonwebtoken';
import {
  CodeResult,
  AuthResult,
  AuthPayload,
} from '@ads-bread/shared/bread/codecs';
import { FCWithChildren } from '../lib/types';
import { setAdditionalContext } from '../lib/analytics';
import {
  AuthenticationServiceErrorData,
  authenticationMachine,
} from '../lib/machines/authenticationMachine';
import { useAuthTokenStore } from '../lib/hooks/useAuthTokenStore';
import { HandlerWrapper } from '../lib/handlers/base';
import { SendCodeOptions, sendCode } from '../lib/handlers/send-code';
import { authorize } from '../lib/handlers/auth';
import { useFetch } from '../lib/hooks/apiFetch';
import { logout } from '../lib/handlers/logout';
import { isBreadJWT } from '../lib/bread-jwt';
import { logger } from '../lib/logger';
import { useHasReviewedApplication } from '../lib/hooks/useHasReviewedApplication';
import { useBuyer, useExperienceKeys } from './XPropsContext';

export const AUTH_REASONS = {
  INVALID_TOKEN: 'Invalid_Token',
  REQUEST_VALIDATION: 'Request_Validation',
  TOO_LOGIN_MANY_ATTEMPTS: 'Too_Many_Login_Attempts',
  UNAUTHENTICATED_RETRY_WITH_PII: 'Unauthenticated_Retry_With_PII',
  UNAUTHENTICATED: 'Unauthenticated',
};

export type AuthorizeOptions = {
  otpCode?: string;
  credentials?: Omit<AuthPayload['credentials'], 'code'>;
};

export type AuthenticationService = InterpreterFrom<
  typeof authenticationMachine
>;

export const AuthenticationContext =
  createContext<AuthenticationService | null>(null);

export const AuthenticationMachineProvider: FCWithChildren = ({ children }) => {
  const { merchantID, programID } = useExperienceKeys();
  const { buyerID } = useBuyer();
  const { authToken, isAuthTokenActive, setAuthToken } = useAuthTokenStore();
  const apiFetch = useFetch();
  const { setHasReviewedApplication } = useHasReviewedApplication();

  const authenticationService = useInterpret(authenticationMachine, {
    services: {
      sendCode: async (_, event) => {
        try {
          const opts = event.options;
          const sendCodeRes = await sendCode(opts, apiFetch);
          return { sendCodeRes, resolve: event.resolve };
        } catch (error) {
          throw { error, reject: event.reject };
        }
      },
      authorize: async (ctx, event) => {
        try {
          const authPayload: AuthPayload = {
            merchantID,
            programID,
            buyerID: buyerID ?? '',
            referenceID: ctx.refID ?? '',
            credentials: {
              ...(event.authorizeOptions.credentials
                ? event.authorizeOptions.credentials
                : {}),
              code: event.authorizeOptions.otpCode || ctx.otpCode || '',
            },
          };
          const authRes = await authorize(authPayload, apiFetch);

          return { authRes, resolve: event.resolve };
        } catch (error) {
          throw { error, reject: event.reject };
        }
      },
      logout: async () => {
        await logout(apiFetch);
      },
    },
    actions: {
      assignRefID: assign({
        refID: (context, event) => {
          return event.data.sendCodeRes.result?.referenceID || context.refID;
        },
      }),
      assignOtpCode: assign({
        otpCode: (ctx, event) => {
          return event?.authorizeOptions.otpCode || ctx.otpCode;
        },
      }),
      identifyAuthenticatedBuyer: () => {
        setAdditionalContext({
          returning_buyer: true,
        });
      },
      identifyReturningBuyer: (_, event) => {
        setAdditionalContext({
          returning_buyer: !!event.data.authRes.result?.buyerID,
        });
      },
      handleSendCodeDone: (_, event) => {
        event.data.resolve(event.data.sendCodeRes);
      },
      handleServiceError: (_, event) => {
        const errorData = event.data as AuthenticationServiceErrorData;
        errorData.reject(errorData.error);
      },
      handleAuthenticatingDone: (_, event) => {
        const token = event.data.authRes.result?.token;
        if (token) {
          setAuthToken(token);
        }
        event.data.resolve(event.data.authRes);
      },
      handleLogout: (_) => {
        setAuthToken('');
        setHasReviewedApplication(false);
      },
      resetContext: assign({
        refID: null,
        otpCode: null,
      }),
      logError: (_, event) => {
        const err =
          event.data instanceof Error ? event.data : { message: event.data };
        logger.error(
          { err, buyerID, merchantID },
          `Error in AuthenticationContext: ${event.type}`
        );
      },
    },
    guards: {
      isAuthTokenActive,
      isExchangedBuyer: () => {
        return !!buyerID;
      },
      isMismatchedBuyerToken: () => {
        const decodedJwt = decode(authToken);
        if (isBreadJWT(decodedJwt)) {
          return decodedJwt.buyerID !== buyerID;
        }
        return false;
      },
      isAuthenticatedWithBuyer: (_, event) => {
        return !!event.data.authRes.result?.buyerID;
      },
      isRetryWithPIIError: (_, event) => {
        return (
          event.data.authRes.error?.reason ===
          AUTH_REASONS.UNAUTHENTICATED_RETRY_WITH_PII
        );
      },
      isAuthenticatedAnonymous: (_, event) => {
        return !!event.data.authRes.result?.token;
      },
      isAuthenticatedAnonymousMismatchedBuyerPII: (_, event) => {
        return (
          !!event.data.authRes.result?.token &&
          !event.data.authRes.result.buyerID
        );
      },
      isAuthenticatedMatchedBuyerPII: (_, event) => {
        return (
          !!event.data.authRes.result?.token &&
          !!event.data.authRes.result.buyerID
        );
      },
    },
  });

  return (
    <AuthenticationContext.Provider value={authenticationService}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export interface UseAuthenticationValues {
  state: AuthenticationService['state'];
  send: AuthenticationService['send'];
  logout: () => void;
  logoutUnauthorized: () => void;
  authorize: HandlerWrapper<AuthorizeOptions, AuthResult>;
  sendCode: HandlerWrapper<SendCodeOptions, CodeResult>;
}

export const useAuthentication = (): UseAuthenticationValues => {
  const authenticationService = useContext(AuthenticationContext);

  if (!authenticationService) {
    throw new Error('Authentication service is null!');
  }

  const [state, send] = useActor(authenticationService);

  const logoutWrapper = () => {
    send('SEND_LOGOUT');
  };

  const logoutUnauthorizedWrapper = () => {
    send('SEND_LOGOUT_UNAUTHORIZED');
  };

  const authorizeWrapper: HandlerWrapper<AuthorizeOptions, AuthResult> = async (
    authorizeOptions
  ) => {
    return new Promise((res, rej) => {
      send({
        type: 'SEND_AUTH_REQUEST',
        authorizeOptions,
        resolve: res,
        reject: rej,
      });
    });
  };

  const sendCodeWrapper: HandlerWrapper<SendCodeOptions, CodeResult> = async (
    options
  ) => {
    return new Promise((res, rej) => {
      send({
        type: 'SEND_CODE',
        options,
        resolve: res,
        reject: rej,
      });
    });
  };

  return {
    state,
    send,
    logout: logoutWrapper,
    logoutUnauthorized: logoutUnauthorizedWrapper,
    authorize: authorizeWrapper,
    sendCode: sendCodeWrapper,
  };
};

export const AuthenticationSubscriber: FunctionComponent = () => {
  const { state } = useAuthentication();
  return (
    <>
      <pre className="text-sm">
        {JSON.stringify({ value: state.value }, null, 2)}
      </pre>
      <pre className="text-sm">
        {JSON.stringify({ context: state.context }, null, 2)}
      </pre>
    </>
  );
};
