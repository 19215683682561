import React from 'react';
import { FCWithChildren } from '../lib/types';

interface HeadingProps {
  className?: string;
}

export const Heading: FCWithChildren<HeadingProps> = ({
  children,
  className = '',
}) => {
  return (
    <h1
      className={`font-extrabold text-blackberry text-3xl mb-4 leading-10 ${className}`}
      data-testid="terms-heading"
      id="modal-headline"
    >
      {children}
    </h1>
  );
};
