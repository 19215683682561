import {
  Buyer,
  MaskedBuyerPhoneAndEmail,
} from '@ads-bread/shared/bread/codecs';
import { APIFetch, APIResponse } from '../hooks/apiFetch';
import { HandlerResponse, handleResponse } from './base';

export const GET_BUYER_URI = '/api/buyer';

export async function getBuyer(
  apiFetch: APIFetch,
  buyerID?: string
): Promise<HandlerResponse<Buyer>> {
  const uri = `${GET_BUYER_URI}${buyerID ? `/${buyerID}` : ''}`;
  const res: APIResponse<Buyer> = await apiFetch<Buyer>(
    uri,
    { method: 'GET' },
    Buyer
  );
  return handleResponse(res);
}

export async function getBuyerPhoneAndEmail(
  apiFetch: APIFetch,
  buyerID: string
): Promise<HandlerResponse<MaskedBuyerPhoneAndEmail>> {
  const uri = `${GET_BUYER_URI}/${buyerID}/masked`;
  const res: APIResponse<MaskedBuyerPhoneAndEmail> =
    await apiFetch<MaskedBuyerPhoneAndEmail>(
      uri,
      { method: 'GET' },
      MaskedBuyerPhoneAndEmail
    );
  return handleResponse(res);
}
