// Note: Currently we are only supporting US and Canadian phone numbers,
// and therefore only removing US and CA country codes.
import { FormatNumberOptions, IntlShape } from 'react-intl';

/**
 * Removes +1 from the beginning of a phone number if needed.
 *
 * @remark Currently only supports removing +1, and not other country codes.
 */
export const removeCountryCodeFromPhoneNumber = (phone: string): string => {
  if (phone.startsWith('+1')) {
    return phone.substring(2);
  }

  return phone;
};

/**
 * Prepends +1 to the beginning of a phone number if needed.
 *
 * @remark Currently only supports adding +1, and not other country codes.
 **/
export const addCountryCodeToPhoneNumber = (phone: string): string => {
  if (!phone.startsWith('+1')) {
    return `+1${phone}`;
  }

  return phone;
};

export const percentFormat: FormatNumberOptions = {
  style: 'percent',
  minimumFractionDigits: 2,
};

/**
 * @example
 * BPS of 2999 is formatted to 29.99%
 */
export const aprFormatter = (bps: number, intl: IntlShape): string => {
  return intl.formatNumber(bps / 10000, percentFormat);
};
